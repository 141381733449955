// ts-gql-integrity:bbfe89f281bf11794164f24afbf49d04
/*
ts-gql-meta-begin
{
  "hash": "2728f26346b56feb5cbd800d59e78ea7"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type ClientsSearchQueryVariables = {
  searchInput: SchemaTypes.Scalars['String'];
};


type ClientsSearchQuery = (
  { readonly __typename: 'Query' }
  & { readonly searchClients: SchemaTypes.Maybe<ReadonlyArray<(
    { readonly __typename: 'Organisation' }
    & Pick<SchemaTypes.Organisation, 'id' | 'sortName'>
    & { readonly entityType: (
      { readonly __typename: 'EntityType' }
      & Pick<SchemaTypes.EntityType, 'id'>
    ) }
  ) | (
    { readonly __typename: 'Person' }
    & Pick<SchemaTypes.Person, 'id' | 'sortName'>
    & { readonly entityType: (
      { readonly __typename: 'EntityType' }
      & Pick<SchemaTypes.EntityType, 'id'>
    ) }
  )>> }
);


export type type = TypedDocumentNode<{
  type: "query";
  result: ClientsSearchQuery;
  variables: ClientsSearchQueryVariables;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    ClientsSearch: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"ClientsSearch\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"searchInput\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"searchClients\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"searchInput\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"searchInput\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"sortName\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"entityType\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]}]}}]}}]}}]}")
