const map = [' ', 'abc', 'def', 'ghi', 'jkl', 'mno', 'pqrs', 'tuv', 'wxyz'];

export const convertWordsToNumbers = (value: string, maxNumber: number) => {
  let v = value.toLowerCase(),
    out = [];

  for (let i = 0; i < v.length; i++) {
    for (let j = 0; j < map.length; j++) {
      if (map[j] && map[j].indexOf(v[i]) > -1) {
        out.push(j);
        break;
      }
    }
  }

  if (maxNumber) {
    return Number(out.join('')) % (maxNumber + 1);
  }

  return Number(out.join(''));
};
