import { Dispatch, SetStateAction } from 'react';
import { MutationFunction } from '@apollo/client';

import {
  ApolloClientType,
  ClientObjectType,
  EntityType,
  Failure,
  NonMatchingClient,
  StaffMember,
} from '../types';
import { getCurrentISODate } from '../../../utilities/helpers/dates';

import { createClientObject } from './createClientObject';
import { checkIfExistingClient } from './checkIfExistingClient';

export async function importClientRecord({
  item,
  clientRow,
  entityTypes,
  titleRow,
  apolloClient,
  staffMembers,
  nonMatchingDebtor,
  nonMatchingFamily,
  setImportCount,
  createClient,
  updateClient,
  setImportWarnings,
}: {
  item: string[];
  clientRow: number;
  entityTypes: EntityType[] | undefined;
  titleRow: string[];
  apolloClient: ApolloClientType;
  staffMembers: StaffMember[] | undefined;
  nonMatchingDebtor: NonMatchingClient[];
  nonMatchingFamily: NonMatchingClient[];
  setImportCount: Dispatch<SetStateAction<number>>;
  createClient: MutationFunction;
  updateClient: MutationFunction;
  setImportWarnings: Dispatch<
    SetStateAction<{ failure: Failure; row: number }[]>
  >;
}) {
  const entityType = entityTypes?.find(
    (entity) => entity.name === item[titleRow.indexOf('EntityType')]
  );
  const type = entityType?.type || 'Person';
  const entityTypeId = entityType?.id;

  const clientObject = createClientObject(
    type,
    entityTypeId,
    titleRow,
    item,
    staffMembers
  );

  if (clientObject.warnings.length) {
    //   @ts-ignore
    setImportWarnings((prevWarnings) => {
      if (!prevWarnings) return clientObject.warnings;
      return [...prevWarnings, clientObject.warnings];
    });
  }

  return (async function () {
    try {
      const findExistingClient = await checkIfExistingClient(
        {
          sortName: clientObject.client.sortName,
          externalClientId: clientObject.client.externalClientId,
        },
        apolloClient
      );

      if (
        item[titleRow.indexOf('FamilyGroup')] &&
        item[titleRow.indexOf('FamilyGroup')] !== ''
      ) {
        const familyGroup =
          item[titleRow.indexOf('FamilyGroup')] ===
          item[titleRow.indexOf('Sortname')];

        if (!familyGroup) {
          const findFamilyClient = await checkIfExistingClient(
            {
              sortName: item[titleRow.indexOf('FamilyGroup')],
            },
            apolloClient
          );

          if (findFamilyClient) {
            clientObject.client = {
              ...clientObject.client,
              familyGroup: { id: findFamilyClient.id },
            };
          } else {
            // Collects info for clients with family group head that does not exist yet
            nonMatchingFamily.push({
              clientObject,
              row: clientRow,
              clientName: item[titleRow.indexOf('FamilyGroup')],
            });
          }
        }
      }

      if (
        item[titleRow.indexOf('Debtor')] &&
        item[titleRow.indexOf('Debtor')] !== ''
      ) {
        const DebtorGroup =
          item[titleRow.indexOf('Debtor')] ===
          item[titleRow.indexOf('Sortname')];

        if (!DebtorGroup) {
          const findDebtorClient = await checkIfExistingClient(
            {
              sortName: item[titleRow.indexOf('Debtor')],
            },
            apolloClient
          );

          if (findDebtorClient) {
            clientObject.client = {
              ...clientObject.client,
              debtor: { id: findDebtorClient.id },
            };
          } else {
            // Collects info for clients with debtor group head that does not exist yet
            nonMatchingDebtor.push({
              clientObject,
              row: clientRow,
              clientName: item[titleRow.indexOf('Debtor')],
            });
          }
        }
      }

      let result = {};
      if (findExistingClient) {
        delete clientObject.client.entityType;
        result = await updateImportClient(
          clientObject.client,
          findExistingClient.id,
          updateClient
        );
      } else {
        result = await createImportClient(clientObject.client, createClient);
      }

      setImportCount((count) => count + 1);

      return { ...result, row: clientRow };
    } catch (error) {
      return {
        failure: {
          sortName: item[titleRow.indexOf('Sortname')],
          error,
          message: error?.message,
        },
        row: clientRow,
      };
    }
  })();
}

export const createImportClient = async (
  input: ClientObjectType,
  createClient: MutationFunction
) => {
  const createClientInput = { ...input, dateAdded: getCurrentISODate() };

  try {
    const { data } = await createClient({
      variables: { createClientInput },
    });
    // Returning a success
    if (data.createClient?.id) return { success: data.createClient };
  } catch (error) {
    // Setting a failure message
    if (error?.message) {
      return {
        failure: { sortName: input.sortName, error, message: error?.message },
      };
    }

    return error;
  }
};

export const updateImportClient = async (
  input: ClientObjectType,
  id: string,
  updateClient: MutationFunction
) => {
  try {
    const { data } = await updateClient({
      variables: { id, updateClientInput: input },
    });
    if (data.updateClient?.id) return { success: data.updateClient };
  } catch (error) {
    if (error?.message) {
      return {
        failure: { sortName: input.sortName, error, message: error?.message },
      };
    }

    return error;
  }
};
