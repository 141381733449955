import React, { useState } from 'react';

// import { Button } from '@reckon-web/button';
import { Empty } from '@reckon-web/empty';
import { Inline } from '@reckon-web/inline';
import { Divider } from '@reckon-web/divider';
import { Stack } from '@reckon-web/stack';

import type { Relationship } from '../../queries/useQueryRelationships';
// import { READ, WRITE, useAccessLevel } from '../access';

// import { AddEditRelationshipDrawerContainer } from './addEditRelationshipDrawer/AddEditRelationshipDrawerContainer';
import type { Client } from './Client';
import {
  PopulatedRelationshipsTable,
  RelationshipDrawerState,
} from './PopulatedRelationshipsTable';

type RelationshipsTableProps = {
  client: Client;
  relationships: readonly Relationship[];
};

const initialAddEditRelationshipDrawerState: RelationshipDrawerState = {
  isOpen: false,
  relationshipId: undefined,
  isPrimaryContact: false,
  relationshipData: undefined,
};

export const RelationshipsTable = ({
  client,
  relationships,
}: RelationshipsTableProps) => {
  // const accessLevel = useAccessLevel();
  const [
    addEditRelationshipDrawerState,
    setAddEditRelationshipDrawerState,
  ] = useState(initialAddEditRelationshipDrawerState);

  return (
    <Stack gap="medium">
      <Inline gap="small" align="right">
        {/* <Button
          size="small"
          label="Add relationship"
          disabled={accessLevel === READ || accessLevel === WRITE}
          onClick={() => {
            setAddEditRelationshipDrawerState({
              isOpen: !addEditRelationshipDrawerState.isOpen,
              relationshipId: undefined,
              isPrimaryContact: false,
              relationshipData: undefined,
            });
          }}
        /> */}
      </Inline>

      <Divider />

      {relationships.length > 0 ? (
        <PopulatedRelationshipsTable
          relationships={relationships}
          handleAddEditRelationshipDrawerState={
            setAddEditRelationshipDrawerState
          }
          addEditRelationshipDrawerState={addEditRelationshipDrawerState}
        />
      ) : (
        <Empty title="No relationships yet" />
      )}

      {/* <AddEditRelationshipDrawerContainer
        isOpen={addEditRelationshipDrawerState.isOpen}
        client={client}
        relationshipId={addEditRelationshipDrawerState.relationshipId}
        isPrimaryContact={addEditRelationshipDrawerState.isPrimaryContact}
        relationshipData={addEditRelationshipDrawerState.relationshipData}
        onDrawerClose={() => {
          setAddEditRelationshipDrawerState({
            isOpen: false,
            relationshipId: undefined,
            isPrimaryContact: false,
            relationshipData: undefined,
          });
        }}
      /> */}
    </Stack>
  );
};
