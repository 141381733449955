import React, { Dispatch, SetStateAction } from 'react';

import { AlertDialog } from '@reckon-web/modal-dialog';
import { Text } from '@reckon-web/text';

type DiscardConfirmationDialogProps = {
  isDiscardDialogOpen: boolean;
  setIsDiscardDialogOpen: Dispatch<SetStateAction<boolean>>;

  callback?: () => void;
};

export const DiscardConfirmationDialog = ({
  isDiscardDialogOpen,
  setIsDiscardDialogOpen,
  /* Custom callback usually used to close the parent drawer/dialog   */
  callback,
}: DiscardConfirmationDialogProps) => {
  return (
    <AlertDialog
      isOpen={isDiscardDialogOpen}
      title="Discard changes?"
      tone="critical"
      actions={{
        confirm: {
          label: 'Discard',
          action: () => {
            setIsDiscardDialogOpen(false);
            callback?.();
          },
        },
        cancel: {
          label: 'Cancel',
          action: () => setIsDiscardDialogOpen(false),
        },
      }}
    >
      <Text>Changes you've made so far will not be saved.</Text>
    </AlertDialog>
  );
};
