import React from 'react';
import { FragmentData, gql } from '@ts-gql/tag';

import { Stack } from '@reckon-web/stack';
import { Box } from '@reckon-web/box';
import { DataGroup, DataRow } from '@reckon-web/data-group';

import { AddressText } from './AddressText';

let fragment = gql`
  fragment ClientContactInfo_contactDetail on ContactDetail {
    id
    primaryPhone
    otherPhone
    fax
    primaryEmail
    otherEmail
    website
    importantContactInfo
    postalAddress {
      ...AddressText_address
    }
    streetAddress {
      ...AddressText_address
    }
    registeredAddress {
      ...AddressText_address
    }
  }
` as import('../../../__generated__/ts-gql/ClientContactInfo_contactDetail').type;

export function ClientContactInfo({
  contactDetail,
}: {
  contactDetail: FragmentData<typeof fragment>;
}) {
  return (
    <Stack gap="xxlarge">
      <Box marginTop="medium">
        <DataGroup>
          <DataRow label="Primary phone">
            {contactDetail.primaryPhone || '—'}
          </DataRow>
          <DataRow label="Other phone">
            {contactDetail.otherPhone || '—'}
          </DataRow>
          <DataRow label="Fax">{contactDetail.fax || '—'}</DataRow>
        </DataGroup>
      </Box>
      <Box marginTop="medium">
        <DataGroup>
          <DataRow label="Primary email">
            {contactDetail.primaryEmail || '—'}
          </DataRow>
          <DataRow label="Other email">
            {contactDetail.otherEmail || '—'}
          </DataRow>
          <DataRow label="Website">{contactDetail.website || '—'}</DataRow>
          <DataRow label="Other contact information">
            {contactDetail.importantContactInfo || '—'}
          </DataRow>
        </DataGroup>
      </Box>
      <Box marginTop="medium">
        <DataGroup>
          <DataRow label="Street address">
            <AddressText address={contactDetail.streetAddress} />
          </DataRow>
          <DataRow label="Postal address">
            <AddressText address={contactDetail.postalAddress} />
          </DataRow>
          <DataRow label="Registered address">
            <AddressText address={contactDetail.registeredAddress} />
          </DataRow>
        </DataGroup>
      </Box>
    </Stack>
  );
}
