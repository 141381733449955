/** @jsx jsx */

import { CSSProperties, ReactNode, forwardRef, useMemo } from 'react';

import { buildDataAttributes, jsx } from '@reckon-web/core';
import { DefaultTextPropsProvider } from '@reckon-web/text';

// Component
// ------------------------------

export type TableBodyProps = UseBodyStylesProps & {
  /** The elements that make up the table body. */
  children: ReactNode;
};

export const TableBody = forwardRef<HTMLDivElement, TableBodyProps>(
  ({ children, ...props }, ref) => {
    const { css, dataAttributes, style } = useBodyStyles(props);
    return (
      <DefaultTextPropsProvider size="small">
        <div
          ref={ref}
          role="rowgroup"
          css={css}
          style={style}
          {...dataAttributes}
        >
          {children}
        </div>
      </DefaultTextPropsProvider>
    );
  }
);

// Styles
// ------------------------------

type UseBodyStylesProps = {
  /**
   * Sets inline [style](https://reactjs.org/docs/dom-elements.html#style) for
   * the element. Only use as a **last resort**.
   */
  UNSAFE_style?: CSSProperties;
};

export function useBodyStyles(props: UseBodyStylesProps) {
  const { UNSAFE_style } = props;

  // dynamic properties
  const style = useMemo(() => UNSAFE_style, [UNSAFE_style]);

  // static-ish properties
  const dataAttributes = useMemo(
    () => buildDataAttributes({ type: 'body' }, 'rowgroup'),
    []
  );

  // declarations
  const css = useMemo(
    () => [
      {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0%',
      } as const,
      // print rules
      {
        [`@media print`]: {
          display: 'table-row-group',
        } as const,
      },
    ],
    []
  );

  return { dataAttributes, css, style };
}
