/** @jsx jsx */
import { useContext } from 'react';
import { FileRejection } from 'react-dropzone';

import { jsx } from '@reckon-web/core';
import { Button } from '@reckon-web/button';
import { Flex } from '@reckon-web/flex';

import { ImportClientContext } from './ImportClientDialogContainer';

type ImportClientDialogFooterProps = {
  fileRejections: FileRejection[];
};

export const ImportClientDialogFooter = ({
  fileRejections,
}: ImportClientDialogFooterProps) => {
  const {
    verifyingCsv,
    beginImport,
    setBeginImport,
    importError,
    setImportError,
    importDone,
    initialValidationFail,
    setCancelOpen,
    csv,
    file,
    handleCloseImportDialog,
  } = useContext(ImportClientContext);

  if (!importDone) {
    return (
      <Flex justifyContent="flex-end" gap="small" padding="large">
        <Button
          tone="passive"
          weight="none"
          label="Cancel"
          onClick={() => {
            beginImport ? setCancelOpen(true) : handleCloseImportDialog();
          }}
        />
        <Button
          disabled={
            file.status !== 'accepted' ||
            !!fileRejections.length ||
            verifyingCsv ||
            initialValidationFail
          }
          loading={beginImport || verifyingCsv}
          label={
            file.status !== 'none' && csv?.totalClients
              ? `Import ${csv?.totalClients} records`
              : 'Import'
          }
          onClick={() => {
            if (importError) setImportError(false);
            setBeginImport(true);
          }}
        />
      </Flex>
    );
  }

  return (
    <Flex justifyContent="flex-end" gap="small" padding="large">
      <Button label="Done" onClick={handleCloseImportDialog} />
    </Flex>
  );
};
