/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { Heading } from '@reckon-web/heading';
import { Box } from '@reckon-web/box';
import { Stack } from '@reckon-web/stack';
import { Divider } from '@reckon-web/divider';
import { Form } from '@reckon-web/forms';

import { contactDetailSchema } from '../utils';
import { TextField } from '../../../design-system/text-field';

// component
export function ContactForm({
  isEdit = false,
  form,
}: {
  isEdit?: boolean;
  form: Form<typeof contactDetailSchema>;
}) {
  return (
    <Stack marginY="medium" gap="large">
      <Heading level="3">Contact info</Heading>

      <TextField
        {...form.fields.primaryPhone.props}
        autoComplete="off"
        id="Primary-contact-number"
        inputMode="tel"
        placeholder="Enter contact number"
        label="Primary contact number"
      />
      <TextField
        {...form.fields.otherPhone.props}
        autoComplete="off"
        id="Other-contact-number"
        inputMode="tel"
        placeholder="Enter other contact number"
        label="Other contact number"
      />
      {isEdit && (
        <TextField
          {...form.fields.fax.props}
          autoComplete="off"
          id="Fax"
          inputMode="tel"
          placeholder="Enter fax number"
          label="Fax"
        />
      )}
      <Box marginY="medium">
        <Divider />
      </Box>

      <TextField
        {...form.fields.primaryEmail.props}
        autoComplete="off"
        id="Primary-email"
        inputMode="email"
        placeholder="Enter email address"
        label="Primary email"
      />
      <TextField
        {...form.fields.otherEmail.props}
        autoComplete="off"
        id="Other-emaill"
        inputMode="email"
        placeholder="Enter other email address"
        label="Other email"
      />
      <TextField
        {...form.fields.website.props}
        autoComplete="off"
        id="Website"
        inputMode="url"
        placeholder="Enter website"
        label="Website"
      />
      {isEdit && (
        <TextField
          {...form.fields.importantContactInfo.props}
          id="Other-contact-information"
          placeholder="Enter other contact information"
          label="Other contact information"
        />
      )}
    </Stack>
  );
}
