import { FragmentData } from '@ts-gql/tag';
import { Dispatch, SetStateAction } from 'react';
import Router from 'next/router';

import { ToastProps } from '@reckon-web/toast/src/types';

import { ClientDetailsFragment } from '../..';
import { getCurrentISODate } from '../../../../utilities/helpers/dates';

const handleError = (
  error: any,
  addToast: (props: ToastProps) => void,
  errorName: string
) => {
  let err = error?.message || error;
  if (err.includes('GraphQL error:')) err = err.replace('GraphQL error: ', '');

  addToast({
    tone: 'warning',
    title: `${errorName}: `,
    message: `${err}`,
  });
};

const handleSuccess = (
  addToast: (props: ToastProps) => void,
  setIsOpen: Dispatch<SetStateAction<boolean>>
) => {
  addToast({
    tone: 'positive',
    title: 'Client update successful',
  });
  setIsOpen(false);
};

export const handleUpdate = async (
  updateClient: any,
  client: FragmentData<typeof ClientDetailsFragment>,
  input: any,
  addToast: (props: ToastProps) => void,
  setIsOpen: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const { data } = await updateClient({
      variables: { id: client.id, updateClientInput: input },
    });
    if (data.updateClient?.id) {
      handleSuccess(
        () =>
          addToast({
            tone: 'positive',
            title: 'Update client successful',
            message: `Client ${data.updateClient.sortName} was successfully updated`,
          }),
        setIsOpen
      );
    } else {
      addToast({
        tone: 'warning',
        title: "Something isn't right, please check the console",
      });
    }
  } catch (error) {
    handleError(error, addToast, 'Update Failed');
  }
};

export const handleCreate = async (
  createClient: any,
  input: any,
  addToast: (props: ToastProps) => void,
  setIsOpen: Dispatch<SetStateAction<boolean>>
) => {
  try {
    input.dateAdded = getCurrentISODate();
    const { data } = await createClient({
      variables: { createClientInput: input },
    });
    if (data.createClient?.id) {
      handleSuccess(
        () =>
          addToast({
            tone: 'positive',
            title: 'Create client successful',
            message: `Client ${data?.createClient?.sortName} was successfully created`,
          }),
        setIsOpen
      );
      Router.push(`/client?client=${data.createClient?.id}`);
    } else {
      addToast({
        tone: 'warning',
        title: "Something isn't right",
      });
    }
  } catch (error) {
    handleError(error, addToast, 'Create Client Failed');
  }
};
