/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react';

import { jsx } from '@reckon-web/core';
import { Box } from '@reckon-web/box';
import { Flex } from '@reckon-web/flex';
import { EntityAvatar } from '@reckon-web/avatar';
import { Text } from '@reckon-web/text';
import { TextLink } from '@reckon-web/text-link';
import { Button, IconButton } from '@reckon-web/button';
import { MinusSquareIcon } from '@reckon-web/icon/icons/MinusSquareIcon';
import { PlusSquareIcon } from '@reckon-web/icon/icons/PlusSquareIcon';
import { LoadingDots } from '@reckon-web/loading';

import { TreeItem } from './TreeItem';
import { Group, Member } from './types';

export const TreeView = ({
  group,
  itemsPerPage = 10,
  loading,
}: {
  group: Group;
  itemsPerPage?: number;
  loading: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { groupHead, members = [] } = { ...group };

  const [page, setPage] = useState(0);

  const getNextMembers = (members: Member[], page: number) =>
    members.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);

  const [displayingMembers, setDisplayingMembers] = useState(members);

  useEffect(() => {
    if (members.length) {
      members.length <= itemsPerPage
        ? setDisplayingMembers(members)
        : setDisplayingMembers(getNextMembers(members, page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const handleDisplayMore = () => {
    setDisplayingMembers(
      displayingMembers.concat(getNextMembers(members, page + 1))
    );
    setPage(page + 1);
  };

  if (!group) return <div />;

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" gap="medium">
        <Flex alignItems="center" gap="medium">
          <EntityAvatar
            size="small"
            type={groupHead.__typename || 'Person'}
            name={groupHead.avatar}
          />
          <Text weight="semibold">
            <TextLink href={`/client?client=${groupHead.id}`}>
              {groupHead.name}
            </TextLink>
          </Text>
        </Flex>

        {loading && <LoadingDots label="loading..." />}
        {members.length > 0 && (
          <IconButton
            label={isOpen ? 'Collapse' : 'Expand'}
            size="small"
            weight="none"
            icon={isOpen ? MinusSquareIcon : PlusSquareIcon}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          />
        )}
      </Flex>
      <Box
        css={{
          borderLeft: `2px solid #F0F0F1`,
          marginLeft: 16,
        }}
      >
        {isOpen && members.length > 0 && (
          <Fragment>
            <Box paddingTop="medium" paddingLeft="xxlarge">
              <Text size="small" weight="semibold">
                Members ({members.length})
                {members.length > itemsPerPage &&
                  `, displaying (${displayingMembers.length})`}
              </Text>
            </Box>
            {displayingMembers.map((member: Member) => (
              <TreeItem key={member.id} member={member} />
            ))}
            {members.length > itemsPerPage && (
              <Button
                label="Display more"
                weight="none"
                size="small"
                onClick={handleDisplayMore}
                disabled={displayingMembers.length >= members.length}
              />
            )}
          </Fragment>
        )}
      </Box>
    </Box>
  );
};
