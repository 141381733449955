/** @jsx jsx */
import { Fragment, useContext, useState } from 'react';
import { gql } from '@ts-gql/tag';
import { useQuery } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { Button } from '@reckon-web/button';
import { ChevronDownIcon } from '@reckon-web/icon/icons/ChevronDownIcon';
import { Edit2Icon } from '@reckon-web/icon/icons/Edit2Icon';
import { PlusIcon } from '@reckon-web/icon/icons/PlusIcon';
import { DropdownMenu, MenuItem } from '@reckon-web/dropdown';
import { DrawerController } from '@reckon-web/drawer';
import { Stack } from '@reckon-web/stack';
import { ChevronUpIcon, DownloadIcon, HelpCircleIcon } from '@reckon-web/icon';
import { Flex } from '@reckon-web/flex';
import { Box } from '@reckon-web/box';
import { Tooltip } from '@reckon-web/tooltip';
import { ThemeProvider } from '@reckon-web/theme';

import { ClientContext } from '..';
import { ADMIN, READ, WRITE, useAccessLevel } from '../../access';
import { ImportClientDialogContainer } from '../../importClient/ImportClientDialogContainer';
import { useUser } from '../../auth';
import { ConditionalTooltip } from '../../design-system/ConditionalTooltip';

import { CreateEditClientDrawer, EntityType } from './CreateEditClientDrawer';

// gql
export const ENTITY_TYPE = gql`
  query entityTypes {
    entityTypes {
      id
      name
      type
      selectable
    }
  }
` as import('../../../../__generated__/ts-gql/entityTypes').type;

type CreateEditClientDrawerContainerProps = {
  /* isEdit is true when the drawer should contain the edit functionality */
  isEdit?: boolean;
};

// component
export const CreateEditClientDrawerContainer = ({
  isEdit = false,
}: CreateEditClientDrawerContainerProps) => {
  const user = useUser();
  const client = useContext(ClientContext).client;
  const accessLevel = useAccessLevel();
  const [isOpen, setIsOpen] = useState(false);
  const [isImportClientDialogOpen, setIsImportClientDialogOpen] = useState(
    false
  );

  const [entityType, setEntityType] = useState({
    id: '',
    name: '',
    type: client?.__typename || '',
    __typename: '',
    selectable: false,
  });

  const { data: fetchedEntityTypes } = useQuery(ENTITY_TYPE);

  const isIndividual = client?.__typename
    ? client.__typename === 'Person'
    : entityType.type === 'Person';

  const { role } = user.profile;

  const handleEditClientClick = () => {
    setIsOpen(!isOpen);
  };

  const handleCreateClientClick = (fetchedEntity: EntityType) => {
    setEntityType(fetchedEntity);
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      {isEdit ? (
        <ConditionalTooltip
          when={accessLevel === READ}
          content="Edit client permission denied, Speak to an admin to upgrade your permissions level"
        >
          <Button
            weight="subtle"
            id="CreateEditClient-Button-edit-client"
            iconBefore={Edit2Icon}
            label="Edit client"
            onClick={handleEditClientClick}
            disabled={accessLevel === READ}
          />
        </ConditionalTooltip>
      ) : (
        <ConditionalTooltip
          when={accessLevel === READ || accessLevel === WRITE}
          content="Add client permission denied, Speak to an admin to upgrade your permissions level"
        >
          <Box>
            <ThemeProvider theme={{ colors: { text: 'rgb(32, 38, 45)' } }}>
              <DropdownMenu
                trigger={({ triggerProps, isOpen: isDropdownOpen }) => {
                  return (
                    <Button
                      {...triggerProps}
                      id="CreateEditClient-Button-add-client"
                      size="small"
                      iconBefore={PlusIcon}
                      iconAfter={
                        isDropdownOpen ? ChevronUpIcon : ChevronDownIcon
                      }
                      label="Add new client"
                      disabled={accessLevel === READ || accessLevel === WRITE}
                    />
                  );
                }}
              >
                <Stack>
                  <Flex paddingY="small" alignItems="center">
                    <MenuItem
                      id={`import-client`}
                      onClick={() => setIsImportClientDialogOpen(true)}
                      disabled={
                        accessLevel !== ADMIN || role?.toLowerCase() === 'user'
                      }
                      icon={DownloadIcon}
                      label="Import..."
                    />
                    {(accessLevel !== ADMIN ||
                      role?.toLowerCase() === 'user') && (
                      <Box marginX="small">
                        <ThemeProvider theme={{ colors: { text: '#000' } }}>
                          <Tooltip
                            content={
                              accessLevel !== ADMIN
                                ? 'You do not have admin rights to import clients. Speak to an admin to upgrade your permissions level on contacts+'
                                : 'You do not have system or admin rights in user management. Speak to an admin to upgrade your permissions level in user-management'
                            }
                          >
                            <span aria-label="Help" css={{ cursor: 'help' }}>
                              <HelpCircleIcon
                                size="small"
                                color="informative"
                              />
                            </span>
                          </Tooltip>
                        </ThemeProvider>
                      </Box>
                    )}
                  </Flex>
                  <Box paddingBottom="small">
                    <div css={{ height: 1, background: '#E4E6EA' }} />
                  </Box>
                </Stack>
                {fetchedEntityTypes?.entityTypes
                  .filter((fetchedEntity) => fetchedEntity.selectable)
                  .map((fetchedEntity) => (
                    <MenuItem
                      id={`CreateEditClient-MenuItem-${fetchedEntity.name}`}
                      key={fetchedEntity.id}
                      onClick={() => handleCreateClientClick(fetchedEntity)}
                      label={fetchedEntity.name}
                    />
                  ))}
              </DropdownMenu>
            </ThemeProvider>
          </Box>
        </ConditionalTooltip>
      )}

      <DrawerController isOpen={isOpen}>
        <ThemeProvider
          theme={{
            colors: {
              accent: '#3EBA63',
              background: '#ffffff',
              text: '#121212',
            },
          }}
        >
          <CreateEditClientDrawer
            isEdit={isEdit}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isIndividual={isIndividual}
            entityType={entityType}
          />
        </ThemeProvider>
      </DrawerController>
      <ImportClientDialogContainer
        isImportClientDialogOpen={isImportClientDialogOpen}
        setIsImportClientDialogOpen={setIsImportClientDialogOpen}
      />
    </Fragment>
  );
};
