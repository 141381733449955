/** @jsx jsx */

import React, { useEffect, useState } from 'react';
import { FragmentData, gql } from '@ts-gql/tag';
import { useQuery } from '@ts-gql/apollo';
import { useRouter } from 'next/router';

import { jsx } from '@reckon-web/core';
import {
  TabItem,
  TabList,
  useTabList,
  useTabListState,
} from '@reckon-web/tabs';
import { Box } from '@reckon-web/box';
import { Flex } from '@reckon-web/flex';
import { Columns } from '@reckon-web/columns';
import { LoadingDots } from '@reckon-web/loading';
import { Hidden } from '@reckon-web/media-query';
import { Divider } from '@reckon-web/divider';

import { useUser } from '../auth';
import { GenericError } from '../GenericError';
import { useError } from '../../utilities/hooks/useError';
import { useQueryRelationships } from '../../queries';
import { Relationship } from '../../queries/useQueryRelationships';
import { ADMIN, useAccessLevel } from '../access';
import { FullPageHorizontalDivider } from '../common/dividers/FullPageHorizontalDivider';
import { FullPageVerticalDivider } from '../common/dividers/FullPageVerticalDivider';

import ClientHeader from './ClientHeader';
import { ClientDetails } from './ClientDetails';
import { ClientContactInfo } from './ClientContactDetails';
import { RelationshipsTable } from './RelationshipsTable';
import { ClientRightColumn } from './clientRightColumn/ClientRightColumn';

export let ClientDetailsQuery = gql`
  query ClientDetails($id: ID!, $showSecGroup: Boolean = false) {
    client(id: $id) {
      ...ClientDetailsPage_client
    }
  }
` as import('../../../__generated__/ts-gql/ClientDetails').type;

export const ClientDetailsFragment = gql`
  fragment ClientDetailsPage_client on Client {
    id
    ...ClientDetails_client
    contactDetail {
      ...ClientContactInfo_contactDetail
    }
    familyGroup {
      ...DebtorAndFamilyGroup_group
    }
    debtor {
      ...DebtorAndFamilyGroup_group
    }
    securityGroup @include(if: $showSecGroup) {
      id
      name
      description
    }
  }
` as import('../../../__generated__/ts-gql/ClientDetailsPage_client').type;

export type Client = FragmentData<typeof ClientDetailsFragment>;

type ClientDetailsItemProps = {
  client: Client;
  relationships: readonly Relationship[];
};

type CachedClientID = string;

// Updates the localStorage with the list of recent clients
const updateRecentClients = (
  client: ClientDetailsItemProps['client'],
  tenant: string
) => {
  const recents: CachedClientID[] =
    JSON.parse(localStorage.getItem(`RecentClients_${tenant}`) as string) || [];

  const isInArray = recents.find((recent) => recent === client?.id);
  if (isInArray) return recents;

  if (!isInArray) {
    if (12 <= recents.length) {
      recents.pop();
    }
  } else if (isInArray) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    recents.splice(recents.indexOf(isInArray), 1)[0];
  }

  recents.unshift(client.id);

  localStorage.setItem(`RecentClients_${tenant}`, JSON.stringify(recents));

  return recents;
};

function ClientDetailsItem({ client, relationships }: ClientDetailsItemProps) {
  const tabItems = [
    {
      label: 'Details',
      element: <ClientDetails client={client} />,
    },

    {
      label: 'Relationships',
      element: (
        <RelationshipsTable relationships={relationships} client={client} />
      ),
    },
  ];

  if (client.contactDetail) {
    tabItems.splice(1, 0, {
      label: 'Contact info',
      element: <ClientContactInfo contactDetail={client.contactDetail!} />,
    });
  }

  const [listRef, setListRef] = useState<any>(null);
  const selectedIndex = useTabListState(listRef);
  const { listProps, getTabAttributes, getPanelAttributes } = useTabList(
    selectedIndex
  );

  return (
    <Box paddingY="medium">
      <TabList {...listProps} ref={setListRef}>
        {tabItems.map(({ label }, index) => {
          return (
            <TabItem
              key={label}
              {...getTabAttributes(index)}
              isSelected={index === selectedIndex}
            >
              {label}
            </TabItem>
          );
        })}
      </TabList>
      {tabItems.map(({ element, label }, index) => {
        return (
          <Box key={label} paddingY="medium" {...getPanelAttributes(index)}>
            {element}
          </Box>
        );
      })}
    </Box>
  );
}

export const ClientContext = React.createContext<
  Partial<ClientDetailsItemProps>
>({});

export function ClientDetailsPage() {
  let router = useRouter();
  const user = useUser();
  const accessLevel = useAccessLevel();
  const handleError = useError();
  let id = router.query.client as string;
  // showSecGroup only shows the security group for admins, Read permissions causes issues
  let { data, error } = useQuery(ClientDetailsQuery, {
    variables: { id, showSecGroup: accessLevel === ADMIN },
  });

  const { relationships } = useQueryRelationships(id);

  useEffect(() => {
    if (data?.client) {
      updateRecentClients(data.client, user.profile.currentTenantId);
    }
  });

  if (error?.graphQLErrors.length) {
    const isDenied = error?.graphQLErrors.find(
      (i) => i.message === 'Access denied by security service.'
    );
    if (isDenied) {
      handleError({
        title: 'Access denied by security service.',
        message:
          'This client belongs to a security group that you to not have access to',
      });
      router.push('/');
      return <div />;
    }
  }
  if (error) return <GenericError />;

  if (!data)
    return (
      <Flex
        width="100%"
        height="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingDots label="loading..." size="large" />
      </Flex>
    );
  if (!data.client) return <GenericError />;

  return (
    <ClientContext.Provider
      value={{
        client: data.client,
      }}
    >
      <Flex direction="column" alignItems="center">
        <Box>
          <Box paddingX="small" width="100%">
            <ClientHeader client={data.client} />
          </Box>
          <FullPageHorizontalDivider />

          <Box paddingX="xxlarge" width="100%">
            <Columns gap="medium" template={[8, 1, 3]} collapse="medium">
              <ClientDetailsItem
                client={data.client}
                relationships={relationships}
              />

              <Hidden below="medium">
                <FullPageVerticalDivider />
              </Hidden>
              <Hidden above="medium">
                <Box paddingY="large">
                  <Divider />
                </Box>
              </Hidden>

              <ClientRightColumn client={data.client} />
            </Columns>
          </Box>
        </Box>
      </Flex>
    </ClientContext.Provider>
  );
}
