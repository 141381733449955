// ts-gql-integrity:4ccb25f1a96b52fbd69b1e7f47d5663d
/*
ts-gql-meta-begin
{
  "hash": "b26c74bb4a1e72a1ad4079d6713d2476"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type CheckClientExistsQueryVariables = {
  sortName: SchemaTypes.Maybe<SchemaTypes.Scalars['String']>;
  externalClientId: SchemaTypes.Maybe<SchemaTypes.Scalars['ID']>;
};


type CheckClientExistsQuery = (
  { readonly __typename: 'Query' }
  & { readonly checkClientExists: SchemaTypes.Maybe<ReadonlyArray<(
    { readonly __typename: 'CheckClient' }
    & Pick<SchemaTypes.CheckClient, 'id' | 'sortName' | 'familyGroupId' | 'debtorId'>
  )>> }
);


export type type = TypedDocumentNode<{
  type: "query";
  result: CheckClientExistsQuery;
  variables: CheckClientExistsQueryVariables;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    CheckClientExists: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"CheckClientExists\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"sortName\"}},\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}},\"directives\":[]},{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"externalClientId\"}},\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"ID\"}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"checkClientExists\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"sortName\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"sortName\"}}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"externalClientId\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"externalClientId\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"sortName\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"familyGroupId\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"debtorId\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
