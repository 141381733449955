/** @jsx jsx */

import {
  ButtonHTMLAttributes,
  Fragment,
  ReactElement,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
} from 'react';

import { ActionButton, ActionButtonProps } from '@reckon-web/button';
import { jsx } from '@reckon-web/core';
import { ChevronDownIcon } from '@reckon-web/icon/icons/ChevronDownIcon';
import { ChevronUpIcon } from '@reckon-web/icon/icons/ChevronUpIcon';
import { PopoverDialog, usePopover } from '@reckon-web/popover';
import { makeId, useForkedRef, useId } from '@reckon-web/utils';

import { Menu } from './Menu';
import { useCollectionFocus } from './utils';

// Button
// ------------------------------

export const DropdownButton = forwardRef<any, ActionButtonProps>(
  (props, ref) => {
    if ('children' in props) {
      return <ActionButton ref={ref} type="button" {...props} />;
    }

    return (
      <ActionButton
        ref={ref}
        type="button"
        {...props}
        iconAfter={props['aria-expanded'] ? ChevronUpIcon : ChevronDownIcon}
      />
    );
  }
);
DropdownButton.displayName = 'DropdownButton';

// Menu
// ------------------------------

const alignment = {
  left: 'bottom-start',
  right: 'bottom-end',
} as const;

type TriggerRendererType = (options: {
  isOpen: boolean;
  triggerProps: ButtonHTMLAttributes<HTMLButtonElement>;
}) => ReactElement;

export type DropdownMenuProps = {
  align?: keyof typeof alignment;
  children?: ReactNode;
  consumerId?: string;
  trigger: string | TriggerRendererType;
};

export const DropdownMenu = ({
  align = 'left',
  children,
  consumerId,
  trigger,
}: DropdownMenuProps) => {
  const id = useId();
  const menuId = makeId('dropdown-menu', id);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const internalTriggerRef = useRef<HTMLElement>();
  const { isOpen, setOpen, dialog, trigger: popoverTrigger } = usePopover({
    placement: alignment[align],
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  // handle focus
  // NOTE: we should close the popover if the user tabs outside the dropdown menu
  useCollectionFocus({
    containerRef: containerRef as any,
    direction: 'vertical',
    targetRoles: ['menuitem', 'menuitemradio', 'menuitemcheckbox'],
    listenWhen: isOpen,
    onSelect: () => {
      // HACK: wait a moment for the click handler before removing the items
      setTimeout(() => setOpen(false), 1);
    },
  });

  // focus the target element on close
  useEffect(() => {
    let trigger = internalTriggerRef.current;
    if (isOpen) {
      return () => {
        trigger?.focus();
      };
    }
  }, [isOpen]);

  // trigger UI
  const forkedTriggerRef = useForkedRef(internalTriggerRef, popoverTrigger.ref);
  const triggerProps = {
    ref: forkedTriggerRef,
    onClick: () => setOpen((s) => !s),
    ...popoverTrigger.props,
    'aria-haspopup': 'menu',
    'aria-controls': menuId,
  } as const;
  const triggerElement =
    typeof trigger === 'function' ? (
      trigger({ isOpen, triggerProps })
    ) : (
      <DropdownButton id={consumerId} label={trigger} {...triggerProps} />
    );

  // We need a reference to the menu element to prepare keyboard navigation, but
  // we don't want the menu items to be focusable until the menu is open
  const menuContent = isOpen ? children : null;

  return (
    <Fragment>
      {triggerElement}
      <PopoverDialog ref={dialog.ref} {...dialog.props} isVisible={isOpen}>
        <Menu id={menuId} ref={containerRef}>
          {menuContent}
        </Menu>
      </PopoverDialog>
    </Fragment>
  );
};
