/** @jsx jsx */

import {
  Fragment,
  HTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DayModifiers } from 'react-day-picker';
import FocusLock from 'react-focus-lock';
import {
  formatISO,
  isAfter,
  isBefore,
  isEqual,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
} from 'date-fns';

import { jsx } from '@reckon-web/core';
import { formatDateObj } from '@reckon-web/date-input';
import { PopoverDialog, usePopover } from '@reckon-web/popover';
import { InputSizeType, InputWeightType } from '@reckon-web/text-input';

import { Calendar, CalendarProps } from './Calendar';
import { InputButton, Placeholder } from './styled';
import { ISODate, ISODateRange } from './types';

type InternalDateRange = {
  start?: Date;
  end?: Date;
};

type SelectedInput = 'start' | 'end' | 'none';

export type DateRangePickerProps = {
  /** When true, the triggers will be disabled. */
  disabled?: boolean;
  /** Indicate that the value does not conform to the format expected by the application. */
  invalid?: boolean;
  /** Called when the value changes. */
  onChange: (value: ISODateRange | undefined) => void;
  /** The value of the calendar, displayed in the trigger. */
  value: ISODateRange | undefined;
  size?: InputSizeType;
  weight?: InputWeightType;
} & Pick<
  CalendarProps,
  'disabledDays' | 'fromMonth' | 'initialMonth' | 'modifiers' | 'toMonth'
>;

export const DateRangePicker = ({
  disabled = false,
  invalid = false,
  onChange,
  value,
  // calendar props
  disabledDays,
  fromMonth,
  initialMonth,
  modifiers: consumerModifiers,
  toMonth,
  size = 'medium',
  weight = 'subtle',
  ...inputProps
}: DateRangePickerProps) => {
  const [selectedInput, setSelectedInput] = useState<SelectedInput>('none');

  // derived state is bad. this is a necessary evil to support partial range
  // selection within the calendar
  let [internalValue, setInternalValue] = useState<InternalDateRange>(() =>
    isoRangeToDateRange(value)
  );

  // sync state with the popover dialog and the "focused input"
  const { isOpen, setOpen, dialog, trigger } = usePopover({
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
  const handleOpen = (input: SelectedInput) => () => {
    setSelectedInput(input);
    setOpen(true);
  };

  // sync internal state
  useEffect(() => {
    if (!isOpen) setSelectedInput('none');
  }, [isOpen]);

  useEffect(() => {
    setInternalValue((iv) => {
      const nextRange = isoRangeToDateRange(value);
      if (
        nextRange === undefined ||
        !(
          existsAndIsEqual(nextRange.start, iv.start) &&
          existsAndIsEqual(nextRange.end, iv.end)
        )
      ) {
        return nextRange;
      }

      return iv;
    });
  }, [value]);

  // day click is called from the calendar widget
  const handleDayClick = useCallback(
    (day: Date, { disabled }: DayModifiers) => {
      if (disabled) {
        return;
      }

      setInternalValue((iv) => {
        let resolvedDate: InternalDateRange = {};

        if (selectedInput === 'start') {
          resolvedDate = {
            start: day,
            // NOTE: invert behaviour (day is outside the valid range):
            // selected start day is after the end date
            end: iv.end && day > iv.end ? undefined : iv.end,
          };

          // we're done with the start date, move "focus" to the end date
          setSelectedInput('end');
        } else {
          // NOTE: invert behaviour (day is outside the valid range):
          // selected end day is before the start date
          if (!iv.start || (iv.start && day < iv.start)) {
            resolvedDate = {
              start: day,
              end: undefined,
            };

            // standard behaviour
          } else {
            resolvedDate = {
              start: iv.start,
              end: day,
            };

            // wait a moment so the user has time to see the day become selected
            setTimeout(() => {
              setOpen(false);
            }, 300);
          }
        }

        // only update the consumer's value once we have a valid range,
        // then keep it up-to-date
        if (resolvedDate.start && resolvedDate.end) {
          onChange({
            start: formatISO(resolvedDate.start, {
              representation: 'date',
            }) as ISODate,
            end: formatISO(resolvedDate.end, {
              representation: 'date',
            }) as ISODate,
          });
        }

        // resolve this set state action
        return resolvedDate;
      });
    },
    [onChange, selectedInput, setOpen]
  );

  // without an end date we only want the calendar to know about the start date
  // so the appropriate modifiers are applied
  const selectedDays = !internalValue.end
    ? internalValue.start
    : {
        from: internalValue.start as Date,
        to: internalValue.end as Date,
      };

  // stringify the internal date object for button labels
  const formattedStart = internalValue.start
    ? formatDateObj(internalValue.start)
    : undefined;
  const formattedEnd = internalValue.end
    ? formatDateObj(internalValue.end)
    : undefined;

  // modifiers allow us to style the calendar for range input
  const modifiers = {
    ...consumerModifiers,
    lastOfMonth: (day: Date) => isLastDayOfMonth(day),
    firstOfMonth: (day: Date) => isFirstDayOfMonth(day),
    rangeStart: (day: Date) =>
      Boolean(internalValue.start && isSameDay(day, internalValue.start)),
    rangeEnd: (day: Date) =>
      Boolean(internalValue.end && isSameDay(day, internalValue.end)),
    rangeBetween: (day: Date) => {
      if (!internalValue.start || !internalValue.end) {
        return false;
      }
      if (
        isSameDay(day, internalValue.start) ||
        isSameDay(day, internalValue.end)
      ) {
        return false;
      }

      return (
        isAfter(day, internalValue.start) && isBefore(day, internalValue.end)
      );
    },
  };
  const calendarProps = {
    disabledDays,
    fromMonth,
    initialMonth,
    modifiers,
    toMonth,
  };

  const placeholder = <Placeholder>dd/mm/yyyy</Placeholder>;

  return (
    <Fragment>
      <ButtonWrapper ref={trigger.ref}>
        <InputButton
          disabled={disabled}
          invalid={invalid}
          aria-label={ariaLabel('Choose start date', formattedStart)}
          onClick={handleOpen('start')}
          isSelected={isOpen && selectedInput === 'start'}
          size={size}
          weight={weight}
          {...inputProps}
          {...trigger.props}
        >
          {formattedStart || placeholder}
        </InputButton>
        <InputButton
          disabled={disabled}
          invalid={invalid}
          aria-label={ariaLabel('Choose end date', formattedEnd)}
          onClick={handleOpen('end')}
          isSelected={isOpen && selectedInput === 'end'}
          size={size}
          weight={weight}
          {...trigger.props}
        >
          {formattedEnd || placeholder}
        </InputButton>
      </ButtonWrapper>
      <PopoverDialog
        isVisible={isOpen}
        ref={dialog.ref}
        key="range-dialog"
        {...dialog.props}
      >
        <FocusLock autoFocus returnFocus disabled={!isOpen}>
          <Calendar
            onDayClick={handleDayClick}
            selectedDays={selectedDays}
            numberOfMonths={2}
            {...calendarProps}
          />
        </FocusLock>
      </PopoverDialog>
    </Fragment>
  );
};

// Utils
// ------------------------------

function ariaLabel(prefix: string, day?: string) {
  return prefix + (day ? `, selected date is ${day}` : '');
}

function isoRangeToDateRange(range?: ISODateRange): InternalDateRange {
  if (!range) {
    return {
      start: undefined,
      end: undefined,
    };
  }

  return {
    start: new Date(range.start),
    end: new Date(range.end),
  };
}

function existsAndIsEqual(
  dateLeft: Date | undefined,
  dateRight: Date | undefined
) {
  if (!dateLeft) return false;
  if (!dateRight) return false;

  return isEqual(dateLeft, dateRight);
}

// Styled Components
// ------------------------------

const ButtonWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => (
  <div
    ref={ref}
    css={{
      display: 'flex',

      '> div:first-of-type > button': {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      },
      '> div:last-of-type > button': {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        marginLeft: 2,
      },
    }}
    {...props}
  />
));
