/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { Form, countVisibleValidationErrors } from '@reckon-web/forms';

import { ContactForm } from '../forms/ContactForm';
import { AddressForm } from '../forms/AddressForm';
import { IndividualForm } from '../forms/IndividualForm';
import { OrganisationForm } from '../forms/OrganisationForm';
import { ManagementForm } from '../forms/ManagementForm';

import {
  createEditIndividualSchema,
  createEditOrganisationSchema,
} from './formSchemas';

export const getTabs = (
  isIndividual: boolean = false,
  isEdit: boolean = false,
  individualForm: Form<typeof createEditIndividualSchema>,
  organisationForm: Form<typeof createEditOrganisationSchema>
) => {
  return isIndividual
    ? [
        {
          label: 'General details',
          issueCount: countVisibleValidationErrors(
            individualForm.fields.personDetails
          ),
          content: (
            <IndividualForm
              form={individualForm.fields.personDetails}
              isEdit={isEdit}
            />
          ),
        },
        {
          label: 'Contact info',
          issueCount: countVisibleValidationErrors(
            individualForm.fields.contactDetail
          ),
          content: (
            <ContactForm
              form={individualForm.fields.contactDetail}
              isEdit={isEdit}
            />
          ),
        },
        {
          label: 'Street address',
          issueCount: countVisibleValidationErrors(
            individualForm.fields.streetAddress
          ),
          content: (
            <AddressForm
              form={individualForm.fields.streetAddress}
              formName="Street address"
            />
          ),
        },
        {
          label: 'Postal address',
          issueCount: countVisibleValidationErrors(
            individualForm.fields.postalAddress
          ),
          content: (
            <AddressForm
              form={individualForm.fields.postalAddress}
              formName="Postal address"
            />
          ),
        },
        {
          label: 'Registered office address',
          issueCount: countVisibleValidationErrors(
            individualForm.fields.registeredAddress
          ),
          content: (
            <AddressForm
              form={individualForm.fields.registeredAddress}
              formName="Registered office address"
            />
          ),
        },
        ...(!isEdit
          ? [
              {
                label: 'Management',
                issueCount: countVisibleValidationErrors(
                  individualForm.fields.management
                ),
                content: (
                  <ManagementForm form={individualForm.fields.management} />
                ),
              },
            ]
          : []),
      ]
    : [
        {
          label: 'General details',
          issueCount: countVisibleValidationErrors(
            organisationForm.fields.organisationDetails
          ),
          content: (
            <OrganisationForm
              form={organisationForm.fields.organisationDetails}
              isEdit={isEdit}
            />
          ),
        },
        {
          label: 'Contact info',
          issueCount: countVisibleValidationErrors(
            organisationForm.fields.contactDetail
          ),
          content: (
            <ContactForm
              form={organisationForm.fields.contactDetail}
              isEdit={isEdit}
            />
          ),
        },
        {
          label: 'Street address',
          issueCount: countVisibleValidationErrors(
            organisationForm.fields.streetAddress
          ),
          content: (
            <AddressForm
              form={organisationForm.fields.streetAddress}
              formName="Street address"
            />
          ),
        },
        {
          label: 'Postal address',
          issueCount: countVisibleValidationErrors(
            organisationForm.fields.postalAddress
          ),
          content: (
            <AddressForm
              form={organisationForm.fields.postalAddress}
              formName="Postal address"
            />
          ),
        },
        {
          label: 'Registered office address',
          issueCount: countVisibleValidationErrors(
            organisationForm.fields.registeredAddress
          ),
          content: (
            <AddressForm
              form={organisationForm.fields.registeredAddress}
              formName="Registered office address"
            />
          ),
        },
        ...(!isEdit
          ? [
              {
                label: 'Management',
                issueCount: countVisibleValidationErrors(
                  organisationForm.fields.management
                ),
                content: (
                  <ManagementForm form={organisationForm.fields.management} />
                ),
              },
            ]
          : []),
      ];
};
