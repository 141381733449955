export const objectMap = (
  obj: any,
  fn: (value: any, key: any, i: any) => void
) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value], i) => [key, fn(value, key, i)])
  );

export function arrayUnique(array: string[]) {
  let a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}
