/** @jsx jsx */
import { useState } from 'react';

import { jsx } from '@reckon-web/core';
import { Box } from '@reckon-web/box';
import { Stack } from '@reckon-web/stack';
import { Heading } from '@reckon-web/heading';
import { Form } from '@reckon-web/forms';
import { Divider } from '@reckon-web/divider';

import { organisationSchema } from '../utils';
import {
  createOrganisationMailName,
  createOrganisationSortName,
} from '../utils/generateformfields';
import { TextField } from '../../../design-system/text-field';

import { GeneralDetailsForm } from './GeneralDetailsForm';

export const OrganisationForm = ({
  isEdit = false,
  form,
}: {
  isEdit?: boolean;
  form: Form<typeof organisationSchema>;
}) => {
  const [autoMailName, setAutoMailName] = useState(isEdit ? false : true);
  const [autoSortName, setAutoSortName] = useState(isEdit ? false : true);

  const generateFormFields = (name: string, value: string) => {
    const updatedOrganisation = { ...form.value, [name]: value };
    const updatedForm: any = form;

    autoSortName &&
      createOrganisationSortName(updatedOrganisation, updatedForm);
    autoMailName &&
      createOrganisationMailName(updatedOrganisation, updatedForm);

    // update the registeredName field
    updatedForm.fields[name].setState({
      ...updatedForm.fields[name],
      value,
    });
  };

  return (
    <Stack marginY="medium" gap="large">
      <Heading level="3">General Details</Heading>

      <TextField
        {...form.fields.registeredName.props}
        autoComplete="off"
        id="Registered-name"
        label="Registered name*"
        placeholder="Enter registered name"
        onChange={(e) =>
          generateFormFields('registeredName', e.target.value || '')
        }
      />
      <TextField
        {...form.fields.tradingAs.props}
        autoComplete="off"
        id="Trading-as"
        label="Trading as"
        placeholder="Enter trading name"
      />

      <Box marginY="medium">
        <Divider />
      </Box>

      <GeneralDetailsForm
        isEdit={isEdit}
        form={form.fields.generalDetails}
        autoMailName={{ autoMailName, setAutoMailName }}
        autoSortName={{ autoSortName, setAutoSortName }}
        names={{ registeredName: form.fields.registeredName.value }}
        entityType="Organisation"
      />
    </Stack>
  );
};
