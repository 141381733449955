import React, { forwardRef } from 'react';

import { LinkComponentProps, useLinkComponent } from '@reckon-web/core';

export type LinkProps = LinkComponentProps;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const LinkComponent = useLinkComponent();

  return <LinkComponent ref={ref} {...props} />;
});
