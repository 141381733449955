import dayjs from 'dayjs';

import { ClientObjectType, Failure, StaffMember } from '../types';

function getMonthFromString(mon: string) {
  if (!mon) return null;
  return new Date(Date.parse(mon + ' 1, 2012')).getMonth() + 1;
}

function findStaff(
  staffType: string,
  staff: string,
  staffMembers: StaffMember[] | undefined
) {
  if (!staff) return undefined;
  const staffMember = staffMembers?.find(
    (s) => s.name?.toUpperCase().trim() === staff?.toUpperCase().trim()
  );

  if (staffMember && staffType === 'Partner' && staffMember.isPartner)
    return staffMember.id;
  if (staffMember && staffType === 'Manager' && staffMember.isManager)
    return staffMember.id;
  if (staffMember && staffType === 'Staff') return staffMember.id;

  return undefined;
}

const formatDate = (date: string) => {
  let dateString = date;
  if (dateString.includes('/')) {
    dateString = dateString.indexOf('/') === 1 ? `0${date}` : date;
    return dayjs(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }
  dateString = dateString.indexOf('-') === 1 ? `0${date}` : date;
  return dayjs(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD');
};

export let createClientObject = (
  type: string,
  entityTypeId: string | undefined,
  titleRow: string[],
  item: string[],
  fetchedStaff: StaffMember[] | undefined
) => {
  let warnings: { failure: Failure; row: number }[] = [];
  let errors: string[] = [];

  const partnerId = findStaff(
    'Partner',
    item[titleRow.indexOf('Partner')],
    fetchedStaff
  );

  const managerId = findStaff(
    'Manager',
    item[titleRow.indexOf('Manager')],
    fetchedStaff
  );

  const staffId = findStaff(
    'Staff',
    item[titleRow.indexOf('Staff')],
    fetchedStaff
  );

  const externalClientId = item[titleRow.indexOf('ExtClientID')];

  let client: ClientObjectType = {
    entityType: { id: entityTypeId },

    sortName: item[titleRow.indexOf('Sortname')],

    ...(externalClientId && {
      externalClientId,
    }),

    salutation: item[titleRow.indexOf('Salutation')],
    mailName: item[titleRow.indexOf('MailName')],
    // TO Do : change to number
    balanceMonth: getMonthFromString(item[titleRow.indexOf('BalanceMonth')]),
    ...(item[titleRow.indexOf('StartDate')] && {
      startDate: formatDate(item[titleRow.indexOf('StartDate')]),
    }),
    ...(item[titleRow.indexOf('EndDate')] && {
      endDate: formatDate(item[titleRow.indexOf('EndDate')]),
    }),

    taxDetails: {
      abnNumber: item[titleRow.indexOf('ABN')],
      abnDivisionNumber: item[titleRow.indexOf('ABNDiv')],
      tfnNumber: item[titleRow.indexOf('TFN')],
      acnArbnNumber: item[titleRow.indexOf('ACN')],

      irdNumber: item[titleRow.indexOf('IRD')],
      nzbnNumber: item[titleRow.indexOf('NZBN')],
    },

    attributes: {
      ...(item[titleRow.indexOf('DOB')] && {
        dateOfBirth: formatDate(item[titleRow.indexOf('DOB')]),
      }),
      ...(item[titleRow.indexOf('DOD')] && {
        dateOfDeath: formatDate(item[titleRow.indexOf('DOD')]),
      }),
      ...(item[titleRow.indexOf('Age')] && {
        age: item[titleRow.indexOf('Age')],
      }),
      placeOfBirth: item[titleRow.indexOf('PlaceOfBirth')],
      annualReturnMonth: getMonthFromString(
        item[titleRow.indexOf('AnnualReturnMonth')]
      ),
    },

    contactDetail: {
      primaryPhone: item[titleRow.indexOf('PrimaryPh')],
      primaryEmail: item[titleRow.indexOf('PrimaryEmail')],
      fax: item[titleRow.indexOf('Fax')],
      otherPhone: item[titleRow.indexOf('OtherPh')],
      otherEmail: item[titleRow.indexOf('OtherEmail')],
      importantContactInfo: item[titleRow.indexOf('OtherInfo')],
      website: item[titleRow.indexOf('Website')],

      streetAddress: {
        line1: item[titleRow.indexOf('StreetLine1')],
        line2: item[titleRow.indexOf('StreetLine2')],
        city: item[titleRow.indexOf('StreetCity')],
        state: item[titleRow.indexOf('StreetState')],
        postcode: item[titleRow.indexOf('StreetPcode')],
        country: item[titleRow.indexOf('StreetCountry')],
      },

      postalAddress: {
        line1: item[titleRow.indexOf('PostalLine1')],
        line2: item[titleRow.indexOf('PostalLine2')],
        city: item[titleRow.indexOf('PostalCity')],
        state: item[titleRow.indexOf('PostalState')],
        postcode: item[titleRow.indexOf('PostalPcode')],
        country: item[titleRow.indexOf('PostalCountry')],
      },

      registeredAddress: {
        line1: item[titleRow.indexOf('RegLine1')],
        line2: item[titleRow.indexOf('RegLine2')],
        city: item[titleRow.indexOf('RegCity')],
        state: item[titleRow.indexOf('RegState')],
        postcode: item[titleRow.indexOf('RegPCode')],
        country: item[titleRow.indexOf('RegCountry')],
      },
    },
  };

  if (type === 'Person') {
    client['person'] = {
      title: item[titleRow.indexOf('Title')],
      firstName: item[titleRow.indexOf('FirstName')],
      middleName: item[titleRow.indexOf('MidName')],
      lastName: item[titleRow.indexOf('LastName')],
      initials: item[titleRow.indexOf('Initials')],
    };
  } else {
    client['organisation'] = {
      registeredName: item[titleRow.indexOf('RegName')],
      tradingAs: item[titleRow.indexOf('TradingAs')],
    };
  }

  if (partnerId) client.attributes.partner = { id: partnerId };
  if (managerId) client.attributes.manager = { id: managerId };
  if (staffId) client.attributes.staff = { id: staffId };
  return {
    client,
    warnings,
    errors,
  };
};
