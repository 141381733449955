import { ReactNode, useContext } from 'react';
import React from 'react';
import { gql } from '@ts-gql/tag';
import { useQuery } from '@ts-gql/apollo';
import { useRouter } from 'next/router';

import { Flex } from '@reckon-web/flex';
import { LoadingDots } from '@reckon-web/loading';

import { useUser } from '../components/auth';

import { Header } from './header';

export const ADMIN = 1;
export const ADD_WRITE = 2;
export const WRITE = 3;
export const READ = 4;

export let UserAccess = gql`
  query UserAccess($id: ID!) {
    user(id: $id) {
      id
      accessLevel
    }
  }
` as import('../../__generated__/ts-gql/UserAccess').type;

let AccessContext = React.createContext<string>('READ');

export function AccessProvider({ children }: { children: ReactNode }) {
  const { staffId } = useUser().profile;
  const router = useRouter();

  let { data, loading } = useQuery(UserAccess, {
    variables: { id: staffId },
  });

  if (loading) {
    return (
      <div>
        <Header />
        <Flex
          width="100%"
          height="60vh"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingDots label="loading..." size="large" />
        </Flex>
      </div>
    );
  }

  if (
    router.pathname !== '/' &&
    !router.pathname.includes('/client') &&
    data?.user &&
    levels[data?.user?.accessLevel || 'READ'] > 1
  ) {
    router.push('/');
  }

  return (
    <AccessContext.Provider value={data?.user?.accessLevel || 'READ'}>
      {children}
    </AccessContext.Provider>
  );
}

const levels = {
  ADMIN,
  ADD_WRITE,
  WRITE,
  READ,
};

export function useAccessLevel() {
  let accessLevel = useContext(AccessContext);
  return levels[accessLevel as keyof typeof levels];
}
