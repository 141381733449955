/** @jsx jsx */
import { createRef, useCallback, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';

import { jsx } from '@reckon-web/core';
import { Box } from '@reckon-web/box';
import { Stack } from '@reckon-web/stack';
import { Notice } from '@reckon-web/notice';
import { useTheme } from '@reckon-web/theme';

import { Failure } from '../types';

import { WarningMessage } from './WarningMessage';

export const ImportWarnings = ({
  importWarnings,
}: {
  importWarnings: { failure: Failure; row: number }[];
}) => {
  const listRef: any = createRef();
  const warnings = importWarnings.flat().sort((a, b) => a.row - b.row);
  const warningRows = warnings.map((i) => i.row);
  let uniqueRows = [...new Set(warningRows)];
  const uniqueRowsLength = uniqueRows.length;

  const { sizing } = useTheme();

  const sizeMap: any = useRef({});

  const setSize = useCallback(
    (index, size) => {
      listRef.current.resetAfterIndex(0);
      sizeMap.current = { ...sizeMap.current, [index]: size };
    },
    [listRef]
  );
  const getSize = useCallback(
    (index) => sizeMap.current[index] || sizing.medium,
    [sizing.medium]
  );

  if (!warnings.length) return <div />;

  let tableHeight = 450;
  if (warnings.length < 15) {
    const dynamicHeight: number = Object.values(sizeMap.current).reduce(
      (acc: number, curr: any) => {
        return (acc = curr + acc);
      },
      0
    );
    if (dynamicHeight === 0) tableHeight = warnings.length * 40;
    else if (dynamicHeight + 50 < 580) tableHeight = dynamicHeight + 50;
  }

  return (
    <Stack>
      <Notice tone="cautious">
        Data from {uniqueRowsLength}{' '}
        {uniqueRowsLength > 1 ? 'rows were' : 'row was'} unable to be imported.
        <Box maxHeight={450}>
          <Stack dividers marginTop="medium">
            <List
              ref={listRef}
              height={tableHeight}
              width={480}
              itemCount={warnings.length}
              itemSize={getSize}
            >
              {({ index, style }) => {
                return (
                  <div style={style}>
                    <WarningMessage
                      index={index}
                      warningItem={warnings[index]}
                      setSize={setSize}
                    />
                  </div>
                );
              }}
            </List>
          </Stack>
        </Box>
      </Notice>
    </Stack>
  );
};
