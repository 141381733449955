import dayjs from 'dayjs';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export const parseCsvData = (data: string[][]) => {
  let titleRow: string[] = [];

  const records = data?.filter((item: string[], index: number) => {
    if (index === 0) {
      titleRow = item.map((i: string) => i);
    }
    return item.join('') !== '' && index !== 0;
  });

  return {
    records,
    titleRow,
  };
};
