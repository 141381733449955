// ts-gql-integrity:b1eaf062e85019582e979066b81f6e95
/*
ts-gql-meta-begin
{
  "hash": "952d86c723086fc5121a5fbfeb05f69c"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type createClientMutationVariables = {
  createClientInput: SchemaTypes.CreateClientInput;
};


type createClientMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createClient: (
    { readonly __typename: 'Organisation' }
    & Pick<SchemaTypes.Organisation, 'id' | 'sortName'>
  ) | (
    { readonly __typename: 'Person' }
    & Pick<SchemaTypes.Person, 'id' | 'sortName'>
  ) }
);


export type type = TypedDocumentNode<{
  type: "mutation";
  result: createClientMutation;
  variables: createClientMutationVariables;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    createClient: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"createClient\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"createClientInput\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CreateClientInput\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"createClient\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"createClientInput\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"sortName\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
