/** @jsx jsx */
import { Fragment, useState } from 'react';
import { FragmentData, gql } from '@ts-gql/tag';
import { useApolloClient, useMutation } from '@ts-gql/apollo';

import { Button } from '@reckon-web/button';
import { jsx } from '@reckon-web/core';
import { SideDrawer } from '@reckon-web/side-drawer';
import { Stack } from '@reckon-web/stack';
import { useToasts } from '@reckon-web/toast';
import {
  AutocompleteField,
  entityOptionRenderer,
} from '@reckon-web/autocomplete';
import { field, useForm, useFormSnapshot, useSubmit } from '@reckon-web/forms';

import { DiscardConfirmationDialog } from '../../../common/dialogs/DiscardConfirmationDialog';
import { useError } from '../../../../utilities/hooks/useError';
import { CLIENT_SEARCH } from '../../../SearchClients';
import { ClientDetailsFragment } from '../..';
type Client = {
  toggleIsOpen: () => void;
  client: FragmentData<typeof ClientDetailsFragment>;
};
const familyGroupForm = field.object({
  familyGroup: field.autocomplete()(),
});
// component
export const EditFamilyGroupDrawer = ({ toggleIsOpen, client }: Client) => {
  const apolloClient = useApolloClient();
  const { addToast } = useToasts();
  const form = useForm(familyGroupForm, {
    familyGroup: {
      value: client?.familyGroup?.head?.id || '',
      label: client?.familyGroup?.head?.sortName || '',
    },
  });
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
  const { isDirty } = useFormSnapshot(form);
  const [UpdatefamilyGroup, { loading }] = useMutation(UPDATE_FAMILY_GROUP);
  const handleError = useError();
  const handleSaveAndCloseSubmit = useSubmit(form, (value) => {
    UpdatefamilyGroup({
      variables: {
        id: client.id,
        familyGroupId: value.familyGroup?.value || '',
      },
    })
      .then(() => {
        toggleIsOpen();
        addToast({
          title: 'Family group update successful',
          message: `Successfully updated the family group to ${value.familyGroup?.label}`,
          tone: 'positive',
        });
      })
      .catch((error) => {
        handleError({ title: 'Failed to update debtor group', error });
      });
  });
  const handleCancel = () => {
    isDirty ? setIsDiscardDialogOpen(true) : toggleIsOpen();
  };
  return (
    <Fragment>
      <SideDrawer>
        <SideDrawer.Form onSubmit={handleSaveAndCloseSubmit}>
          <SideDrawer.Header>
            <SideDrawer.Title>Change family group</SideDrawer.Title>
          </SideDrawer.Header>
          <SideDrawer.Body>
            <Stack marginY="xlarge" gap="xlarge" paddingX="xlarge">
              <AutocompleteField
                label="Search clients to assign a new family group"
                {...form.fields.familyGroup.props}
                loadOptions={async (inputValue) => {
                  const { data } = await apolloClient.query({
                    query: CLIENT_SEARCH,
                    variables: { searchInput: inputValue },
                  });
                  return (
                    data?.searchClients?.map((client) => {
                      return {
                        value: client?.id || '',
                        label: client?.sortName || '',
                      };
                    }) || []
                  );
                }}
                renderOption={entityOptionRenderer}
              />
            </Stack>
          </SideDrawer.Body>
          <SideDrawer.Footer>
            <Button label="Save & close" type="submit" loading={loading} />
            <Button
              label="Cancel"
              weight="none"
              onClick={handleCancel}
              disabled={loading}
            />
          </SideDrawer.Footer>
        </SideDrawer.Form>
      </SideDrawer>
      <DiscardConfirmationDialog
        isDiscardDialogOpen={isDiscardDialogOpen}
        setIsDiscardDialogOpen={setIsDiscardDialogOpen}
        callback={() => toggleIsOpen()}
      />
    </Fragment>
  );
};
export const UPDATE_FAMILY_GROUP = gql`
  mutation UpdateFamilyGroup(
    $id: ID!
    $familyGroupId: ID!
    $showSecGroup: Boolean = false
  ) {
    updateClient(id: $id, input: { familyGroup: { id: $familyGroupId } }) {
      id
      ...ClientDetailsPage_client
    }
  }
` as import('../../../../../__generated__/ts-gql/UpdateFamilyGroup').type;
