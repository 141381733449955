import { formatISO } from 'date-fns';

export const getCurrentISODate = () => {
  const currentDate = new Date();
  return formatISO(currentDate, {
    representation: 'date',
  });
};

export const formatDate = (date: string) => {
  if (!date) return null;
  const dateWithNoTimezone = removeTimezoneFromDate(new Date(date));
  return Intl.DateTimeFormat('en-NZ').format(dateWithNoTimezone);
};

export const removeTimezoneFromDate = (date: Date) => {
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};

export const monthsArray = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const monthsObject: any = {
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};
