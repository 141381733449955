/** @jsx jsx */

import { jsx } from '@reckon-web/core';
import { ArrowUpIcon } from '@reckon-web/icon/icons/ArrowUpIcon';

export const SortIndicator = ({ tone }: { tone: 'base' | 'dim' }) => {
  return (
    <span
      aria-hidden="true"
      css={{
        lineHeight: 0,
        paddingInline: 2,
        opacity: 0,
        transition: 'opacity 100ms linear, transform 150ms ease',

        '[aria-sort] &': {
          opacity: 1,
        },
        '[aria-sort="descending"] &': {
          transform: `rotate(180deg)`,
        },
      }}
    >
      <ArrowUpIcon size="small" color={tone} />
    </span>
  );
};
