import { gql } from '@ts-gql/tag';

import { ApolloClientType, CheckClientExistsType } from '../types';

let CHECK_CLIENT_EXIST = gql`
  query CheckClientExists($sortName: String, $externalClientId: ID) {
    checkClientExists(
      sortName: $sortName
      externalClientId: $externalClientId
    ) {
      id
      sortName
      familyGroupId
      debtorId
    }
  }
` as import('../../../../__generated__/ts-gql/CheckClientExists').type;

export const checkIfExistingClient = async (
  input: CheckClientExistsType,
  apolloClient: ApolloClientType
) => {
  const { data } = await apolloClient.query({
    query: CHECK_CLIENT_EXIST,
    variables: {
      sortName: input.sortName || '',
      externalClientId: input.externalClientId || '',
    },
    fetchPolicy: 'no-cache',
  });

  return data?.checkClientExists?.length ? data.checkClientExists[0] : null;
};
