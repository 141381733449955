import React from 'react';

import { Field, FieldProps } from '@reckon-web/field';
import { SelectInput, SelectInputProps } from '@reckon-web/select-input';

export type SelectFieldProps<Value extends string | number> = SelectInputProps<
  Value
> &
  FieldProps;

export const SelectField = <Value extends string | number>({
  description,
  helpText,
  id,
  invalidMessage,
  label,
  labelVisible,
  reserveMessageSpace,
  ...inputProps
}: SelectFieldProps<Value>) => {
  return (
    <Field
      description={description}
      helpText={helpText}
      id={id}
      invalidMessage={invalidMessage}
      label={label}
      labelVisible={labelVisible}
      reserveMessageSpace={reserveMessageSpace}
    >
      <SelectInput {...inputProps} />
    </Field>
  );
};
