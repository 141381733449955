/** @jsx jsx */
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import stringify from 'csv-stringify';

import { jsx } from '@reckon-web/core';
import { Box } from '@reckon-web/box';
import { Button } from '@reckon-web/button';
import { useToasts } from '@reckon-web/toast';

import { DataSheetGrid } from '../../design-system/DatasheetGrid';
import { Cell } from '../../design-system/DatasheetGrid/typings';
import { objectMap } from '../../../utilities/helpers/objects';
import { CSV } from '../types';

import { textColumn } from './TextColumn';
import { mappingValidations } from './mappingValidations';
import { mappingDataContext } from './MapCsvDataDialog';

const increaseWidth = [
  'FamilyGroup',
  'Debtor',
  'Sortname',
  'RegName',
  'TradingAs',
];

export const MappingSpreadheet = ({
  csv,
  setCsv,
  handleFinalisingCsv,
  setHandleFinalisingCsv,
}: {
  csv: CSV;
  setCsv: Dispatch<SetStateAction<CSV | undefined>>;
  handleFinalisingCsv: string | undefined;
  setHandleFinalisingCsv: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const { addToast } = useToasts();
  const { entityTypes, staffMembers } = useContext(mappingDataContext);
  const { titleRow, records } = csv;
  const [scrollToValue, setScrollToValue] = useState<Cell | undefined>();

  const checkRowForInvalid = (item: {}) => {
    return objectMap(item, (value, key) =>
      mappingValidations({
        value,
        colKey: key,
        rowData: item,
        entityTypes,
        staffMembers,
      })
    );
  };

  const checkInvalidColumn = (checkRow: any) => {
    return Object.values(checkRow).findIndex((i) => typeof i === 'string');
  };

  const [data, setData] = useState(
    records
      .map((record) => {
        let obj = {};
        titleRow.map(
          (title) =>
            (obj = { ...obj, [title]: record[titleRow.indexOf(title)] })
        );
        return obj;
      })
      .sort((a, b) => {
        const checkRowA = checkRowForInvalid(a);
        const isInvalidColA = checkInvalidColumn(checkRowA) !== -1 ? 1 : 0;
        const checkRowB = checkRowForInvalid(b);
        const isInvalidColB = checkInvalidColumn(checkRowB) !== -1 ? 1 : 0;

        return isInvalidColB - isInvalidColA;
      })
  );

  useEffect(() => {
    if (
      handleFinalisingCsv === 'downloadStart' ||
      handleFinalisingCsv === 'finaliseMapping'
    ) {
      const newRecords: string[][] = data.map((row) => Object.values(row));

      if (handleFinalisingCsv === 'downloadStart') {
        stringify(
          //   @ts-ignore
          newRecords,
          {
            header: true,
            columns: titleRow,
          },
          (err, data) => {
            if (err) throw err;

            let hiddenElement = document.createElement('a');
            hiddenElement.href =
              'data:text/csv;charset=utf-8,' + encodeURI(data);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'APS-contacts-mapped-data.csv';
            hiddenElement.click();
          }
        );
      }

      if (handleFinalisingCsv === 'finaliseMapping') {
        setCsv({ ...csv, records: newRecords });
      }

      setHandleFinalisingCsv(undefined);
    }
  }, [
    csv,
    data,
    handleFinalisingCsv,
    setCsv,
    setHandleFinalisingCsv,
    titleRow,
  ]);

  const handleValidationSearch = () => {
    const searchInvalid = data.find((i, rowIndex) => {
      const checkRow = checkRowForInvalid(i);
      const isInvalidCol = checkInvalidColumn(checkRow);

      if (isInvalidCol !== -1) {
        addToast({
          title: `Validation error: Row ${rowIndex + 1}`,
          // @ts-ignore
          message: Object.values(checkRow)[isInvalidCol] || '',
          tone: 'critical',
        });
        setScrollToValue({ row: rowIndex + 1, col: isInvalidCol });
      }

      return isInvalidCol !== -1;
    });

    if (!searchInvalid)
      return addToast({
        title: `No issues detected`,
        tone: 'positive',
      });
  };

  const columns = titleRow.map((i) => {
    return textColumn({
      key: i,
      title: i,
      width: increaseWidth.includes(i) ? 3 : 1,
    });
  });

  return (
    <Fragment>
      <Button
        label="Find validation issue"
        onClick={() => {
          handleValidationSearch();
        }}
      />

      <Box overflow="auto">
        <DataSheetGrid
          height={800}
          data={data}
          onChange={setData}
          columns={columns}
          scrollToValue={scrollToValue}
        />
      </Box>
    </Fragment>
  );
};
