import React, { forwardRef } from 'react';
import dedent from 'dedent';

import { Field, FieldProps } from '@reckon-web/field';
import { DateInput, DateInputProps } from '@reckon-web/date-input';

type DateFieldProps = DateInputProps & FieldProps;

/** @deprecated `DateField` has been deprecated. Use [Field](https://balance.reckon.com/package/field) and [DateInput](https://balance.reckon.com/package/date-input) instead. */
export const DateField = forwardRef<HTMLInputElement, DateFieldProps>(
  (
    {
      description,
      helpText,
      id,
      invalidMessage,
      label,
      labelVisible,
      reserveMessageSpace,
      ...inputProps
    },
    ref
  ) => {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        dedent`
      The "DateField" component has been deprecated and will be removed in a future version. Use "Field" and "DateInput" instead.
      %c
      - <DateField
      -   label="Field label"
      -   onChange={handleInputChange}
      -   value={inputValue}
      - />
      %c
      + <Field label="Field label">
      +   <DateInput
      +     onChange={handleInputChange}
      +     value={inputValue}
      +   />
      + </Field>
    `,
        'color: red',
        'color: green'
      );
    }

    return (
      <Field
        description={description}
        helpText={helpText}
        id={id}
        invalidMessage={invalidMessage}
        label={label}
        labelVisible={labelVisible}
        reserveMessageSpace={reserveMessageSpace}
      >
        <DateInput ref={ref} {...inputProps} />
      </Field>
    );
  }
);
