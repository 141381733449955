// const validateText = (value: any) => {
//   if (value === undefined) return false;
//   return /^[^0-9]+$|^$|null/.test(value) || 'Item cannot include numbers';
// };

// const validateNumber = (value: any) => {
//   if (value === undefined) return false;
//   return (
//     /^[0-9]{1,45}$|^$|null/.test(value) || 'Value entered must be a number'
//   );
// };

export const validateEmail = (value: string) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value);
};

export const validatePhone = (value: string) => {
  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(value);
};

export const validateABN = (value: string) => {
  if (value.length !== 11) return false;
  let weighting = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  let firstDigitProcessed = parseInt(value.charAt(0)) - 1;
  let weighted = firstDigitProcessed * weighting[0];
  for (let i = 1; i < value.length; i++) {
    weighted += parseInt(value.charAt(i)) * weighting[i];
  }
  return weighted % 89 === 0;
};

export const validateTFN = (value: string) => {
  if (!/^[0-9]{1,45}$|^$|null/.test(value)) return false;
  const count = value.length;
  if (count !== 9 && count !== 8) return false;
  const i = value
    .toString()
    .split('')
    .map((n: string) => parseInt(n));
  //standard 9 digit tfn
  let weightFactor;
  if (count === 9) {
    weightFactor =
      i[0] * 1 +
      i[1] * 4 +
      i[2] * 3 +
      i[3] * 7 +
      i[4] * 5 +
      i[5] * 8 +
      i[6] * 6 +
      i[7] * 9 +
      i[8] * 10;
  }
  //check if it is an old 8 digit tfn
  else {
    weightFactor =
      i[0] * 10 +
      i[1] * 7 +
      i[2] * 8 +
      i[3] * 4 +
      i[4] * 6 +
      i[5] * 3 +
      i[6] * 5 +
      i[7] * 1;
  }
  return weightFactor % 11 === 0;
};

export const validateNZBN = (nzbnNumber: string) => {
  let total: number = 0;

  nzbnNumber = nzbnNumber.replace(/[- _.+]/g, '');
  if (nzbnNumber.length !== 13) {
    return false;
  }
  for (let i = 1; i <= 12; i++) {
    if (i % 2 === 0) {
      total += +nzbnNumber.substring(i - 1, i) * 3;
    } else {
      total += +nzbnNumber.substring(i - 1, i) * 1;
    }
  }

  let roundValue: number = 0;

  if (total % 10 !== 0) {
    roundValue = 10 - (total % 10) + total;
  } else {
    roundValue = total;
  }
  return roundValue - total === +nzbnNumber.substring(12, 13);
};

const modIRD = (irdNumber: string, factor: string, modValue: number) => {
  let total: number = 0;
  let index: number = 0;

  for (let pos = 0; pos < irdNumber.length; pos++) {
    total +=
      +irdNumber.substring(pos, pos + 1) * +factor.substring(index, index + 1);
    index++;
  }
  return total % modValue;
};

export const validateIRD = (irdNumber: string) => {
  let modValue: number = 11;
  irdNumber = irdNumber.replace(/-/g, '');
  while (irdNumber.length < 9) {
    irdNumber = '0' + irdNumber;
  }
  let factor1: string = '32765432';
  let factor2: string = '74325276';

  let result: number = modIRD(irdNumber, factor1, modValue);
  if (result > 0) {
    result = 11 - result;
  }

  if (result === 10) {
    result = modIRD(irdNumber, factor2, modValue);
    if (result > 0) {
      result = 11 - result;
    }
  }

  return result === +irdNumber.substring(irdNumber.length - 1);
};

export const validateCharacterCount = (
  value: string | number,
  characterLimit: number
) => {
  if (value && value.toString().length > characterLimit) return false;
  return true;
};
