/** @jsx jsx */
import { Fragment, useReducer } from 'react';

import { jsx } from '@reckon-web/core';
import { Button } from '@reckon-web/button';
import { SideDrawerController } from '@reckon-web/side-drawer';

import { READ, useAccessLevel } from '../../../access';
import { Client } from '../../Client';

import { EditDebtorGroupDrawer } from './EditDebtorGroupDrawer';
import { EditFamilyGroupDrawer } from './EditFamilyGroupDrawer';
type EditDebtorOrFamilyGroupProps = {
  client: Client;
  type: 'debtor' | 'familygroup';
};
// component
export const EditDebtorOrFamilyGroup = ({
  client,
  type,
}: EditDebtorOrFamilyGroupProps) => {
  const accessLevel = useAccessLevel();
  const [isOpen, toggleIsOpen] = useReducer((bool) => !bool, false);
  return (
    <div>
      {accessLevel !== READ && (
        <Fragment>
          <Button
            size="small"
            weight="none"
            onClick={toggleIsOpen}
            label="Edit"
          />
          ​
          <SideDrawerController isOpen={isOpen} onClose={toggleIsOpen}>
            {type === 'debtor' ? (
              <EditDebtorGroupDrawer
                toggleIsOpen={toggleIsOpen}
                client={client}
              />
            ) : (
              <EditFamilyGroupDrawer
                toggleIsOpen={toggleIsOpen}
                client={client}
              />
            )}
          </SideDrawerController>
        </Fragment>
      )}
    </div>
  );
};
