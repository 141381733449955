// ts-gql-integrity:ded4968ea723da3f2078022ef98eb5c3
/*
ts-gql-meta-begin
{
  "hash": "163ab82d619f892b252ec0db46c93566"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type entityTypesQueryVariables = {};


type entityTypesQuery = (
  { readonly __typename: 'Query' }
  & { readonly entityTypes: ReadonlyArray<(
    { readonly __typename: 'EntityType' }
    & Pick<SchemaTypes.EntityType, 'id' | 'name' | 'type' | 'selectable'>
  )> }
);


export type type = TypedDocumentNode<{
  type: "query";
  result: entityTypesQuery;
  variables: entityTypesQueryVariables;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    entityTypes: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"entityTypes\"},\"variableDefinitions\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"entityTypes\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"selectable\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
