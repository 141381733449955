/** @jsx jsx */
import { useRouter } from 'next/router';

import { jsx } from '@reckon-web/core';
import { TabItem, TabList } from '@reckon-web/tabs';
import { Link } from '@reckon-web/link';
import { Box } from '@reckon-web/box';

import { ADMIN, useAccessLevel } from '../access';

export const NavRight = () => {
  const router = useRouter();
  const sessionAccessLevel = useAccessLevel();

  const tabs = [
    {
      access: ADMIN,
      href: '/users',
      id: 'users-tab-item',
      isActive: (pathname: string) => pathname === '/users',
      label: 'Users',
    },
  ];

  return (
    <Box paddingX="small">
      <TabList css={{ height: '100%' }}>
        {tabs
          .filter(({ access }) => sessionAccessLevel <= access)
          .map(({ href, id, isActive, label }) => {
            return (
              <TabItem
                as={Link}
                href={href}
                id={id}
                isSelected={isActive(router.pathname)}
                key={href}
              >
                {label}
              </TabItem>
            );
          })}
      </TabList>
    </Box>
  );
};
