import React from 'react';

import { Tooltip, TooltipProps } from '@reckon-web/tooltip';

type ConditionalTooltipProps = { when: boolean } & TooltipProps;

export const ConditionalTooltip = ({
  children,
  when,
  ...tooltipProps
}: ConditionalTooltipProps) => {
  if (when) {
    return <Tooltip {...tooltipProps}>{children}</Tooltip>;
  }
  return children;
};
