/** @jsx jsx */
import { Fragment, useReducer } from 'react';

import { jsx } from '@reckon-web/core';
import { Button } from '@reckon-web/button';
import { SideDrawerController } from '@reckon-web/side-drawer';
import { Box } from '@reckon-web/box';

import { READ, useAccessLevel } from '../../../access';

import { EditManagementGroupDrawer } from './EditManagementGroupDrawer';

export const EditManagement = () => {
  const accessLevel = useAccessLevel();
  const [isOpen, toggleIsOpen] = useReducer((bool) => !bool, false);

  return (
    <Box>
      {accessLevel !== READ && (
        <Fragment>
          <Button
            size="small"
            weight="none"
            onClick={toggleIsOpen}
            label="Edit"
          />
          <SideDrawerController isOpen={isOpen} onClose={toggleIsOpen}>
            <EditManagementGroupDrawer toggleIsOpen={toggleIsOpen} />
          </SideDrawerController>
        </Fragment>
      )}
    </Box>
  );
};
