/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { Flex } from '@reckon-web/flex';
import { Text } from '@reckon-web/text';
import { Stack } from '@reckon-web/stack';
import { UserAvatar } from '@reckon-web/avatar';

import { Client } from '../../Client';

type ManagementAvatarsProps = {
  client: Client;
};

export const ManagementAvatars = ({ client }: ManagementAvatarsProps) => {
  return (
    <Stack gap="xlarge">
      {client?.attributes?.partner?.name && (
        <Flex>
          <UserAvatar size="medium" name={client.attributes.partner.name} />
          <Stack gap="xsmall" marginX="small">
            <Text size="medium">{client.attributes.partner.name}</Text>
            <Text color="dim">Partner</Text>
          </Stack>
        </Flex>
      )}

      {client?.attributes?.manager?.name && (
        <Flex>
          <UserAvatar size="medium" name={client.attributes.manager.name} />
          <Stack gap="xsmall" marginX="small">
            <Text size="medium">{client.attributes.manager.name}</Text>
            <Text color="dim">Manager</Text>
          </Stack>
        </Flex>
      )}

      {client?.attributes?.user?.name && (
        <Flex>
          <UserAvatar size="medium" name={client.attributes.user.name} />
          <Stack gap="xsmall" marginX="small">
            <Text size="medium">{client.attributes.user.name}</Text>
            <Text color="dim">Staff</Text>
          </Stack>
        </Flex>
      )}
    </Stack>
  );
};
