/** @jsx jsx */
import { jsx } from '@reckon-web/core';

export const FullPageVerticalDivider = () => (
  <div
    title="full-page-vertical-divider"
    css={{
      height: '100vh',
      width: 1,
      flexShrink: 0,
      backgroundColor: '#E8E8E8',
      marginLeft: '50%',
    }}
  />
);
