/** @jsx jsx */
import { Fragment, useContext, useEffect, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@ts-gql/apollo';
import PQueue from 'p-queue';
import { MutationFunction } from '@apollo/client';

import { jsx } from '@reckon-web/core';
import { Text } from '@reckon-web/text';
import { Flex } from '@reckon-web/flex';
import { Stack } from '@reckon-web/stack';
import { Inline } from '@reckon-web/inline';
import { LoadingDots } from '@reckon-web/loading';

import { ENTITY_TYPE } from '../client/createEditClientDrawer/CreateEditClientDrawerContainer';
import { STAFF_QUERY } from '../client/clientRightColumn/management/EditManagementGroupDrawer';
import {
  CREATE_CLIENT,
  UPDATE_CLIENT,
} from '../client/createEditClientDrawer/CreateEditClientDrawer';
import { useError } from '../../utilities/hooks/useError';

import { importClientRecord } from './functions/importClientRecord';
import { checkOutstandingFamilyOrDebtor } from './functions/updateDifferentFamilyDebtor';
import { setDoneMessaging } from './functions/setDoneMessaging';
import { ImportWarnings } from './ImportWarnings';
import { ImportResults, NonMatchingClient, SortedRecord } from './types';
import {
  sortIncludesDebtor,
  sortIncludesFam,
  sortIncludesFamAndDebtor,
} from './functions/sortRecordPositions';
import { ImportClientContext } from './ImportClientDialogContainer';

export const BeginImportClient = () => {
  const {
    file,
    csv,
    setBeginImport,
    setImportError,
    importDone,
    setImportDone,
    importWarnings,
    setImportWarnings,
  } = useContext(ImportClientContext);

  const apolloClient = useApolloClient();

  const { data: fetchedEntityTypes, loading: loadingEntityTypes } = useQuery(
    ENTITY_TYPE
  );
  const entityTypes = fetchedEntityTypes?.entityTypes && [
    ...fetchedEntityTypes?.entityTypes,
  ];

  const { data: fetchedStaff, loading: loadingStaff } = useQuery(STAFF_QUERY);
  let staffMembers = fetchedStaff?.users && [...fetchedStaff?.users];

  const [createClient, { loading: createLoading }] = useMutation(CREATE_CLIENT);
  const [updateClient, { loading: updateLoading }] = useMutation(UPDATE_CLIENT);

  const [importCount, setImportCount] = useState(0);

  const [postImportCount, setPostImportCount] = useState({
    successCount: 0,
    failCount: 0,
  });

  const [updatingGroupsStatus, setUpdatingGroupsStatus] = useState(false);

  const handleError = useError();

  useEffect(() => {
    if (
      !loadingEntityTypes &&
      !loadingStaff &&
      !createLoading &&
      !updateLoading &&
      !importDone &&
      fetchedEntityTypes
    ) {
      let nonMatchingFamily: NonMatchingClient[] = [];
      let nonMatchingDebtor: NonMatchingClient[] = [];

      const queue = new PQueue({ concurrency: 2 });

      function generatePromise(item: SortedRecord) {
        return () =>
          new Promise((resolve, reject) => {
            resolve(
              importClientRecord({
                item: item.rowData,
                clientRow: item.rowIndex,
                entityTypes,
                titleRow,
                apolloClient,
                staffMembers,
                nonMatchingDebtor,
                nonMatchingFamily,
                setImportCount,
                createClient: createClient as MutationFunction,
                updateClient: updateClient as MutationFunction,
                setImportWarnings,
              })
            );
          });
      }

      const myPromises = sortedRecords.map(generatePromise);

      queue
        .addAll(myPromises)
        .then(async (data) => {
          return checkOutstandingFamilyOrDebtor({
            nonMatchingFamily,
            nonMatchingDebtor,
            apolloClient,
            data: data as ImportResults[],
            setUpdatingGroupsStatus,
          });
        })
        .then(({ data, nonMatchingDebtorFamilyResults }) => {
          setDoneMessaging({
            data,
            setImportError,
            setBeginImport,
            setImportWarnings,
            nonMatchingDebtorFamilyResults,
            setPostImportCount,
            setImportDone,
          });
        })
        .catch((error) => {
          handleError({ title: 'Import Error', error });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingEntityTypes, loadingStaff]);

  if (!csv) return null;

  const { records, titleRow } = csv;
  let containsFamilyGroup = titleRow.includes('FamilyGroup');
  let containsDebtor = titleRow.includes('Debtor');

  // gives the record a row index
  let sortedRecords: SortedRecord[] = records.map((rowData, idx) => {
    return { rowData, rowIndex: idx + 2 };
  });

  // sort the rows to have records with same family and debtor group higher in the list
  // while also maintaining the original row index
  if (containsFamilyGroup && containsDebtor) {
    sortedRecords = sortIncludesFamAndDebtor(sortedRecords, titleRow);
  } else if (containsFamilyGroup) {
    sortedRecords = sortIncludesFam(sortedRecords, titleRow);
  } else if (containsDebtor) {
    sortedRecords = sortIncludesDebtor(sortedRecords, titleRow);
  }

  if (importDone) {
    return (
      <Stack gap="small">
        {importWarnings.length ? (
          <ImportWarnings importWarnings={importWarnings} />
        ) : null}

        <Inline gap="xsmall" alignY="center">
          <Text color="informative">
            {postImportCount.successCount} clients
          </Text>
          <Text color="dim">have been updated or created.</Text>
        </Inline>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Text color="muted">
        Please do not close tab or window until import is complete.
      </Text>

      <Flex padding="xlarge" justifyContent="space-between" alignItems="center">
        <Flex gap="large" alignItems="center">
          <LoadingDots label="..." />
          <Stack gap="medium">
            <Text>{file.meta?.name}</Text>
            {!updatingGroupsStatus ? (
              <Text size="xsmall" color="dim">
                {csv?.totalClients + ' clients'} ({importCount} of{' '}
                {csv?.totalClients})
              </Text>
            ) : (
              <Text size="xsmall" color="dim">
                Updating non matching debtor and family groups, this may take
                some time
              </Text>
            )}
          </Stack>
        </Flex>
      </Flex>
    </Fragment>
  );
};
