import React from 'react';
import { FragmentData, gql } from '@ts-gql/tag';

let addressfragment = gql`
  fragment AddressText_address on Address {
    id
    line1
    line2
    line3
    city
    country
    postcode
    state
  }
` as import('../../../__generated__/ts-gql/AddressText_address').type;

type Address = FragmentData<typeof addressfragment>;

export function AddressText({ address }: { address: Address | null }) {
  if (!address) return <span>—</span>;
  if (!checkAddressExist(address)) return <span>—</span>;

  return (
    <span>
      {[address.line1, address.line2, address.line3].filter((x) => x).join(' ')}
      ,{' '}
      {[address.city, address.state, address.postcode]
        .filter((x) => x)
        .join(' ')}
      , {address.country}
    </span>
  );
}

const checkAddressExist = (address: Address) => {
  return Object.keys(address)
    .filter((key) => key !== 'id' && key !== '__typename')
    .reduce((acc, curr) => {
      if (!!address[curr as keyof typeof address]) return (acc = true);
      return acc;
    }, false);
};
