import React, { Fragment, useMemo } from 'react';
import { DropzoneRootProps } from 'react-dropzone';

import { Box } from '@reckon-web/box';
import { FilePlusIcon } from '@reckon-web/icon';
import { Text } from '@reckon-web/text';
import { Stack } from '@reckon-web/stack';
import { LoadingDots } from '@reckon-web/loading';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '56px',
  borderWidth: 2,
  border: '1px solid',
  borderColor: '#E4E6EA',
  borderRadius: 4,
  backgroundColor: '#F9F9F9',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  border: '1px solid #E4E6EA',
  backgroundColor: '#E2F3FF',
};

const acceptStyle = {
  borderColor: '#E4E6EA',
};

const rejectStyle = {};

export function Dropzone({
  getRootProps,
  getInputProps,

  fileRejections,
  isDragActive,
  isDragAccept,
  isDragReject,
  verifyingCsv,
}: DropzoneRootProps) {
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Box>
      <div
        // @ts-ignore
        {...getRootProps({ style })}
      >
        {!verifyingCsv ? (
          <Fragment>
            <input {...getInputProps()} />
            <Stack gap="xlarge" align="center">
              <FilePlusIcon
                size="large"
                color={isDragAccept ? 'informative' : 'dim'}
              />
              <Stack align="center">
                <Text color={isDragAccept ? 'informative' : 'dim'}>
                  Drag and drop file here or
                </Text>
                <Text weight="medium" cursor="pointer">
                  select a file...
                </Text>
              </Stack>
            </Stack>
          </Fragment>
        ) : (
          <Stack gap="xlarge" align="center">
            <LoadingDots label="loading" />
            <Text color={'dim'}>Uploading and checking file...</Text>
          </Stack>
        )}
      </div>
    </Box>
  );
}
