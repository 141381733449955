/** @jsx jsx */

import { ButtonHTMLAttributes, HTMLAttributes, forwardRef } from 'react';

import { jsx } from '@reckon-web/core';
import { XIcon } from '@reckon-web/icon/icons/XIcon';
import { CalendarIcon } from '@reckon-web/icon/icons/CalendarIcon';
import {
  Adornment,
  AdornmentWrapper,
  InputSizeType,
  InputWeightType,
  useInputStyles,
} from '@reckon-web/text-input';
import { usePack, useTheme } from '@reckon-web/theme';

type ButtonProps = {
  invalid?: boolean;
  isSelected?: boolean;
  onClear?: () => void;
  size: InputSizeType;
  weight: InputWeightType;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const InputButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ invalid = false, isSelected, onClear, size, weight, ...props }, ref) => {
    const theme = useTheme();
    const inputStyles = useInputStyles({
      shape: 'square',
      size: size,
      weight: weight,
    });
    const focusStyles = isSelected
      ? {
          ...inputStyles[':focus'],
          ':hover': inputStyles[':focus'],
          ':focus': inputStyles[':focus'],
        }
      : null;
    const buttonStyles = {
      ...inputStyles,
      ...focusStyles,
      cursor: 'pointer',
      lineHeight: 'initial', // let the button vertically align its text; the have different native behaviour to inputs
      textAlign: 'left',
    } as const;

    const adornmentPaddingRight =
      size === 'medium' ? theme.spacing.small : theme.spacing.xsmall;

    const iconSizeKey = getIconSize(size);

    return (
      <AdornmentWrapper
        shape="square"
        weight={weight}
        size={size}
        css={{ flex: 1 }}
      >
        <button
          ref={ref}
          css={buttonStyles}
          type="button"
          data-invalid={invalid}
          {...props}
        />
        {onClear && !props.disabled && (
          <ClearButton size={size} onClick={onClear} />
        )}
        <Adornment
          align="right"
          css={{ paddingRight: adornmentPaddingRight, pointerEvents: 'none' }}
        >
          <CalendarIcon size={iconSizeKey} color="dim" />
        </Adornment>
      </AdornmentWrapper>
    );
  }
);

InputButton.displayName = 'InputButton';

type ClearButtonProps = {
  size: InputSizeType;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ClearButton = ({ size, ...props }: ClearButtonProps) => {
  const { palette } = useTheme();
  const inputSizePack = usePack('sizes')[size];
  const iconSizeKey = getIconSize(size);

  return (
    <Adornment
      as="button"
      type="button"
      align="right"
      tabIndex={-1}
      css={{
        alignItems: 'center',
        background: 0,
        border: 0,
        borderRadius: '50%',
        color: palette.text.dim,
        display: 'flex',
        justifyContent: 'center',
        outline: 0,
        padding: 0,
        right: inputSizePack.boxSize,

        // No focus styles because this button is not focusable
        ':hover': {
          color: palette.text.muted,
        },
      }}
      {...props}
    >
      <XIcon size={iconSizeKey} />
    </Adornment>
  );
};

export const Placeholder = (props: HTMLAttributes<HTMLSpanElement>) => {
  const { palette } = useTheme();
  return (
    <span css={{ color: palette.text.dim, pointerEvents: 'none' }} {...props} />
  );
};

// Utils
// ------------------------------

/** Use the "small" icon size for both input sizes "xsmall | small" */
function getIconSize(inputSizeKey: InputSizeType) {
  return inputSizeKey === 'xsmall' || inputSizeKey === 'small'
    ? 'small'
    : 'medium';
}
