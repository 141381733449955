/** @jsx jsx */

import {
  CSSProperties,
  ReactElement,
  ReactNode,
  forwardRef,
  useMemo,
  useReducer,
} from 'react';

import { buildDataAttributes, jsx } from '@reckon-web/core';
import { DefaultTextPropsProvider } from '@reckon-web/text';
import { useId } from '@reckon-web/utils';

type TriggerProps = {
  'aria-controls'?: string;
  'aria-expanded': boolean;
  onClick: () => void;
};

export type TableGroupProps = UseGroupStylesProps & {
  children: ReactNode;
  initialExpanded?: boolean;
  header: (props: {
    expanded: boolean;
    triggerProps: TriggerProps;
  }) => ReactElement;
};

export const TableGroup = forwardRef<HTMLDivElement, TableGroupProps>(
  ({ children, header, initialExpanded = true, ...props }, ref) => {
    const { css, dataAttributes, style } = useGroupStyles(props);
    const { expanded, regionId, toggleExpanded } = useGroupState(
      initialExpanded
    );

    const headerRenderProps = useMemo(() => {
      const triggerProps = {
        'aria-controls': regionId,
        'aria-expanded': expanded,
        onClick: toggleExpanded,
      };
      return { expanded, triggerProps };
    }, [expanded, regionId, toggleExpanded]);

    return (
      <DefaultTextPropsProvider size="small">
        <div
          ref={ref}
          role="rowgroup"
          css={css}
          style={style}
          {...dataAttributes}
        >
          {header(headerRenderProps)}
          <div role="region" id={regionId}>
            {expanded && children}
          </div>
        </div>
      </DefaultTextPropsProvider>
    );
  }
);

function useGroupState(initialExpanded: boolean) {
  const [expanded, toggleExpanded] = useReducer(
    (bool) => !bool,
    initialExpanded
  );
  const regionId = useId();

  return { expanded, regionId, toggleExpanded };
}

// Styles
// ------------------------------

type UseGroupStylesProps = {
  /**
   * Sets inline [style](https://reactjs.org/docs/dom-elements.html#style) for
   * the element. Only use as a **last resort**.
   */
  UNSAFE_style?: CSSProperties;
};

export function useGroupStyles(props: UseGroupStylesProps) {
  const { UNSAFE_style } = props;

  // dynamic properties
  const style = useMemo(() => UNSAFE_style, [UNSAFE_style]);

  // static-ish properties
  const dataAttributes = useMemo(
    () => buildDataAttributes({ type: 'group' }, 'rowgroup'),
    []
  );

  // declarations
  const css = useMemo(
    () => [
      {
        // display: 'flex',
        // flexDirection: 'column',
        // flex: '1 1 0%',
      } as const,
    ],
    []
  );

  return { dataAttributes, css, style };
}
