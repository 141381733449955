/** @jsx jsx */
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { gql } from '@ts-gql/tag';
import { useApolloClient } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { Stack } from '@reckon-web/stack';
import { Box } from '@reckon-web/box';
import { SelectField } from '@reckon-web/select-field';
import { Divider } from '@reckon-web/divider';
import { DateField } from '@reckon-web/date-field';
import { Columns } from '@reckon-web/columns';
import { Form } from '@reckon-web/forms';
import { Text } from '@reckon-web/text';
import { Checkbox } from '@reckon-web/checkbox';

import { monthsArray } from '../../../../utilities/helpers/dates';
import { useUser } from '../../../auth';
import { generalDetailsSchema } from '../utils';
import {
  generateIndividualMailName,
  generateIndividualSortName,
  generateOrgSortName,
} from '../utils/generateformfields';
import { TextField } from '../../../design-system/text-field';

export let GROUPS_LIST_QUERY = gql`
  query GroupsList($limit: Int!, $skip: Int!) {
    groups(limit: $limit, skip: $skip) {
      id
      name
      isDefault
      userBelongsToGroup
    }
  }
` as import('../../../../../__generated__/ts-gql/GroupsList').type;

type IndividualNames = {
  firstName: string;
  middleName: string;
  lastName: string;
  title?: string;
};

type OrganisationName = {
  registeredName: string;
};

type AutoMailName = {
  autoMailName: boolean;
  setAutoMailName: Dispatch<SetStateAction<boolean>>;
};

type AutoSortName = {
  autoSortName: boolean;
  setAutoSortName: Dispatch<SetStateAction<boolean>>;
};

type EntityType = 'Person' | 'Organisation';

type SecurityGroup = {
  id: string;
  name: string;
  isDefault: boolean;
  userBelongsToGroup: boolean;
};

// component
export const GeneralDetailsForm = ({
  isEdit = false,
  form,
  autoMailName,
  autoSortName,
  names,
  entityType,
}: {
  isEdit?: boolean;
  form: Form<typeof generalDetailsSchema>;
  autoMailName?: AutoMailName;
  autoSortName?: AutoSortName;
  names: IndividualNames | OrganisationName;
  entityType: EntityType;
}) => {
  const apolloClient = useApolloClient();
  const [additionalAttributes, setAdditionalAtrributes] = useState(false);
  const [defaultGroup, setDefaultGroup] = useState<SecurityGroup | undefined>();

  const [groupsList, setGroupsList] = useState<
    | {
        value: string;
        label: string;
      }[]
    | undefined
  >();

  useEffect(() => {
    if (!isEdit) {
      (async () => {
        const { data } = await apolloClient.query({
          query: GROUPS_LIST_QUERY,
          variables: { limit: 100, skip: 0 },
        });

        const defaultGroup = data?.groups.find((group) => group.isDefault);

        const availableGroupsList = data?.groups
          .filter(
            (group) =>
              group.id &&
              group.userBelongsToGroup &&
              group.name !== 'Administrator'
          )
          .sort((a, b) => Number(b.isDefault) - Number(a.isDefault))
          .map((group) => ({
            value: group.id,
            label: group.name,
          }));

        setDefaultGroup(defaultGroup);
        setGroupsList(availableGroupsList || []);
      })();
    }
  }, [apolloClient, isEdit]);

  const { country = 'AUS' } = useUser().profile;
  const { taxDetails } = form.fields;
  const { attributes } = form.fields;

  const securityGroupVal =
    form.fields.group.value !== undefined
      ? form.fields.group.value
      : defaultGroup?.id;

  return (
    <Fragment>
      <Stack gap="large">
        <Stack gap="xsmall">
          <TextField
            {...form.fields.sortName.props}
            autoComplete="off"
            id="Sort-name"
            label="Sort name*"
            placeholder="Enter sort name"
            helpText="Sort name will be the unique identifier in our system."
          />
          <Checkbox
            checked={autoSortName?.autoSortName}
            onChange={() => {
              if (!autoSortName?.autoSortName) {
                if ('firstName' in names) {
                  const newSortName = generateIndividualSortName(
                    names.firstName,
                    names.middleName,
                    names.lastName
                  );
                  form.fields.sortName.setState({
                    value: newSortName,
                    touched: true,
                  });
                }
                if ('registeredName' in names) {
                  const newSortName = generateOrgSortName(names.registeredName);
                  form.fields.sortName.setState({
                    value: newSortName,
                    touched: true,
                  });
                }
              }
              autoSortName?.setAutoSortName(!autoSortName.autoSortName);
            }}
          >
            <Text color="dim" size="small">
              {'registeredName' in names
                ? 'Generate sort name from registered name'
                : 'Generate sort name from first, middle and last name'}
            </Text>
          </Checkbox>
        </Stack>

        <TextField
          {...form.fields.salutation.props}
          id="Salutation"
          label="Salutation"
          placeholder="Enter salutation"
        />
        <Stack gap="xsmall">
          <TextField
            {...form.fields.mailName.props}
            autoComplete="off"
            id="Mailing-name"
            label="Mailing name"
            placeholder="Enter mailing name"
          />
          <Checkbox
            checked={autoMailName?.autoMailName}
            onChange={() => {
              if (!autoMailName?.autoMailName) {
                if ('firstName' in names) {
                  const newMailName = generateIndividualMailName(
                    names.firstName,
                    names.middleName,
                    names.lastName,
                    names.title
                  );
                  form.fields.mailName.setState({
                    value: newMailName,
                    touched: true,
                  });
                }
                if ('registeredName' in names) {
                  form.fields.mailName.setState({
                    value: names.registeredName,
                    touched: true,
                  });
                }
              }
              autoMailName?.setAutoMailName(!autoMailName.autoMailName);
            }}
          >
            <Text color="dim" size="small">
              {'registeredName' in names
                ? 'Generate mailing name from registered name'
                : 'Generate mailing name from first, middle and last name'}
            </Text>
          </Checkbox>
        </Stack>
        <Box marginY="medium">
          <Divider />
        </Box>
        <SelectField
          {...form.fields.balanceMonth.props}
          id="Balance-month"
          label="Balance month"
          placeholder="Select balance month"
          options={monthsArray}
          value={
            form.fields.balanceMonth.props.value ||
            (country === 'AUS' ? '6' : '3')
          }
        />
        <Box marginY="medium">
          <Divider />
        </Box>
      </Stack>

      {!isEdit && (
        <Stack gap="large">
          <SelectField
            {...form.fields.group.props}
            id="Select-client-group"
            label="Select client group"
            value={securityGroupVal}
            options={groupsList || []}
            helpText="Choose a security group for this client. You can only choose a security group that your user belongs to"
          />
          <Box marginY="medium">
            <Divider />
          </Box>
        </Stack>
      )}

      {!isEdit && (
        <Box marginY="medium">
          <Checkbox
            checked={additionalAttributes}
            onChange={() => setAdditionalAtrributes(!additionalAttributes)}
          >
            View Additional Attributes
          </Checkbox>
        </Box>
      )}

      {(isEdit || additionalAttributes) && (
        <Stack gap="large">
          {country === 'AUS' ? (
            <Stack gap="large">
              <Columns gap="medium">
                <TextField
                  {...taxDetails.fields.abnNumber.props}
                  id="ABN"
                  inputMode="numeric"
                  label="ABN"
                  placeholder="Enter ABN"
                />
                <TextField
                  {...taxDetails.fields.abnDivisionNumber.props}
                  id="ABN-division-number"
                  inputMode="numeric"
                  label="ABN division number"
                  placeholder="Enter division number"
                />
              </Columns>

              <TextField
                {...taxDetails.fields.tfnNumber.props}
                id="TFN"
                inputMode="numeric"
                label="TFN"
                placeholder="Enter TFN number"
              />
              <TextField
                {...taxDetails.fields.acnArbnNumber.props}
                id="ACN/ARBN-number"
                inputMode="numeric"
                label="ACN/ARBN number"
                placeholder="Enter ACN/ARBN number"
              />
            </Stack>
          ) : (
            <Stack gap="large">
              <TextField
                {...taxDetails.fields.irdNumber.props}
                id="IRD-number"
                inputMode="numeric"
                label="IRD number"
                placeholder="Enter IRD number"
              />
              <TextField
                {...taxDetails.fields.nzbnNumber.props}
                id="NZBN-number"
                inputMode="numeric"
                label="NZBN number"
                placeholder="Enter NZBN number"
              />
            </Stack>
          )}

          <Box marginY="medium">
            <Divider />
          </Box>

          <DateField
            {...form.fields.startDate.props}
            id="Start-date"
            label="Start date"
          />
          <DateField
            {...form.fields.endDate.props}
            id="End-date"
            label="End date"
          />
          <Box marginY="medium">
            <Divider />
          </Box>

          <DateField
            {...attributes.fields.dateOfBirth.props}
            id={
              entityType === 'Person'
                ? 'Date-of-birth'
                : 'Date-of-incorporation'
            }
            label={
              entityType === 'Person'
                ? 'Date of birth'
                : 'Date of incorporation'
            }
          />
          <TextField
            {...attributes.fields.placeOfBirth.props}
            id={
              entityType === 'Person'
                ? 'Place-of-birth'
                : 'Place-of-incorporation'
            }
            label={
              entityType === 'Person'
                ? 'Place of birth'
                : 'Place of incorporation'
            }
            // placeholder="Enter place of incorporation"
          />
          <DateField
            {...attributes.fields.dateOfDeath.props}
            id={
              entityType === 'Person'
                ? 'Date-of-death'
                : 'Date-of-deregistration'
            }
            label={
              entityType === 'Person'
                ? 'Date of death'
                : 'Date of deregistration'
            }
          />
          <SelectField
            {...attributes.fields.annualReturnMonth.props}
            id="annual-return-month"
            label="Annual return month"
            placeholder="Select annual return month"
            options={monthsArray}
          />
        </Stack>
      )}
    </Fragment>
  );
};
