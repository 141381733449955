/** @jsx jsx */

import { ReactNode } from 'react';

import { CSSObject, jsx } from '@reckon-web/core';
import { Inline } from '@reckon-web/inline';
import { Heading } from '@reckon-web/heading';
import { Text } from '@reckon-web/text';
import { Stack } from '@reckon-web/stack';

export type EmptyProps = {
  title: string;
  illustration?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode[];
  css?: CSSObject;
};

export const Empty = ({
  title,
  illustration,
  description,
  actions,
  css,
}: EmptyProps) => {
  return (
    <Stack
      align="center"
      background="muted"
      gap="xlarge"
      padding="xxlarge"
      css={{
        paddingTop: 52,
        paddingBottom: 52,
        textAlign: 'center',
        justifyContent: 'center',
        ...(css ?? {}),
      }}
    >
      {illustration}
      <Stack gap="medium">
        <Heading level="2">{title}</Heading>
        {description && (
          <Text color="muted" size="small">
            {description}
          </Text>
        )}
      </Stack>
      {actions && actions.length && (
        <Inline gap="small" alignY="center">
          {actions}
        </Inline>
      )}
    </Stack>
  );
};
