/** @jsx jsx */
import { useRouter } from 'next/router';

import { jsx } from '@reckon-web/core';
import { TabItem, TabList } from '@reckon-web/tabs';
import { Link } from '@reckon-web/link';

import { ADMIN, READ, useAccessLevel } from '../access';

export const NavLeft = () => {
  const router = useRouter();
  const sessionAccessLevel = useAccessLevel();

  const tabs = [
    {
      access: READ,
      href: '/',
      id: 'clients-tab-item',
      isActive: (pathname: string) => pathname === '/',
      label: 'Clients',
    },
    {
      access: ADMIN,
      href: '/groups',
      id: 'groups-tab-item',
      isActive: (pathname: string) => pathname.startsWith('/groups'),
      label: 'Groups',
    },
  ];

  return (
    <TabList css={{ height: '100%' }}>
      {tabs
        .filter(({ access }) => sessionAccessLevel <= access)
        .map(({ href, id, isActive, label }) => {
          return (
            <TabItem
              as={Link}
              href={href}
              id={id}
              isSelected={isActive(router.pathname)}
              key={href}
            >
              {label}
            </TabItem>
          );
        })}
    </TabList>
  );
};
