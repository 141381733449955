import axios from 'axios';
// import { AddressResult, AddressSearch } from 'address-models';

const addressURL = process.env.REACT_APP_ADDRESS_LOOKUP_API_URI;
// const CLIENT_API_URL = process.env.REACT_APP_CLIENTAPI_URL;

type AddressSearch = {
  RecordId: string;
  AddressLine: string;
  Locality: string;
  State: string;
  Postcode: string;
  Country: string;
  CountryCode: string;
};

type AddressResult = {
  DPID: string;
  unitType: string;
  unitNumber: string;
  levelType: string;
  levelNumber: string;
  streetNumber1: string;
  streetNumberSuffix1: string;
  postBoxNumber: string;
  streetName: string;
  streetType: string;
  streetSuffix: string;
  postBoxType: string;
  buildingName: string;
  addressLine: string;
  postcode: string;
  countrySpecific: CountrySpecific;
  country: string;
};

type CountrySpecific = {
  suburb: string;
  state: string;
  townCityMailtown: string;
  locality: string;
};

export async function searchAddress(
  partialAddress: string,
  token: string,
  country?: string
): Promise<AddressSearch[]> {
  const url = `${addressURL}/searchAddress`;

  // const token = Cognito.instance().getAccessToken();
  //   const token = VuexStoreManager.cogintoStore.accessToken;

  const response = await axios.get(url, {
    params: {
      partialAddress: partialAddress,
      country: country,
    },
    headers: {
      authorizationtoken: token,
      'Access-Control-Allow-Origin': '*',
    },
  });

  return response.data;
}

export async function getAddress(
  recordId: string,
  token: string,
  country?: string
): Promise<AddressResult> {
  const url = `${addressURL}/getAddress`;
  // const token = Cognito.instance().getAccessToken();
  //   const token = VuexStoreManager.cogintoStore.accessToken;

  const response = await axios.get(url, {
    params: {
      recordId: recordId,
      country: country,
    },
    headers: {
      authorizationtoken: token,
      'Access-Control-Allow-Origin': '*',
    },
  });

  return response.data;
}
