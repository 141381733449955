/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { DropdownMenu, MenuItem } from '@reckon-web/dropdown';
import { ChevronDownIcon, ChevronUpIcon, LogOutIcon } from '@reckon-web/icon';
import { ActionButton } from '@reckon-web/button';
import { Flex } from '@reckon-web/flex';
import { UserAvatar } from '@reckon-web/avatar';
import { Tooltip } from '@reckon-web/tooltip';
import { Box } from '@reckon-web/box';
import { Inline } from '@reckon-web/inline';
import { Text } from '@reckon-web/text';

import { signout, useUser } from '../auth';

const APPLICATION_VERSION = process.env.APPLICATION_VERSION;

export const Profile = () => {
  const user = useUser();

  const userName = user?.profile?.name ?? '';

  return (
    <DropdownMenu
      align="right"
      trigger={({ triggerProps, isOpen }) => (
        <ActionButton {...triggerProps} weight="subtle" id="user-avatar-button">
          <Flex alignItems="center" gap="small">
            <Tooltip content={userName}>
              <Box>
                <UserAvatar name={userName} size="small" />
              </Box>
            </Tooltip>
            {isOpen ? (
              <ChevronUpIcon size="small" />
            ) : (
              <ChevronDownIcon size="small" />
            )}
          </Flex>
        </ActionButton>
      )}
    >
      <MenuItem
        id="logout-text-link"
        icon={LogOutIcon}
        onClick={() => signout()}
        tone="critical"
        label="Logout"
      />
      <Inline paddingX="large" paddingTop="small" alignY="center">
        <Text color="muted" size="xsmall">
          Version: {APPLICATION_VERSION}
        </Text>
      </Inline>
    </DropdownMenu>
  );
};
