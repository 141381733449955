/** @jsx jsx */
import { useEffect, useRef } from 'react';

import { jsx } from '@reckon-web/core';
import { Stack } from '@reckon-web/stack';
import { Text } from '@reckon-web/text';

import { Failure } from '../types';

export const WarningMessage = ({
  index,
  warningItem,
  setSize,
}: {
  index: number;
  warningItem: { failure: Failure; row: number };
  setSize: any;
}) => {
  const root = useRef();
  useEffect(() => {
    //   @ts-ignore
    setSize(index, root?.current?.getBoundingClientRect()?.height || 48);
  }, [index, setSize, warningItem.failure.message]);

  let warningMsg = warningItem.failure.message;
  if (warningMsg === 'Response not successful: Received status code 400')
    warningMsg =
      'Response failed for unknown reason. You likely have an unknown value in one of your columns';

  return (
    <div
      // @ts-ignore
      ref={root}
      className="message"
    >
      <Stack gap="xsmall" paddingY="xxsmall">
        <Text size="xsmall" color="muted" weight="semibold">
          Row {warningItem.row} {warningItem.failure.sortName} issue:
        </Text>
        <Text size="xsmall" color="critical">
          {warningMsg}
        </Text>
      </Stack>
    </div>
  );
};
