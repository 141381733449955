/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { Link } from '@reckon-web/link';
import { Flex } from '@reckon-web/flex';
import { useTheme } from '@reckon-web/theme';
import { makeId } from '@reckon-web/utils';

export const Logo = () => {
  const theme = useTheme();

  return (
    <Link href="/" id="Header-NextLink-logo">
      <Flex alignItems="center" gap="small">
        <img
          height={theme.sizing.small}
          src="/access-aps-contacts-logo.svg"
          alt="Access APS logo"
          id={makeId('logo', 'img')}
        />
      </Flex>
    </Link>
  );
};
