import React, { ReactNode, useMemo } from 'react';

import { Stack } from '@reckon-web/stack';
import { DefaultTextPropsProvider } from '@reckon-web/text';

import { AlignmentType, DataGroupContext, TemplateType } from './context';

export type DataGroupProps = {
  /** Does not accept css */
  css?: never;
  /** How the value should be horizontally aligned. */
  align?: AlignmentType;
  /** Each row in the group. */
  children: ReactNode;
  /** Define the relative width of each column. The default the ratio of label to value is 1:2. */
  template?: TemplateType;
};

export const DataGroup = ({
  align = 'left',
  children,
  template = [1, 2],
}: DataGroupProps) => {
  const context = useMemo(() => ({ align, template }), [align, template]);

  return (
    <DefaultTextPropsProvider size="small" weight="medium">
      <DataGroupContext.Provider value={context}>
        <Stack gap="medium" as="dl" dividers marginY="none">
          {children}
        </Stack>
      </DataGroupContext.Provider>
    </DefaultTextPropsProvider>
  );
};
