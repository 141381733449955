import { FragmentData, OperationData, gql } from '@ts-gql/tag';
import { useQuery } from '@ts-gql/apollo';

const RELATIONSHIP_QUERY = gql`
  query GetRelationships($id: ID!) {
    relationships(id: $id) {
      ...Relationships_Relationship
    }
  }
` as import('../../__generated__/ts-gql/GetRelationships').type;

export const RelationshipsFragment = gql`
  fragment Relationships_Relationship on Relationship {
    id
    type
    sourceId
    targetId
    relationshipType {
      id
      description
    }
    relation {
      id
      sortName
    }
    primaryContact
  }
` as import('../../__generated__/ts-gql/Relationships_Relationship').type;

export type RelationshipQueryType = OperationData<typeof RELATIONSHIP_QUERY>;
export type Relationship = FragmentData<typeof RelationshipsFragment>;

export const useQueryRelationships = (id: string) => {
  const {
    data: relationshipsData,
    loading: loadingRelationships,
    error: relationshipsError,
  } = useQuery(RELATIONSHIP_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });

  return {
    loading: loadingRelationships,
    relationships: relationshipsData?.relationships || [],
    error: relationshipsError,
  };
};
