/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { useTheme } from '@reckon-web/theme';
import { hexToRgb } from '@reckon-web/utils';

export const Divider = ({ active }: { active: boolean }) => {
  const { palette } = useTheme();
  const height = 1;

  return (
    <div
      css={{
        backgroundColor: hexToRgb(palette.text.base, 0.12),
        flexShrink: 0,
        height: height,
        marginBottom: -height,
        marginTop: -height,
        opacity: active ? 1 : 0,
        position: 'relative',
        transition: 'opacity 150ms linear',
        zIndex: 1,
      }}
    />
  );
};
