/** @jsx jsx */
import { useState } from 'react';

import { jsx } from '@reckon-web/core';
import { Heading } from '@reckon-web/heading';
import { Stack } from '@reckon-web/stack';
import { Flex } from '@reckon-web/flex';
import { Box } from '@reckon-web/box';
import { Form, resetForm } from '@reckon-web/forms';
import { AutocompleteField } from '@reckon-web/autocomplete';
import { Button } from '@reckon-web/button';

import { addressSchema } from '../utils';
import { useUser } from '../../../auth';
import { getAddress, searchAddress } from '../utils/address-service';
import { TextField } from '../../../design-system/text-field';
import { SelectField } from '../../../design-system/select-field';

const toCountryCode = {
  Australia: 'au',
  'New Zealand': 'nz',
};

const toCountry = {
  au: 'Australia',
  nz: 'New Zealand',
};

const countryList = [
  { value: 'au', label: 'Australia' },
  { value: 'nz', label: 'New Zealand' },
  { value: 'other', label: 'Other' },
];
const stateList = [
  { value: 'NSW', label: 'NSW' },
  { value: 'VIC', label: 'VIC' },
  { value: 'QLD', label: 'QLD' },
  { value: 'WA', label: 'WA' },
  { value: 'SA', label: 'SA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'NT', label: 'NT' },
  { value: 'ACT', label: 'ACT' },
];

// component
export function AddressForm({
  formName,
  isEdit = false,
  form,
}: {
  formName: string;
  isEdit?: boolean;
  form: Form<typeof addressSchema>;
}) {
  const { access_token, profile } = useUser();
  const [tenantCountry, setTenantCountry] = useState<string | undefined>(() =>
    profile.country === 'NZ' ? profile.country.toLowerCase() : 'au'
  );

  let formCountry =
    (form.fields.country.value &&
      toCountryCode[form.fields.country.value as keyof typeof toCountryCode]) ||
    undefined;

  if (!formCountry && form.fields.country.value) formCountry = 'other';
  if (formCountry === undefined) formCountry = tenantCountry;

  const countryLookup = form.fields.countryLookup.value || formCountry;

  let showOtherField = false;
  if (form.fields.countryLookup.value === 'other') showOtherField = true;
  if (formCountry && formCountry !== 'nz' && formCountry !== 'au')
    showOtherField = true;

  const handleClearAddressForm = () => {
    resetForm(form);
    setTenantCountry(undefined);
  };

  return (
    <Stack marginY="medium" gap="large">
      <Flex alignItems="center" justifyContent="space-between">
        <Heading level="3">{formName}</Heading>
        <Button
          label="Clear"
          weight="none"
          onClick={handleClearAddressForm}
          size="small"
        />
      </Flex>
      <SelectField
        {...form.fields.countryLookup.props}
        id="Country"
        value={countryLookup}
        label="Country"
        placeholder="Select country"
        options={countryList}
        onChange={(option: string | undefined) => {
          form.fields.countryLookup.setState({
            value: option,
            touched: true,
          });

          form.fields.country.setState({
            value: option === 'au' || option === 'nz' ? toCountry[option] : '',
            touched: true,
          });
        }}
      />

      {!showOtherField && (
        <div>
          <AutocompleteField
            label="Address lookup"
            description="Find an Au/Nz address in the lookup, or enter manually below"
            onChange={async (option) => {
              if (!option) return;
              const a = await getAddress(
                option?.value,
                access_token,
                countryLookup
              );

              form.setState({
                line1: {
                  ...form.state.line1,
                  value: a.buildingName ? a.buildingName : a.addressLine,
                },
                line2: {
                  ...form.state.line2,
                  value: a.buildingName ? a.addressLine : '',
                },
                line3: {
                  ...form.state.line3,
                  value: a.countrySpecific?.suburb || '',
                },
                state: {
                  ...form.state.state,
                  value: a.countrySpecific?.state || '',
                },
                city: {
                  ...form.state.city,
                  value:
                    countryLookup === 'nz'
                      ? a.countrySpecific?.townCityMailtown
                      : a.countrySpecific?.locality,
                },
                postcode: {
                  ...form.state.postcode,
                  value: a.postcode,
                },
                country: {
                  ...form.state.country,
                  value:
                    (a.country &&
                      toCountry[
                        a.country.toLowerCase() as keyof typeof toCountry
                      ]) ||
                    '',
                },
              });
            }}
            loadOptions={async (inputValue) => {
              if (!countryLookup || countryLookup === 'other') return [];

              const searchResult = await searchAddress(
                inputValue,
                access_token,
                countryLookup
              ).then((res) => {
                return res.map((a) => {
                  return {
                    value: a.RecordId,
                    label: `${a.AddressLine}, ${a.Locality}, ${a.State}, ${a.Postcode}`,
                  };
                });
              });

              return searchResult;
            }}
          />
        </div>
      )}

      <TextField
        {...form.fields.line1.props}
        id="Address-line-1"
        placeholder="Enter address line 1"
        label="Address line 1"
        onChange={(e) => {
          form.fields.line1.setState({
            value: e.target.value,
            touched: true,
          });

          if (countryLookup === 'nz' || countryLookup === 'au') {
            form.fields.country.setState({
              value: toCountry[countryLookup],
              touched: true,
            });
          }
        }}
      />
      <TextField
        {...form.fields.line2.props}
        id="Address-line-2"
        placeholder="Enter address line 2"
        label="Address line 2"
      />
      <TextField
        {...form.fields.line3.props}
        id="Address-line-3"
        placeholder={
          countryLookup === 'au' ? 'Enter address line 3' : 'Enter suburb'
        }
        label={countryLookup === 'au' ? 'Address line 3' : 'Suburb'}
      />

      <Flex justifyContent="space-between">
        <Box width="50%" marginRight="medium">
          <TextField
            {...form.fields.city.props}
            id="City"
            label="City"
            placeholder="Enter city"
          />
        </Box>
        {countryLookup === 'au' && (
          <Box width="50%">
            <SelectField
              {...form.fields.state.props}
              id="State"
              label="State"
              placeholder="Enter state"
              options={stateList}
            />
          </Box>
        )}
      </Flex>

      <Flex justifyContent="space-between">
        <Box width="50%" marginRight="medium">
          <TextField
            {...form.fields.postcode.props}
            id="Postcode"
            placeholder="Enter postcode"
            label="Postcode"
          />
        </Box>
        {showOtherField && (
          <Box width="50%">
            <TextField
              {...form.fields.country.props}
              placeholder="Enter country"
              label="Other country"
            />
          </Box>
        )}
      </Flex>
    </Stack>
  );
}
