/** @jsx jsx */

import {
  KeyboardEvent,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from 'react';

import { jsx } from '@reckon-web/core';
import { useForkedRef } from '@reckon-web/utils';

import { SortDirection } from '../types';
import { siftAriaAttributes } from '../utils';

import { CellContent } from './CellContent';
import { SortIndicator } from './SortIndicator';
import { TableCellProps, UseCellStylesProps, useCellStyles } from './TableCell';

export type TableColumnHeaderProps = Omit<TableCellProps, 'role'> &
  UseCellStylesProps & {
    /**
     * The callback to run when a user clicks the column header. Typically
     * reserved for sort behaviour.
     */
    onClick?: () => void;
    /**
     * The aria-sort attribute indicates if items in a table or grid are sorted in
     * ascending or descending order.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-sort
     */
    'aria-sort'?: SortDirection;
  };

export const TableColumnHeader = forwardRef<
  HTMLDivElement,
  TableColumnHeaderProps
>(({ children, id, onClick, ...props }, forwardedRef) => {
  const { dataAttributes, css, style } = useCellStyles(props);
  const ariaAttributes = siftAriaAttributes(props);
  // in this case "active" means hovered or focused
  const [isActive, setActive] = useState(false);

  const internalRef = useRef<HTMLSpanElement>(null);
  const ref = useForkedRef(internalRef, forwardedRef);

  const onActivate = useCallback(() => {
    setActive(true);
  }, []);
  const onDeactivate = useCallback(() => {
    setActive(false);
  }, []);
  const onKeyDown = useCallback((event: KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      internalRef.current?.click();
    }
  }, []);

  // indicator
  const tone = onClick && isActive ? 'base' : 'dim';
  const sortIndicator = onClick && <SortIndicator tone={tone} />;

  return (
    <div
      role="columnheader"
      id={id}
      css={css}
      style={style}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onActivate}
      onMouseLeave={onDeactivate}
      onFocus={onActivate}
      onBlur={onDeactivate}
      tabIndex={onClick ? 0 : undefined}
      ref={ref}
      {...ariaAttributes}
      {...dataAttributes}
    >
      {props.align === 'right' && sortIndicator}

      <CellContent color={tone} weight="medium" transform="uppercase">
        {children}
      </CellContent>

      {props.align !== 'right' && sortIndicator}
    </div>
  );
});
