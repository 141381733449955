/** @jsx jsx */
import { useReducer, useState } from 'react';
import { gql } from '@ts-gql/tag';
import { useMutation, useQuery } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { Button } from '@reckon-web/button';
import { SideDrawer, SideDrawerController } from '@reckon-web/side-drawer';
import { Stack } from '@reckon-web/stack';
import { useToasts } from '@reckon-web/toast';
import { field, useForm, useFormSnapshot, useSubmit } from '@reckon-web/forms';
import { Notice } from '@reckon-web/notice';

import { useError } from '../../../../utilities/hooks/useError';
import { DiscardConfirmationDialog } from '../../../common/dialogs/DiscardConfirmationDialog';
import { GROUPS_LIST_QUERY } from '../../createEditClientDrawer/forms/GeneralDetailsForm';
import { ADMIN, useAccessLevel } from '../../../access';
import { SelectField } from '../../../design-system/select-field';

import { Client } from './ClientSecurityGroup';
export const UPDATE_CLIENT_SECURITY_GROUP = gql`
  mutation UpdateClientSecurityGroup($groupId: ID!, $clientId: ID!) {
    updateGroup(
      id: $groupId
      input: { clients: { connect: [{ id: $clientId }] } }
    ) {
      id
      name
    }
  }
` as import('../../../../../__generated__/ts-gql/UpdateClientSecurityGroup').type;
const editSecurityGroupForm = field.object({
  group: field.select()(),
});
// component
export const EditSecurityGroup = ({
  client,
  securityGroup,
}: {
  client: Client;
  securityGroup: Client['securityGroup'];
}) => {
  const accessLevel = useAccessLevel();
  const { addToast } = useToasts();
  const [isOpen, toggleIsOpen] = useReducer((bool) => !bool, false);
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
  const form = useForm(editSecurityGroupForm, {
    group: securityGroup?.id || '',
  });
  const isDirty = useFormSnapshot(form);
  const { data } = useQuery(GROUPS_LIST_QUERY, {
    variables: { limit: 100, skip: 0 },
  });
  const groupsList = data?.groups
    .filter((i) => i.userBelongsToGroup)
    .map((group) => ({
      value: group.id,
      label: group.name,
    }))
    .filter(
      (group): group is { value: string; label: string } =>
        group.value !== null && group.label !== 'Administrator'
    );
  const [UpdateClientSecurityGroup, { loading }] = useMutation(
    UPDATE_CLIENT_SECURITY_GROUP
  );
  const handleError = useError();
  const handleSaveAndCloseSubmit = useSubmit(form, (value) => {
    UpdateClientSecurityGroup({
      variables: {
        groupId: form?.value?.group || '',
        clientId: client.id,
      },
      refetchQueries: ['ClientDetails'],
    })
      .then(() => {
        toggleIsOpen();
        addToast({
          tone: 'positive',
          title: 'Security group updated',
          message: `Successfully added ${client.sortName} to the group: ${
            groupsList?.find((i) => i.value === form.value.group)?.label
          }`,
        });
      })
      .catch((error) => {
        handleError({ title: 'Failed to update client security group', error });
      });
  });
  const handleCancel = () => {
    isDirty ? setIsDiscardDialogOpen(true) : toggleIsOpen();
  };
  return (
    <div>
      <Button
        size="small"
        weight="none"
        onClick={() => {
          if (accessLevel !== ADMIN) {
            return addToast({
              title: 'Edit security group permission denied',
              message: 'Speak to an admin to upgrade your permissions level',
              tone: 'critical',
            });
          }
          toggleIsOpen();
        }}
        label="Edit"
      />
      ​
      <SideDrawerController isOpen={isOpen} onClose={toggleIsOpen}>
        <SideDrawer>
          <SideDrawer.Form onSubmit={handleSaveAndCloseSubmit}>
            <SideDrawer.Header>
              <SideDrawer.Title>Change client security group</SideDrawer.Title>
            </SideDrawer.Header>
            <SideDrawer.Body>
              <Stack marginY="xlarge" gap="xlarge" paddingX="xlarge">
                <Notice>
                  Changing the group may affect who can access this record.
                </Notice>
                <SelectField
                  {...form.fields.group.props}
                  id="Select-client-group"
                  label="Select client group"
                  options={groupsList || []}
                  helpText="Choose a security group for this client. You can only choose a security group that your user belongs to"
                />
              </Stack>
            </SideDrawer.Body>
            <SideDrawer.Footer>
              <Button label="Save & close" type="submit" loading={loading} />
              <Button
                label="Cancel"
                weight="none"
                onClick={handleCancel}
                disabled={loading}
              />
            </SideDrawer.Footer>
          </SideDrawer.Form>
        </SideDrawer>
        <DiscardConfirmationDialog
          isDiscardDialogOpen={isDiscardDialogOpen}
          setIsDiscardDialogOpen={setIsDiscardDialogOpen}
          callback={() => toggleIsOpen()}
        />
      </SideDrawerController>
    </div>
  );
};
