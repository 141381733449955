import { Dispatch, SetStateAction } from 'react';
import PQueue from 'p-queue';

import { ApolloClientType, ImportResults, NonMatchingClient } from '../types';
import { UPDATE_FAMILY_GROUP } from '../../client/clientRightColumn/debtorAndFamilyGroups/EditFamilyGroupDrawer';
import { UPDATE_DEBTOR_GROUP } from '../../client/clientRightColumn/debtorAndFamilyGroups/EditDebtorGroupDrawer';

import { checkIfExistingClient } from './checkIfExistingClient';

export const checkOutstandingFamilyOrDebtor = async ({
  nonMatchingFamily,
  nonMatchingDebtor,
  apolloClient,
  data,
  setUpdatingGroupsStatus,
}: {
  nonMatchingFamily: NonMatchingClient[];
  nonMatchingDebtor: NonMatchingClient[];
  apolloClient: ApolloClientType;
  data: ImportResults[];
  setUpdatingGroupsStatus: Dispatch<SetStateAction<boolean>>;
}) => {
  let results = [];
  if (nonMatchingFamily.length || nonMatchingDebtor.length) {
    setUpdatingGroupsStatus(true);
    await delay(2000);
    results = await updateDifferentFamilyDebtor(
      nonMatchingFamily,
      nonMatchingDebtor,
      apolloClient,
      data
    );
    setUpdatingGroupsStatus(false);
  }

  return { data, nonMatchingDebtorFamilyResults: results };
};

const updateFamilyDebtor = async (
  item: NonMatchingClient,
  apolloClient: ApolloClientType,
  previousResultsData: ImportResults[],
  type: 'family' | 'debtor'
) => {
  const debtorFamClient = await checkIfExistingClient(
    { sortName: item.clientName },
    apolloClient
  );

  if (debtorFamClient) {
    const clientToUpdate = previousResultsData.find((i) => i.row === item.row);
    const clientId = clientToUpdate?.success.id;
    // const clientId = previousResultsData[item.row]?.success.id;
    try {
      if (type === 'family') {
        const { data } = await apolloClient.mutate({
          mutation: UPDATE_FAMILY_GROUP,
          variables: {
            id: clientId,
            familyGroupId: debtorFamClient.id,
          },
        });
        return { success: data?.updateClient, type };
      } else {
        const { data } = await apolloClient.mutate({
          mutation: UPDATE_DEBTOR_GROUP,
          variables: {
            id: clientId,
            debtorId: debtorFamClient.id,
          },
        });

        return { success: data?.updateClient, type };
      }
    } catch (error) {
      return {
        failure: { sortName: item.clientName },
        row: item.row,
        type,
        error,
      };
    }
  } else {
    const error = `${type} group client does not exist in the database for ${item.clientName}`;

    return {
      failure: { sortName: item.clientName, message: error },
      row: item.row,
      type,
      error,
    };
  }
};

export const updateDifferentFamilyDebtor = async (
  nonMatchingFamily: NonMatchingClient[],
  nonMatchingDebtor: NonMatchingClient[],
  apolloClient: ApolloClientType,
  previousResultsData: ImportResults[]
) => {
  let familyUpdate: any = [];
  let debtorUpdate: any = [];
  const familyQueue = new PQueue({ concurrency: 8 });
  const debtorQueue = new PQueue({ concurrency: 8 });

  if (nonMatchingFamily.length) {
    const familyPromises = nonMatchingFamily.map((item) => {
      return () =>
        new Promise((resolve, reject) => {
          resolve(
            updateFamilyDebtor(
              item,
              apolloClient,
              previousResultsData,
              'family'
            )
          );
        });
    });

    await familyQueue.addAll(familyPromises).then((data) => {
      familyUpdate = data;
    });
  }
  if (nonMatchingDebtor.length) {
    const debtorPromises = nonMatchingDebtor.map((item) => {
      return () =>
        new Promise((resolve, reject) => {
          resolve(
            updateFamilyDebtor(
              item,
              apolloClient,
              previousResultsData,
              'debtor'
            )
          );
        });
    });

    await debtorQueue.addAll(debtorPromises).then((data) => {
      debtorUpdate = data;
    });
  }

  return [...familyUpdate, ...debtorUpdate];
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
