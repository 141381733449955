// ts-gql-integrity:31f570b38fa5d28dec9388ac0d744314
/*
ts-gql-meta-begin
{
  "hash": "4f9c8e65d42aa7d26dcafa0025bf9260"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type DebtorAndFamilyGroupMembers_group_Organisation_Fragment = (
  { readonly __typename: 'Organisation' }
  & Pick<SchemaTypes.Organisation, 'registeredName' | 'id' | 'sortName'>
);

type DebtorAndFamilyGroupMembers_group_Person_Fragment = (
  { readonly __typename: 'Person' }
  & Pick<SchemaTypes.Person, 'firstName' | 'lastName' | 'id' | 'sortName'>
);

type DebtorAndFamilyGroupMembers_groupFragment = DebtorAndFamilyGroupMembers_group_Organisation_Fragment | DebtorAndFamilyGroupMembers_group_Person_Fragment;


export type type = TypedDocumentNode<{
  type: "fragment";
  result: DebtorAndFamilyGroupMembers_groupFragment;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    DebtorAndFamilyGroupMembers_group: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"DebtorAndFamilyGroupMembers_group\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Client\"}},\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"sortName\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Organisation\"}},\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"registeredName\"},\"arguments\":[],\"directives\":[]}]}},{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Person\"}},\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"firstName\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"lastName\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
