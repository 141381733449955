import { Dispatch, SetStateAction } from 'react';

import { Failure } from '../types';

export type ImportResults = {
  row: number;
  success?: any;
  failure?: Failure;
};

export const setDoneMessaging = ({
  data,
  setImportError,
  setBeginImport,
  setImportWarnings,
  nonMatchingDebtorFamilyResults,
  setPostImportCount,
  setImportDone,
}: {
  data: ImportResults[];
  setImportError: Dispatch<SetStateAction<boolean>>;
  setBeginImport: Dispatch<SetStateAction<boolean>>;
  setImportWarnings: Dispatch<SetStateAction<any[]>>;
  nonMatchingDebtorFamilyResults: ImportResults[];
  setPostImportCount: Dispatch<
    SetStateAction<{
      successCount: number;
      failCount: number;
    }>
  >;
  setImportDone: Dispatch<SetStateAction<boolean>>;
}) => {
  let successCount = 0;
  let failCount = 0;
  let failItems: ImportResults[] = [];

  // check if all imports failed
  const anySuccess = data.reduce((acc: boolean, curr) => {
    if (curr.success) {
      successCount++;
      return (acc = true);
    }
    failCount++;
    failItems.push(curr);
    return acc;
  }, false);
  // if all fail return this
  if (!anySuccess) {
    setImportError(true);
    return setBeginImport(false);
  }

  // sets the warning state for any failed imports
  if (failCount) {
    setImportWarnings((prevWarnings) => [...prevWarnings, failItems]);
  }

  // sets a warning state for any failed changes to family and debtor groups after the initial import, where the client did not exist initially when assigning a group
  if (nonMatchingDebtorFamilyResults.length) {
    let nonMatchingUpdateFails: any[] = [];
    nonMatchingDebtorFamilyResults.forEach((i) => {
      if (i?.failure) nonMatchingUpdateFails.push(i);
    });
    setImportWarnings((prevWarnings) => [
      ...prevWarnings,
      nonMatchingUpdateFails,
    ]);
  }
  setPostImportCount({ successCount, failCount });
  setImportDone(true);
};
