/** @jsx jsx */
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { useApolloClient } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { AlertDialog } from '@reckon-web/modal-dialog';
import { Text } from '@reckon-web/text';
import { ThemeProvider } from '@reckon-web/theme';

import { STAFF_QUERY } from '../client/clientRightColumn/management/EditManagementGroupDrawer';
import { ENTITY_TYPE } from '../client/createEditClientDrawer/CreateEditClientDrawerContainer';

import { initialValidation } from './functions/initialValidation';
import { CSV, Failure, FileInterface } from './types';
import { ImportClientDialog } from './ImportClientDialog';

type ImportClientContextType = {
  isImportClientDialogOpen: boolean;
  setIsImportClientDialogOpen: Dispatch<SetStateAction<boolean>>;
  isMapCsvDialogOpen: boolean;
  setIsMapCsvDialogOpen: Dispatch<SetStateAction<boolean>>;
  verifyingCsv: boolean;
  setVerifyingCsv: Dispatch<SetStateAction<boolean>>;
  beginImport: boolean;
  setBeginImport: Dispatch<SetStateAction<boolean>>;
  importWarnings: {
    failure: Failure;
    row: number;
  }[];
  setImportWarnings: Dispatch<
    SetStateAction<
      {
        failure: Failure;
        row: number;
      }[]
    >
  >;
  importError: boolean;
  setImportError: Dispatch<SetStateAction<boolean>>;
  importDone: boolean;
  setImportDone: Dispatch<SetStateAction<boolean>>;
  initialValidationFail: boolean;
  setInitialValidationFail: Dispatch<SetStateAction<boolean>>;
  cancelOpen: boolean;
  setCancelOpen: Dispatch<SetStateAction<boolean>>;
  csv: CSV | undefined;
  setCsv: Dispatch<SetStateAction<CSV | undefined>>;
  file: FileInterface;
  setFile: Dispatch<SetStateAction<FileInterface>>;
  handleCloseImportDialog: () => void;
};

export const ImportClientContext = createContext<ImportClientContextType>({
  isImportClientDialogOpen: false,
  setIsImportClientDialogOpen: () => false,
  isMapCsvDialogOpen: false,
  setIsMapCsvDialogOpen: () => false,
  verifyingCsv: false,
  setVerifyingCsv: () => false,
  beginImport: false,
  setBeginImport: () => false,
  importWarnings: [],
  setImportWarnings: () => [],
  importError: false,
  setImportError: () => false,
  importDone: false,
  setImportDone: () => false,
  initialValidationFail: false,
  setInitialValidationFail: () => false,
  cancelOpen: false,
  setCancelOpen: () => false,
  csv: undefined,
  setCsv: () => undefined,
  file: {
    status: 'none',
    meta: null,
  },
  setFile: () => {
    return {
      status: 'none',
      meta: null,
    };
  },
  handleCloseImportDialog: () => {},
});

export const ImportClientDialogContainer = ({
  isImportClientDialogOpen,
  setIsImportClientDialogOpen,
}: {
  isImportClientDialogOpen: boolean;
  setIsImportClientDialogOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const apolloClient = useApolloClient();
  const [file, setFile] = useState<FileInterface>({
    status: 'none',
    meta: null,
  });

  const [isMapCsvDialogOpen, setIsMapCsvDialogOpen] = useState(false);

  const [csv, setCsv] = useState<CSV>();
  const [verifyingCsv, setVerifyingCsv] = useState(false);
  const [beginImport, setBeginImport] = useState(false);

  const [importWarnings, setImportWarnings] = useState<
    { failure: Failure; row: number }[]
  >([]);

  const [importError, setImportError] = useState(false);
  const [importDone, setImportDone] = useState(false);
  const [initialValidationFail, setInitialValidationFail] = useState(false);

  const [cancelOpen, setCancelOpen] = useState(false);

  useEffect(() => {
    if (csv && !isMapCsvDialogOpen) {
      (async function () {
        const { data: fetchedEntityTypes } = await apolloClient.query({
          query: ENTITY_TYPE,
        });
        const entityTypes = fetchedEntityTypes?.entityTypes && [
          ...fetchedEntityTypes?.entityTypes,
        ];
        const { data: fetchedStaff } = await apolloClient.query({
          query: STAFF_QUERY,
        });
        let staffMembers = fetchedStaff?.users && [...fetchedStaff?.users];

        const invalidRecordsCheck = initialValidation(
          staffMembers,
          entityTypes,
          csv
        );
        if (invalidRecordsCheck.length) {
          setInitialValidationFail(true);
          setImportWarnings(invalidRecordsCheck);
        }

        setVerifyingCsv(false);
      })();
    }
  }, [apolloClient, csv, isMapCsvDialogOpen]);

  const cancelActions = {
    confirm: {
      label: 'Yes, cancel import',
      action: () => window.location.reload(),
    },
    cancel: { label: 'No go back', action: () => setCancelOpen(false) },
  };

  const handleCloseImportDialog = () => {
    setFile({ status: 'none', meta: null });
    setCsv(undefined);
    setVerifyingCsv(false);
    setBeginImport(false);
    setIsImportClientDialogOpen(false);
    setImportDone(false);
    setImportWarnings([]);
    setInitialValidationFail(false);
  };

  return (
    <ThemeProvider
      theme={{
        colors: {
          background: '#ffffff',
          text: '#20262D',
          primary: '#007AFF',
          accent: '#3C3391',
        },
      }}
    >
      <ImportClientContext.Provider
        value={{
          isImportClientDialogOpen,
          setIsImportClientDialogOpen,
          isMapCsvDialogOpen,
          setIsMapCsvDialogOpen,
          verifyingCsv,
          setVerifyingCsv,
          beginImport,
          setBeginImport,
          importWarnings,
          setImportWarnings,
          importError,
          setImportError,
          importDone,
          setImportDone,
          initialValidationFail,
          setInitialValidationFail,
          cancelOpen,
          setCancelOpen,
          csv,
          setCsv,
          file,
          setFile,
          handleCloseImportDialog,
        }}
      >
        <ImportClientDialog />
      </ImportClientContext.Provider>

      <AlertDialog
        actions={cancelActions}
        isOpen={cancelOpen}
        title="Cancel import?"
      >
        <Text>
          Are you sure you want to cancel this import, any clients already
          imported will not be removed
        </Text>
      </AlertDialog>
    </ThemeProvider>
  );
};
