/** @jsx jsx */

import { Box } from '@reckon-web/box';
import { jsx } from '@reckon-web/core';
import { Flex } from '@reckon-web/flex';
import { ThemeProvider, useTheme } from '@reckon-web/theme';
import { useMediaQuery } from '@reckon-web/media-query';

import { SearchClients } from '../SearchClients';
import { CreateEditClientDrawerContainer } from '../client/createEditClientDrawer/CreateEditClientDrawerContainer';
import { Wrapper } from '../Wrapper';

import { Profile } from './Profile';
import { NavLeft } from './NavLeft';
import { Logo } from './Logo';
import { NavRight } from './NavRight';

export const Header = () => {
  const { palette } = useTheme();
  const { maxBreak } = useMediaQuery();
  return (
    <ThemeProvider
      theme={{
        colors: {
          accent: '#3EBA63',
          background: '#233365',
          text: '#FFFFFF',
        },
      }}
      paletteResolver={() => ({
        background: {
          dialog: palette.background.dialog,
        },
        menuItem: palette.menuItem,
      })}
    >
      <Wrapper>
        <Flex
          background="base"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          minHeight="64px"
          wrap
        >
          <Flex alignItems="center" gap="xxlarge" height="100%">
            <Logo />
            <NavLeft />
            <Box
              width={400}
              css={{
                [maxBreak('xlarge')]: { width: 270 },
                [maxBreak('large')]: { width: 240 },
              }}
            >
              <SearchClients isNavigation={true} />
            </Box>
          </Flex>

          <Flex gap="medium" alignItems="center" height="100%">
            <NavRight />
            <CreateEditClientDrawerContainer />
            <Profile />
          </Flex>
        </Flex>
      </Wrapper>
    </ThemeProvider>
  );
};
