/** @jsx jsx */
import { jsx } from '@reckon-web/core';

export const FullPageHorizontalDivider = () => (
  <div
    title="full-page-horizontal-divider"
    css={{
      height: 1,
      width: '100%',
      flexShrink: 0,
      backgroundColor: '#E8E8E8',
      position: 'absolute',
      left: 0,
    }}
  />
);
