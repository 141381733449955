/** @jsx jsx */

import { Children, ReactNode } from 'react';

import { Box, BoxProps } from '@reckon-web/box';
import { ResponsiveProp, jsx } from '@reckon-web/core';
import { useMediaQuery } from '@reckon-web/media-query';
import { ReckonTheme, useTheme } from '@reckon-web/theme';
import { forwardRefWithAs } from '@reckon-web/utils';

const alignVerticalMap = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  stretch: 'stretch',
} as const;

export type ColumnsProps = {
  /** Vertically align items within the container. */
  alignY?: keyof typeof alignVerticalMap;
  /** Elements representing each column. */
  children: ReactNode;
  /** At which breakpoint, if any, should the columns collapse. */
  collapse?: keyof ReckonTheme['breakpoints'];
  /** The size of the gap between each column. */
  gap?: ResponsiveProp<keyof ReckonTheme['spacing']>;
  /** Define the relative width of each column. By default each column is the same width. */
  template?: number[];
} & BoxProps;

export const Columns = forwardRefWithAs<'div', ColumnsProps>(
  (
    { alignY = 'top', collapse, gap = 'none', template, ...boxProps },
    forwardedRef
  ) => {
    const count = Children.toArray(boxProps.children).length; // Children.count() counts falsey children
    const { spacing } = useTheme();
    const { mq, mapResponsiveProp, maxBreak } = useMediaQuery();
    const alignItems = alignVerticalMap[alignY];
    const gridTemplateColumns = template
      ? template.map((c) => `${c}fr`).join(' ')
      : `repeat(${count}, 1fr)`;
    const collapseStyles = collapse
      ? {
          [maxBreak(collapse)]: {
            gridTemplateColumns: 'none',
          },
        }
      : {};

    return (
      <Box
        ref={forwardedRef}
        css={mq({
          alignItems,
          display: 'grid',
          gap: mapResponsiveProp(gap, spacing),
          gridTemplateColumns: gridTemplateColumns,
          ...collapseStyles,
        })}
        {...boxProps}
      />
    );
  }
);
