import dayjs from 'dayjs';

import { CSV, EntityType, Failure, StaffMember } from '../types';
import {
  validateABN,
  validateCharacterCount,
  validateIRD,
  validateNZBN,
  validateTFN,
} from '../../../utilities/validators';

export const titleColumns = [
  'FamilyGroup',
  'Debtor',
  'Sortname',
  'EntityType',
  'Partner',
  'Manager',
  'Staff',
  'ExtClientID',
  'Title',
  'FirstName',
  'MidName',
  'LastName',
  'Initials',
  'RegName',
  'TradingAs',
  'Salutation',
  'MailName',
  'BalanceMonth',
  'ABN',
  'ABNDiv',
  'TFN',
  'ACN',
  'IRD',
  'NZBN',
  'StartDate',
  'EndDate',
  'DOB',
  'PlaceOfBirth',
  'DOD',
  'AnnualReturnMonth',
  'PrimaryPh',
  'OtherPh',
  'Fax',
  'PrimaryEmail',
  'OtherEmail',
  'Website',
  'OtherInfo',
  'StreetLine1',
  'StreetLine2',
  'StreetCity',
  'StreetState',
  'StreetPcode',
  'StreetCountry',
  'PostalLine1',
  'PostalLine2',
  'PostalCity',
  'PostalState',
  'PostalPcode',
  'PostalCountry',
  'RegLine1',
  'RegLine2',
  'RegCity',
  'RegState',
  'RegPCode',
  'RegCountry',
];

const addWarning = ({
  warnings,
  sortName,
  row,
  message,
}: {
  warnings: { failure: Failure; row: number }[];
  sortName: string;
  row: number;
  message: string;
}) =>
  warnings.push({
    failure: {
      sortName,
      message,
    },
    row,
  });

const checkForDuplicates = ({
  sortNameColumn,
  externalClientIdColumn,
  existingSortNames,
  existingExternalClientIds,
  warnings,
  sortName,
  item,
  titleRow,
  row,
}: {
  sortNameColumn: number;
  externalClientIdColumn: number;
  existingSortNames: string[];
  existingExternalClientIds: string[];
  warnings: { failure: Failure; row: number }[];
  sortName: string;
  item: string[];
  titleRow: string[];
  row: number;
}) => {
  const warningsProps = { warnings, sortName, row };

  item.forEach((colItem: string, colIndex: number) => {
    // looking for duplicate sortnames
    if (colIndex === sortNameColumn) {
      existingSortNames.includes(colItem)
        ? addWarning({
            ...warningsProps,
            message: `"Sortname" ${colItem} is duplicated in at row ${
              existingSortNames.findIndex((i) => i === colItem) + 2
            } and row ${row}`,
          })
        : existingSortNames.push(colItem);
    }
    if (colIndex === externalClientIdColumn) {
      colItem !== '' && existingExternalClientIds.includes(colItem)
        ? addWarning({
            ...warningsProps,
            message: `"ExtClientId" ${colItem} is duplicated in at row ${
              existingExternalClientIds.findIndex((i) => i === colItem) + 2
            } and row ${row}`,
          })
        : existingExternalClientIds.push(colItem);
    }
  });
};

const checkBadEntityInfo = ({
  entityTypes,
  warnings,
  sortName,
  item,
  titleRow,
  row,
}: {
  entityTypes: EntityType[] | undefined;
  warnings: { failure: Failure; row: number }[];
  sortName: string;
  item: string[];
  titleRow: string[];
  row: number;
}) => {
  const entityToCheck = item[titleRow.indexOf('EntityType')];
  const warningsProps = { warnings, sortName, row };
  const entityType = entityTypes?.find(
    (entity) => entity.name === entityToCheck
  );

  if (entityToCheck?.trim() === '')
    return addWarning({
      ...warningsProps,
      message:
        'There is no "EntityType" detected, this is a required field. Please enter either: Individual, Partnership, Company, Superannuation Fund, Club, Trust, Maori Authority, Government, Incorporated Society, Estate or Trustee Company',
    });

  if (!entityType)
    return addWarning({
      ...warningsProps,
      message: `${entityToCheck} is not a valid "EntityType". Please enter either: Individual, Partnership, Company, Superannuation Fund, Club, Trust, Maori Authority, Government, Incorporated Society, Estate or Trustee Company`,
    });

  if (entityType.type === 'Organisation') {
    if (!item[titleRow.indexOf('RegName')])
      addWarning({
        ...warningsProps,
        message: `Registered name is a required field for entity type ${entityType.name}, please enter a value into the "RegName" column`,
      });

    [
      { type: 'Title', value: item[titleRow.indexOf('Title')] },
      { type: 'FirstName', value: item[titleRow.indexOf('FirstName')] },
      { type: 'MidName', value: item[titleRow.indexOf('MidName')] },
      { type: 'LastName', value: item[titleRow.indexOf('LastName')] },
      { type: 'Initials', value: item[titleRow.indexOf('Initials')] },
    ].forEach((i) => {
      if (i?.value && i?.value?.trim() !== '')
        return addWarning({
          ...warningsProps,
          message: `Entity type ${entityType.name} should not contain a value in the "${i.type}" column`,
        });
    });
  }

  if (entityType.type === 'Person') {
    if (
      !item[titleRow.indexOf('FirstName')] ||
      !item[titleRow.indexOf('LastName')]
    )
      addWarning({
        ...warningsProps,
        message: `First name and last name are required fields for entity type ${entityType.name}, please enter a value into the "FirstName" & "LastName" column`,
      });

    [
      { type: 'RegName', value: item[titleRow.indexOf('RegName')] },
      { type: 'TradingAs', value: item[titleRow.indexOf('TradingAs')] },
    ].forEach((i) => {
      if (i?.value && i?.value?.trim() !== '')
        return addWarning({
          ...warningsProps,
          message: `Entity type ${entityType.name} should not contain a value in the "${i.type}" column`,
        });
    });
  }
};

const checkValidStaff = ({
  staffMembers,
  warnings,
  sortName,
  item,
  titleRow,
  row,
}: {
  staffMembers: StaffMember[] | undefined;
  warnings: { failure: Failure; row: number }[];
  sortName: string;
  item: string[];
  titleRow: string[];
  row: number;
}) => {
  const partner = item[titleRow.indexOf('Partner')];
  const manager = item[titleRow.indexOf('Manager')];
  const staff = item[titleRow.indexOf('Staff')];
  [
    { type: 'Partner', value: partner },
    { type: 'Manager', value: manager },
    { type: 'Staff', value: staff },
  ].forEach((i) => {
    if (!i.value) return;
    const staffMember = staffMembers?.find(
      (s) => s.name?.toUpperCase().trim() === i.value?.toUpperCase().trim()
    );
    if (!staffMember)
      return addWarning({
        warnings,
        sortName,
        row,
        message: `${i.type} - ${i.value} was not found, please review and try again`,
      });
  });
};

const checkTaxEntries = ({
  warnings,
  sortName,
  item,
  titleRow,
  row,
}: {
  warnings: { failure: Failure; row: number }[];
  sortName: string;
  item: string[];
  titleRow: string[];
  row: number;
}) => {
  // ABN Validator
  if (item[titleRow.indexOf('ABN')]?.trim()) {
    const isValid = validateABN(item[titleRow.indexOf('ABN')]?.trim());
    if (!isValid)
      addWarning({
        warnings,
        sortName,
        row,
        message: `"ABN" was detected as invalid, please fix and try again`,
      });
  }
  //   TFN Validator
  if (item[titleRow.indexOf('TFN')]?.trim()) {
    const isValid = validateTFN(item[titleRow.indexOf('TFN')]?.trim());
    if (!isValid)
      addWarning({
        warnings,
        sortName,
        row,
        message: `"TFN" was detected as invalid, please fix and try again`,
      });
  }
  // Ird validator
  if (item[titleRow.indexOf('IRD')]?.trim()) {
    const isValid = validateIRD(item[titleRow.indexOf('IRD')]?.trim());
    if (!isValid)
      addWarning({
        warnings,
        sortName,
        row,
        message: `"IRD" was detected as invalid, please fix and try again`,
      });
  }

  //   NZBN Number validator
  if (item[titleRow.indexOf('NZBN')]?.trim()) {
    const isValid = validateNZBN(item[titleRow.indexOf('NZBN')]?.trim());
    if (!isValid)
      addWarning({
        warnings,
        sortName,
        row,
        message: `"NZBN" was detected as invalid, please fix and try again`,
      });
  }
};

const checkDates = ({
  warnings,
  sortName,
  item,
  titleRow,
  row,
}: {
  warnings: { failure: Failure; row: number }[];
  sortName: string;
  item: string[];
  titleRow: string[];
  row: number;
}) => {
  [
    { type: 'StartDate', value: item[titleRow.indexOf('StartDate')]?.trim() },
    { type: 'EndDate', value: item[titleRow.indexOf('EndDate')]?.trim() },
    { type: 'DOB', value: item[titleRow.indexOf('DOB')]?.trim() },
    { type: 'DOD', value: item[titleRow.indexOf('DOD')]?.trim() },
  ].forEach((i) => {
    if (!i.value) return;

    let dateString = i.value;
    let isValid = true;

    if (dateString.includes('/')) {
      dateString = dateString.indexOf('/') === 1 ? `0${i.value}` : i.value;
      isValid = dayjs(dateString, 'DD/MM/YYYY', true).isValid();
    }
    if (dateString.includes('-')) {
      dateString = dateString.indexOf('-') === 1 ? `0${i.value}` : i.value;
      isValid = dayjs(dateString, 'DD-MM-YYYY', true).isValid();
    }

    if (!isValid)
      return addWarning({
        warnings,
        sortName,
        row,
        message: `${i.type} - ${i.value} was detected as an invalid date. Please enter date in the format "DD/MM/YYYY"`,
      });
  });
};

const checkCharacterLimits = ({
  warnings,
  sortName,
  item,
  titleRow,
  row,
}: {
  warnings: { failure: Failure; row: number }[];
  sortName: string;
  item: string[];
  titleRow: string[];
  row: number;
}) => {
  [
    {
      type: 'Title',
      value: item[titleRow.indexOf('Title')]?.trim(),
      maxLength: 15,
    },
    {
      type: 'Sortname',
      value: item[titleRow.indexOf('Sortname')]?.trim(),
      maxLength: 254,
    },
  ].forEach((i) => {
    if (!i.value) return;
    if (!validateCharacterCount(i.value, i.maxLength)) {
      return addWarning({
        warnings,
        sortName,
        row,
        message: `'${i.type}' field has too many characters, the max length is ${i.maxLength} characters`,
      });
    }
  });
};

export const initialValidation = (
  staffMembers: StaffMember[] | undefined,
  entityTypes: EntityType[] | undefined,
  csv: CSV
) => {
  const { records, titleRow } = csv;

  let warnings: { failure: Failure; row: number }[] = [];

  let invalidColumnNames: string[] = [];
  titleRow.map((i: string) => {
    if (i === '') return i;
    if (!titleColumns.includes(i)) return invalidColumnNames.push(i);
    return i;
  });

  if (invalidColumnNames.length) {
    warnings.push({
      failure: {
        sortName: 'column name',
        message: `Invalid column name detected; cannot recognise ${invalidColumnNames.join(
          ','
        )}`,
      },
      row: 0,
    });
    return warnings;
  }

  const sortNameColumn = titleRow.indexOf('Sortname');
  const externalClientIdColumn = titleRow.indexOf('ExtClientID');
  let existingSortNames: string[] = [];
  let existingExternalClientIds: string[] = [];

  records.forEach((item: string[], index: number) => {
    const row = index + 2;
    let sortName = item[titleRow.indexOf('Sortname')];

    if (sortName?.trim() === '') {
      addWarning({
        warnings,
        sortName,
        row,
        message: 'There is no "Sortname" detected, this is a required field',
      });
    }

    // eslint-disable-next-line no-control-regex
    const foreign = /[^\u0000-\u007f]/;
    if (foreign.test(sortName)) {
      addWarning({
        warnings,
        sortName,
        row,
        message: `Sortname ${sortName} includes foriegn characters that are not able to be saved. Please remove any foreign characters`,
      });
    }

    const warningsProps = {
      warnings,
      sortName,
      item,
      titleRow,
      row,
    };

    checkForDuplicates({
      sortNameColumn,
      externalClientIdColumn,
      existingSortNames,
      existingExternalClientIds,
      ...warningsProps,
    });

    checkBadEntityInfo({
      entityTypes,
      ...warningsProps,
    });

    checkValidStaff({
      staffMembers,
      ...warningsProps,
    });

    checkTaxEntries({
      ...warningsProps,
    });

    checkDates({
      ...warningsProps,
    });

    checkCharacterLimits({
      ...warningsProps,
    });
  });

  return warnings;
};
