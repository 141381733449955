/** @jsx jsx */
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useState } from 'react';

import { jsx } from '@reckon-web/core';
import { Stack } from '@reckon-web/stack';
import { Text } from '@reckon-web/text';
import { Field } from '@reckon-web/field';
import { SelectInput } from '@reckon-web/select-input';
import { Flex } from '@reckon-web/flex';
import { Heading } from '@reckon-web/heading';
import { Divider } from '@reckon-web/divider';
import { useToasts } from '@reckon-web/toast';
import { Box } from '@reckon-web/box';
import { Columns } from '@reckon-web/columns';

import { titleColumns } from '../functions/initialValidation';
import { arrayUnique } from '../../../utilities/helpers/objects';
import { CSV } from '../types';

export const MapTitleColumns = ({
  csv,
  setCsv,
  shouldUpdateCsvTitle,
  setShouldUpdateCsvTitle,
}: {
  csv: CSV;
  setCsv: Dispatch<SetStateAction<CSV>>;
  shouldUpdateCsvTitle: boolean;
  setShouldUpdateCsvTitle: Dispatch<SetStateAction<boolean>>;
}) => {
  const { addToast } = useToasts();
  const { titleRow } = csv;

  const [mappedTitles, setMappedTitles] = useState(
    titleRow.map((title) => {
      if (titleColumns.includes(title)) return title;
      return '';
    })
  );

  useEffect(() => {
    if (shouldUpdateCsvTitle) {
      setShouldUpdateCsvTitle(false);
      const newTitles = arrayUnique([
        ...mappedTitles,
        'Sortname',
        'EntityType',
        'FirstName',
        'LastName',
        'RegName',
      ]);

      const newCsv = { ...csv, titleRow: newTitles };
      setCsv(newCsv);
    }
  }, [
    mappedTitles,
    csv,
    setCsv,
    setShouldUpdateCsvTitle,
    shouldUpdateCsvTitle,
  ]);

  return (
    <Stack gap="medium">
      <Box width={700}>
        <Text color="muted">
          <br />
          The minimum required fields for Contacts+ are; <br />
          &nbsp;&nbsp; • Sortname <br />
          &nbsp;&nbsp; • EntityType <br />
          &nbsp;&nbsp; • FirstName & LastName (for invididual entitytypes){' '}
          <br />
          &nbsp;&nbsp; • RegName (for organisation entitytypes)
          <br />
          <br />
          If you don't have a column to map to these required fields, they will
          be added automatically and you can assign data to the fields in the
          next step
          <br />
          <br />
        </Text>
      </Box>
      <Columns>
        <Heading level="3">Imported CSV column headers</Heading>
        <Heading level="3">Contacts+ column headers</Heading>
      </Columns>
      <Divider />
      <Columns>
        <Stack>
          {titleRow.map((title, idx) => (
            <Flex
              key={title}
              height={50}
              padding="medium"
              alignItems="center"
              background={idx % 2 === 0 ? 'muted' : 'base'}
            >
              <Text>{title}</Text>
            </Flex>
          ))}
        </Stack>
        <Stack>
          {mappedTitles.map((title, titleIdx) => (
            <Flex
              key={title}
              height={50}
              padding="medium"
              alignItems="center"
              background={
                title
                  ? titleIdx % 2 === 0
                    ? 'positive'
                    : 'positiveMuted'
                  : titleIdx % 2 === 0
                  ? 'muted'
                  : 'base'
              }
            >
              <Box width={400}>
                <Field label="Map CSV columns" labelVisible={false}>
                  <SelectInput
                    size="small"
                    placeholder="Map csv columns"
                    onChange={(value) => {
                      if (mappedTitles.includes(value))
                        return addToast({
                          title: `${value} already assigned to another column `,
                          tone: 'critical',
                        });
                      let updatedTitles = mappedTitles.map((i, idx) => {
                        if (titleIdx === idx) return value || '';
                        return i || '';
                      });
                      setMappedTitles(updatedTitles);
                    }}
                    value={title}
                    options={titleColumns.map((i) => {
                      return { value: i, label: i };
                    })}
                  />
                </Field>
              </Box>
            </Flex>
          ))}
        </Stack>
      </Columns>
    </Stack>
  );
};
