/** @jsx jsx */
import { createContext, useContext, useState } from 'react';
import { useQuery } from '@ts-gql/apollo';
import { DropzoneRootProps } from 'react-dropzone';

import { jsx } from '@reckon-web/core';
import { Button } from '@reckon-web/button';
import { AlertDialog, ContentDialog } from '@reckon-web/modal-dialog';
import { Box } from '@reckon-web/box';
import { Heading } from '@reckon-web/heading';
import { Text } from '@reckon-web/text';
import { Flex } from '@reckon-web/flex';
import { UserAvatar } from '@reckon-web/avatar';
import { Divider } from '@reckon-web/divider';
import { useTheme } from '@reckon-web/theme';
import { Stack } from '@reckon-web/stack';

import { Dropzone } from '../Dropzone';
import { ImportClientContext } from '../ImportClientDialogContainer';
import { ENTITY_TYPE } from '../../client/createEditClientDrawer/CreateEditClientDrawerContainer';
import { STAFF_QUERY } from '../../client/clientRightColumn/management/EditManagementGroupDrawer';
import { EntityType, StaffMember } from '../types';

import { MappingSpreadheet } from './MappingSpreadSheet';
import { MapTitleColumns } from './MapTitleColumns';

type MappingDataContext = {
  entityTypes: EntityType[] | undefined | any;
  staffMembers: StaffMember[] | undefined;
};

export const mappingDataContext = createContext<MappingDataContext>({
  entityTypes: undefined,
  staffMembers: undefined,
});

type MapCsvDataDialogProps = {
  dropZoneProps: DropzoneRootProps;
};

export const MapCsvDataDialog = ({ dropZoneProps }: MapCsvDataDialogProps) => {
  const { palette } = useTheme();
  const {
    isMapCsvDialogOpen,
    setIsMapCsvDialogOpen,
    csv,
    setCsv,
    setFile,
  } = useContext(ImportClientContext);

  const [step, setStep] = useState(1);
  const [shouldUpdateCsvTitle, setShouldUpdateCsvTitle] = useState(false);
  const [handleFinalisingCsv, setHandleFinalisingCsv] = useState<
    string | undefined
  >();
  const [shouldDownloadCheck, setShouldDownloadCheck] = useState(false);

  const { data: fetchedEntityTypes } = useQuery(ENTITY_TYPE);

  const entityTypes = fetchedEntityTypes?.entityTypes && [
    ...fetchedEntityTypes?.entityTypes,
  ];

  const { data: fetchedStaff } = useQuery(STAFF_QUERY);
  let staffMembers = fetchedStaff?.users && [...fetchedStaff?.users];

  return (
    <mappingDataContext.Provider
      value={{
        entityTypes,
        staffMembers,
      }}
    >
      <ContentDialog
        isOpen={isMapCsvDialogOpen}
        onClose={() => ''}
        size="full"
        css={{ color: '#20262D' }} // FIXME: avoid `css` prop. If absolutely necessary, document the reasoning.
        header={
          <Box padding="xlarge">
            <Text weight="semibold" size="xsmall" color="dim">
              IMPORT CLIENTS
            </Text>
            <Heading level="2">Map CSV data for Import</Heading>
          </Box>
        }
        footer={
          <Flex
            justifyContent="space-between"
            alignItems="center"
            paddingX="medium"
          >
            {step === 2 && (
              <Button
                label="Download csv"
                weight="none"
                onClick={() => {
                  setHandleFinalisingCsv('downloadStart');
                }}
              />
            )}
            <div />
            <Flex justifyContent="flex-end" gap="small" padding="large">
              <Button
                label="Cancel"
                tone="passive"
                weight="none"
                onClick={() => {
                  setCsv(undefined);
                  setFile({ status: 'none', meta: null });
                  setIsMapCsvDialogOpen(false);
                }}
              />
              {step === 1 ? (
                <Button
                  label="Continue to next step"
                  disabled={!csv}
                  onClick={() => {
                    setShouldUpdateCsvTitle(true);
                    process.nextTick(() => setStep(2));
                  }}
                />
              ) : (
                <Button
                  label="Done"
                  onClick={() => {
                    setShouldDownloadCheck(true);
                  }}
                />
              )}
            </Flex>
          </Flex>
        }
      >
        <Box padding="xlarge">
          {!csv && <Dropzone {...dropZoneProps} />}
          <Stack gap="large">
            {csv?.records && csv.titleRow && (
              <Flex gap="medium" alignItems="center">
                <UserAvatar
                  name="1"
                  size="small"
                  //@ts-ignore  blue is not a color on the ds types yet*
                  color={palette.text.active}
                  disabled={step === 2}
                />
                <Heading level="6" color={step === 1 ? 'base' : 'dim'}>
                  Map column header names
                </Heading>
                <Box width="100px">
                  <Divider />
                </Box>
                <UserAvatar
                  name="2"
                  size="small"
                  //@ts-ignore  blue is not a color on the ds types yet*
                  color={palette.text.active}
                  disabled={step === 1}
                />
                <Heading level="6" color={step === 2 ? 'base' : 'dim'}>
                  Map CSV data
                </Heading>
              </Flex>
            )}

            {csv?.records && csv.titleRow && step === 1 && (
              <MapTitleColumns
                csv={csv}
                // @ts-ignore
                setCsv={setCsv}
                shouldUpdateCsvTitle={shouldUpdateCsvTitle}
                setShouldUpdateCsvTitle={setShouldUpdateCsvTitle}
              />
            )}
            {csv?.records &&
              csv.titleRow &&
              step === 2 &&
              !shouldUpdateCsvTitle && (
                <MappingSpreadheet
                  csv={csv}
                  setCsv={setCsv}
                  handleFinalisingCsv={handleFinalisingCsv}
                  setHandleFinalisingCsv={setHandleFinalisingCsv}
                />
              )}
          </Stack>
        </Box>
      </ContentDialog>
      <AlertDialog
        actions={{
          confirm: {
            label: 'Yes, download first',
            action: () => {
              setHandleFinalisingCsv('downloadStart');
              process.nextTick(() => {
                setHandleFinalisingCsv('finaliseMapping');
                process.nextTick(() => {
                  setIsMapCsvDialogOpen(false);
                  setShouldDownloadCheck(false);
                });
              });
            },
            loading: handleFinalisingCsv !== undefined,
          },
          cancel: {
            label: 'No, continue without a copy',
            action: () => {
              setHandleFinalisingCsv('finaliseMapping');
              process.nextTick(() => {
                setIsMapCsvDialogOpen(false);
                setShouldDownloadCheck(false);
              });
            },
          },
        }}
        isOpen={shouldDownloadCheck}
        title="Download a copy of your mapped CSV"
      >
        <Text>
          It is always a good idea to download a copy of your mapped csv for
          future imports, or if anything goes wrong during the import process
        </Text>
      </AlertDialog>
    </mappingDataContext.Provider>
  );
};
