/** @jsx jsx */
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { gql } from '@ts-gql/tag';
import { useMutation } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { TabbedDrawer } from '@reckon-web/drawer';
import {
  useForm,
  useIsFormEqualToInitialValue,
  useSubmit,
} from '@reckon-web/forms';
import { useToasts } from '@reckon-web/toast';

import { ClientContext } from '..';
import { useUser } from '../../auth';

import {
  createEditIndividualSchema,
  createEditOrganisationSchema,
  getIndividualInput,
  getInitialValues,
  getOrganisationInput,
  getTabs,
  handleCreate,
  handleUpdate,
} from './utils';

// gql
export const CREATE_CLIENT = gql`
  mutation createClient($createClientInput: CreateClientInput!) {
    createClient(input: $createClientInput) {
      id
      sortName
    }
  }
` as import('../../../../__generated__/ts-gql/createClient').type;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $id: ID!
    $updateClientInput: UpdateClientInput!
    $showSecGroup: Boolean = false
  ) {
    updateClient(id: $id, input: $updateClientInput) {
      id
      ...ClientDetailsPage_client
    }
  }
` as import('../../../../__generated__/ts-gql/updateClient').type;

// types
export type EntityType = {
  id: string;
  name: string;
  type: string;
  __typename: string;
  selectable: boolean;
};

type DrawerProps = {
  isEdit: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isIndividual: boolean;
  entityType: EntityType;
};

// component
export const CreateEditClientDrawer = ({
  isEdit,
  isOpen,
  setIsOpen,
  isIndividual,
  entityType,
}: DrawerProps) => {
  const client = useContext(ClientContext).client;
  const { country = 'AUS' } = useUser().profile;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { addToast } = useToasts();

  const [createClient, { loading: createLoading }] = useMutation(CREATE_CLIENT);
  const [updateClient, { loading: updateLoading }] = useMutation(UPDATE_CLIENT);

  const individualForm = useForm(
    createEditIndividualSchema,
    getInitialValues(client)
  );
  const isIndividualEqualToInit = useIsFormEqualToInitialValue(individualForm);

  const organisationForm = useForm(
    createEditOrganisationSchema,
    getInitialValues(client)
  );
  const isOrganisationEqualToInit = useIsFormEqualToInitialValue(
    organisationForm
  );

  const submitIndividual = useSubmit(individualForm, async (value) => {
    const input = getIndividualInput(isEdit, value, entityType, country);
    if (client?.id) {
      await handleUpdate(updateClient, client, input, addToast, setIsOpen);
    } else {
      await handleCreate(createClient, input, addToast, setIsOpen);
    }
  });
  const submitOrganisation = useSubmit(organisationForm, async (value) => {
    const input = getOrganisationInput(isEdit, value, entityType, country);
    if (client?.id) {
      await handleUpdate(updateClient, client, input, addToast, setIsOpen);
    } else {
      await handleCreate(createClient, input, addToast, setIsOpen);
    }
  });

  const drawerActions = {
    confirm: {
      action: isIndividual ? submitIndividual : submitOrganisation,
      label: isEdit ? 'Save & close' : 'Create record',
      type: 'submit',
      loading: createLoading || updateLoading,
    },
    cancel: {
      action: () => {
        setIsOpen(!isOpen);
      },
      label: 'Discard changes',
    },
  };

  const tabs = getTabs(isIndividual, isEdit, individualForm, organisationForm);

  return (
    <TabbedDrawer
      shouldShowCancelConfirmationDialog={
        isIndividual ? !isIndividualEqualToInit : !isOrganisationEqualToInit
      }
      id="create-edit-client"
      actions={drawerActions}
      tabs={tabs}
      onTabChange={setSelectedTabIndex}
      selectedTabIndex={selectedTabIndex}
      title={isEdit ? `${client?.sortName}` : 'New Client'}
    />
  );
};
