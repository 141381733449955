/** @jsx jsx */
import { Fragment, useCallback, useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import parse from 'csv-parse';

import { jsx } from '@reckon-web/core';
import { Button, IconButton } from '@reckon-web/button';
import { ContentDialog } from '@reckon-web/modal-dialog';
import { Box } from '@reckon-web/box';
import { Heading } from '@reckon-web/heading';
import { Text } from '@reckon-web/text';
import { TextLink } from '@reckon-web/text-link';
import { Flex } from '@reckon-web/flex';
import { Stack } from '@reckon-web/stack';
import { Inline } from '@reckon-web/inline';
import { FileIcon, Trash2Icon } from '@reckon-web/icon';
import { Notice } from '@reckon-web/notice';
import { LoadingDots } from '@reckon-web/loading';
import { useToasts } from '@reckon-web/toast';

import { Dropzone } from './Dropzone';
import { formatBytes } from './utils';
import { parseCsvData } from './functions/parseCSV';
import { BeginImportClient } from './BeginImportClient';
import { ImportWarnings } from './ImportWarnings';
import { MapCsvDataDialog } from './mapCsvData/MapCsvDataDialog';
import { ImportClientContext } from './ImportClientDialogContainer';
import { ImportClientDialogFooter } from './ImportClientDialogFooter';

export const ImportClientDialog = () => {
  const { addToast } = useToasts();

  const {
    isImportClientDialogOpen,
    isMapCsvDialogOpen,
    setIsMapCsvDialogOpen,
    verifyingCsv,
    setVerifyingCsv,
    beginImport,
    importWarnings,
    setImportWarnings,
    importError,
    importDone,
    initialValidationFail,
    setInitialValidationFail,
    csv,
    setCsv,
    file,
    setFile,
  } = useContext(ImportClientContext);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!isMapCsvDialogOpen) setVerifyingCsv(true);
      setImportWarnings([]);
      setInitialValidationFail(false);
      const reader = new FileReader();

      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading failed');
      reader.onload = () => {
        // Parse CSV file
        //@ts-ignore
        parse(reader.result, (err, data) => {
          if (!data)
            return addToast({
              title: 'Unknown Error with file',
              message:
                'An unknown error has occured when uploading the file. Please re save the CSV and try again',
              tone: 'critical',
            });
          const csvInfo = parseCsvData(data);

          setCsv({
            records: csvInfo.records,
            titleRow: csvInfo.titleRow,
            totalClients: csvInfo.records.length,
          });
        });
      };

      // read file contents
      acceptedFiles.forEach((file) => reader.readAsBinaryString(file));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, isMapCsvDialogOpen]
  );

  let {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
    onDrop,
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      setFile({
        status: 'accepted',
        meta: acceptedFiles[0],
      });
    }
    if (fileRejections.length) {
      setFile({
        status: 'rejected',
        meta: fileRejections[0].file,
      });
      setImportWarnings([]);
      setVerifyingCsv(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles.length, fileRejections.length]);

  const dropZoneProps = {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
    verifyingCsv,
  };

  const handleRemoveFile = () => {
    setVerifyingCsv(false);
    setImportWarnings([]);
    setInitialValidationFail(false);
    setCsv(undefined);
    setFile({ status: 'none', meta: null });
  };

  return (
    <ContentDialog
      isOpen={isImportClientDialogOpen}
      onClose={() => undefined} // prevent closing dialog by accidentally clicking outside
      size="medium"
      css={{ color: '#20262D' }} // FIXME: avoid `css` prop. If absolutely necessary, document the reasoning.
      header={
        <Box padding="xlarge">
          <Text weight="semibold" size="xsmall" color="dim">
            IMPORT CLIENTS
          </Text>
          <Heading level="2">
            {!importDone ? 'Upload CSV file' : 'Done!'}
          </Heading>
        </Box>
      }
      footer={
        <ImportClientDialogFooter
          fileRejections={dropZoneProps.fileRejections}
        />
      }
    >
      {/* Opens csv mapping dialog when true */}
      {isMapCsvDialogOpen && <MapCsvDataDialog dropZoneProps={dropZoneProps} />}

      {/* Initial dialog display when no file is chosen yet or if the csv mapping screen overlays*/}
      {file.status === 'none' || isMapCsvDialogOpen ? (
        <Stack padding="xlarge" gap="xlarge">
          <Notice size="small">
            Please get in touch with your APS team if this is the first time
            using this tool
          </Notice>
          <Text color="muted">
            If you have not yet mapped your csv data into the required format it
            is a good idea to first map your data below
          </Text>

          <Button
            label="Click here to map csv data"
            onClick={() => {
              setVerifyingCsv(false);
              setIsMapCsvDialogOpen(true);
            }}
          />

          <Text color="muted">
            Importing a CSV file allows you to add or update clients in APS.
            Existing clients will never be deleted.
          </Text>

          <Dropzone {...dropZoneProps} />

          <Inline gap="xsmall">
            <Text color="muted">If a</Text>
            <Box background="active">
              <Text color="informative">
                <code> sort name </code>
              </Text>
            </Box>
            <Text color="muted">or</Text>
            <Box background="active">
              <Text color="informative">
                <code> external client ID </code>
              </Text>
            </Box>
            <Text color="muted">matches an existing client, their</Text>
            <Text color="muted">
              information will be updated. Otherwise, our importer will create
              new clients.
            </Text>
          </Inline>

          <Inline gap="xsmall" alignY="center">
            <Text color="muted">Download a</Text>
            <TextLink
              href="https://contactsimporttemplate.s3-ap-southeast-2.amazonaws.com/Sample-import-template.csv"
              download
            >
              template file
            </TextLink>
            <Text color="muted">
              for required format. If unsupported fields are
            </Text>
            <Text color="muted">included, they will be ignored.</Text>
          </Inline>
        </Stack>
      ) : (
        <Stack padding="xlarge" gap="xlarge">
          {fileRejections[0] &&
            fileRejections[0].errors[0]?.code === 'file-invalid-type' && (
              <Notice tone="critical">
                Invalid file format. Please upload a CSV file.
              </Notice>
            )}

          {importError && (
            <Notice tone="critical">
              There was an error while importing clients. No clients were
              imported. Please refresh and try again.
            </Notice>
          )}

          {!beginImport ? (
            <Fragment>
              <Text color="muted">Is this the correct file?</Text>

              <Flex
                padding="xlarge"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex gap="large" alignItems="center">
                  <FileIcon size="large" color="dim" />
                  <Stack gap="medium">
                    <Text>{file.meta?.name}</Text>
                    {verifyingCsv ? (
                      <Flex gap="medium" alignItems="center">
                        <Text size="xsmall" color="dim">
                          Validating Csv file, please wait
                        </Text>
                        <LoadingDots label="validating..." size="small" />
                      </Flex>
                    ) : (
                      <Text size="xsmall" color="dim">
                        {file.status === 'rejected'
                          ? formatBytes(file.meta?.size)
                          : csv?.totalClients + ' clients'}
                      </Text>
                    )}
                  </Stack>
                </Flex>
                <IconButton
                  label="Remove"
                  id="IconButton-remove-file"
                  weight="subtle"
                  icon={Trash2Icon}
                  onClick={handleRemoveFile}
                />
              </Flex>
            </Fragment>
          ) : (
            <BeginImportClient />
          )}

          {!importDone && (
            <Fragment>
              {initialValidationFail && (
                <ImportWarnings importWarnings={importWarnings} />
              )}
              <Text color="muted">
                Existing clients will never be deleted. If unsupported fields
                are included, they will be ignored.
              </Text>
            </Fragment>
          )}
        </Stack>
      )}
    </ContentDialog>
  );
};
