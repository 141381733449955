/** @jsx jsx */

import { jsx } from '@reckon-web/core';
import { Box } from '@reckon-web/box';
import { Flex } from '@reckon-web/flex';
import { Text } from '@reckon-web/text';
import { Stack } from '@reckon-web/stack';

import { Client } from '../Client';

import { ClientSecurityGroup } from './clientSecurityGroup/ClientSecurityGroup';
import { EditManagement } from './management/EditManagementGroup';
import { ManagementAvatars } from './management/ManagementAvatars';
import { DebtorAndFamilyGroupTree } from './debtorAndFamilyGroups/DebtorAndFamilyGroupTree';
import { EditDebtorOrFamilyGroup } from './debtorAndFamilyGroups/EditDebtorOrFamilyGroup';

type ClientRightColumnProps = {
  client: Client;
};

export const ClientRightColumn = ({ client }: ClientRightColumnProps) => {
  return (
    <Stack gap="xlarge" paddingTop="xxlarge" minWidth={300}>
      {client?.securityGroup?.name && <ClientSecurityGroup client={client} />}

      {client.debtor?.head && (
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text weight="semibold">DEBTOR GROUP</Text>
            <EditDebtorOrFamilyGroup client={client} type="debtor" />
          </Flex>
          <Box marginY="medium">
            <DebtorAndFamilyGroupTree
              group={client.debtor}
              clientId={client.id}
              treeType="debtor"
            />
          </Box>
        </Box>
      )}

      {client.familyGroup && (
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text weight="semibold">FAMILY GROUP</Text>
            <EditDebtorOrFamilyGroup client={client} type="familygroup" />
          </Flex>
          <Box marginY="medium">
            <DebtorAndFamilyGroupTree
              group={client.familyGroup}
              clientId={client.id}
              treeType="familyGroup"
            />
          </Box>
        </Box>
      )}

      <Stack gap="medium" paddingBottom="xxlarge">
        <Flex justifyContent="space-between" alignItems="center">
          <Text weight="semibold">MANAGEMENT</Text>
          <EditManagement />
        </Flex>
        <ManagementAvatars client={client} />
      </Stack>
    </Stack>
  );
};
