/** @jsx jsx */
import { useContext, useLayoutEffect, useRef } from 'react';

import { jsx } from '@reckon-web/core';
import { HelpCircleIcon } from '@reckon-web/icon';
import { Tooltip } from '@reckon-web/tooltip';

import { mappingValidations } from './mappingValidations';
import { Column, InputComponent } from './types';
import { mappingDataContext } from './MapCsvDataDialog';

const Component = ({
  focus,
  onChange,
  value,
  rowData,
  colKey,
  rowIndex,
}: InputComponent) => {
  const { staffMembers, entityTypes } = useContext(mappingDataContext);
  const ref = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    if (focus) {
      ref.current?.select();
    } else {
      ref.current?.blur();
    }
  }, [focus]);

  const isInvalid = mappingValidations({
    value,
    rowData,
    colKey,
    staffMembers,
    // @ts-ignore
    entityTypes,
  });
  const input = (
    <input
      className="dsg-input"
      ref={ref}
      css={{
        pointerEvents: focus ? 'auto' : 'none',
      }}
      value={value || ''}
      onChange={(e) => onChange(e.target.value || '')}
    />
  );
  return (
    <div
      className="dsg-input"
      css={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isInvalid
          ? rowIndex % 2 === 0
            ? '#FFEAEC'
            : '#FFE0E3'
          : 'transparent',
      }}
    >
      {input}
      {isInvalid && (
        <Tooltip content={isInvalid}>
          <span aria-label="Help">
            <HelpCircleIcon size="small" />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export function textColumn<TRow = any>({
  key,
  ...rest
}: Partial<Column<TRow>> & { key: string }): Partial<Column<TRow>> {
  return {
    render: ({ focus, rowData, setRowData, rowIndex }) => (
      <Component
        // @ts-ignore
        value={rowData[key]}
        focus={focus}
        onChange={(value: any) => setRowData({ ...rowData, [key]: value })}
        rowData={rowData}
        colKey={key}
        rowIndex={rowIndex}
      />
    ),
    deleteValue: ({ rowData }) => ({ ...rowData, [key]: '' }),
    // @ts-ignore
    copyValue: ({ rowData }) => rowData[key],
    pasteValue: ({ rowData, value }) => ({ ...rowData, [key]: value || '' }),
    ...rest,
  };
}
