import { useEffect } from 'react';

export const useDocumentEventListener = (type: string, listener: any) => {
  useEffect(() => {
    document.addEventListener(type, listener);

    return () => {
      document.removeEventListener(type, listener);
    };
  }, [listener, type]);
};
