import { SortedRecord } from '../types';

export function sortIncludesFamAndDebtor(
  sortedRecords: SortedRecord[],
  titleRow: string[]
) {
  return sortedRecords.sort((itemA, itemB) => {
    let rowA = itemA.rowData;
    let rowB = itemB.rowData;
    const a =
      rowA[titleRow.indexOf('FamilyGroup')] &&
      rowA[titleRow.indexOf('Debtor')] === rowA[titleRow.indexOf('Sortname')]
        ? true
        : false;
    const b =
      rowB[titleRow.indexOf('FamilyGroup')] &&
      rowB[titleRow.indexOf('Debtor')] === rowB[titleRow.indexOf('Sortname')]
        ? true
        : false;

    return a === b ? 0 : a ? -1 : 1;
  });
}

export function sortIncludesFam(
  sortedRecords: SortedRecord[],
  titleRow: string[]
) {
  return sortedRecords.sort((itemA, itemB) => {
    let rowA = itemA.rowData;
    let rowB = itemB.rowData;
    const a =
      rowA[titleRow.indexOf('FamilyGroup')] ===
      rowA[titleRow.indexOf('Sortname')]
        ? true
        : false;
    const b =
      rowB[titleRow.indexOf('FamilyGroup')] ===
      rowB[titleRow.indexOf('Sortname')]
        ? true
        : false;

    return a === b ? 0 : a ? -1 : 1;
  });
}

export function sortIncludesDebtor(
  sortedRecords: SortedRecord[],
  titleRow: string[]
) {
  return sortedRecords.sort((itemA, itemB) => {
    let rowA = itemA.rowData;
    let rowB = itemB.rowData;
    const a =
      rowA[titleRow.indexOf('Debtor')] === rowA[titleRow.indexOf('Sortname')]
        ? true
        : false;
    const b =
      rowB[titleRow.indexOf('Debtor')] === rowB[titleRow.indexOf('Sortname')]
        ? true
        : false;

    return a === b ? 0 : a ? -1 : 1;
  });
}
