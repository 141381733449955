/** @jsx jsx */

import { CSSProperties, ReactNode, forwardRef, useMemo } from 'react';

import { buildDataAttributes, jsx } from '@reckon-web/core';
import { useTheme } from '@reckon-web/theme';

import { siftAriaAttributes } from '../utils';

// Component
// ------------------------------

export type TableRowProps = UseRowStylesProps & {
  /** The elements that make up the table row. */
  children: ReactNode;
  /**
   * When only a subset of rows are loaded, for virtualization or pagination,
   * use the aria-rowindex attribute to let assistive technologies know what
   * portions of the content are being displayed, and that all the table's
   * content is not present.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-rowindex
   */
  'aria-rowindex'?: number;
};

export const TableRow = forwardRef<HTMLDivElement, TableRowProps>(
  ({ children, ...props }, ref) => {
    const { css, dataAttributes, style } = useRowStyles(props);
    const ariaAttributes = siftAriaAttributes(props);

    return (
      <div
        ref={ref}
        role="row"
        css={css}
        style={style}
        {...ariaAttributes}
        {...dataAttributes}
      >
        {children}
      </div>
    );
  }
);

// Styles
// ------------------------------

type UseRowStylesProps = {
  /** Sets the interaction status. */
  status?: 'dragging';
  /** Sets the background colour for the row. */
  tone?: 'critical' | 'positive' | 'cautious';
  /**
   * Sets inline [style](https://reactjs.org/docs/dom-elements.html#style) for
   * the element. Only use as a **last resort**.
   */
  UNSAFE_style?: CSSProperties;
};

export function useRowStyles(props: UseRowStylesProps) {
  const { shadow } = useTheme();
  const { status, tone, UNSAFE_style } = props;

  const style = useMemo(() => UNSAFE_style, [UNSAFE_style]);
  const css = useMemo(
    () => [
      {
        cursor: 'default',
        display: 'flex',
      },
      {
        '[data-row-status="dragging"]&': {
          boxShadow: shadow.small,
        },
      },
      // print rules
      {
        [`@media print`]: {
          display: 'table-row',
        } as const,
      },
    ],
    [shadow]
  );
  const dataAttributes = useMemo(
    () => buildDataAttributes({ status, tone }, 'row'),
    [status, tone]
  );

  return { css, dataAttributes, style };
}
