import React, { useCallback, useMemo, useState } from 'react';
import { gql } from '@ts-gql/tag';
// import { useMutation } from '@ts-gql/apollo';

import {
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumnHeader,
  TableHeader,
  TableRow,
  createDefaultSort,
} from '@reckon-web/table';
import { TextLink } from '@reckon-web/text-link';
// import { DropdownMenu, MenuItem } from '@reckon-web/dropdown';
import { VisuallyHidden } from '@reckon-web/a11y';
// import { IconButton } from '@reckon-web/button';
// import { MoreHorizontalIcon } from '@reckon-web/icon';
// import { Stack } from '@reckon-web/stack';
// import { LoadingDots } from '@reckon-web/loading';

// import { ADD_WRITE, READ, WRITE, useAccessLevel } from '../access';
import type { Relationship } from '../../queries/useQueryRelationships';

import { RelationshipFormData } from './addEditRelationshipDrawer';

type PopulatedRelationshipsTableProps = {
  relationships: readonly Relationship[];
  handleAddEditRelationshipDrawerState: (
    newState: RelationshipDrawerState
  ) => void;
  addEditRelationshipDrawerState: RelationshipDrawerState;
};

export type RelationshipDrawerState = {
  isOpen?: boolean;
  relationshipId?: string;
  isPrimaryContact: boolean;
  relationshipData?: RelationshipFormData;
};

export const ARCHIVE_RELATIONSHIP = gql`
  mutation archiveRelationship($id: ID!) {
    archiveRelationship(id: $id)
  }
` as import('../../../__generated__/ts-gql/archiveRelationship').type;

export const PopulatedRelationshipsTable = ({
  relationships,
  handleAddEditRelationshipDrawerState,
  addEditRelationshipDrawerState,
}: PopulatedRelationshipsTableProps) => {
  const sortedRelationships = relationships.map(
    ({
      id,
      type,
      sourceId,
      targetId,
      relationshipType,
      relation,
      primaryContact,
    }) => {
      return {
        id,
        uiType: type,
        uiDescription: relationshipType?.description,
        relationshipTypeId: relationshipType?.id,
        uiRelation: relation?.sortName,
        sourceId,
        targetId,
        destinationId: type === 'Has' ? targetId : sourceId,
        isPrimaryContact: primaryContact,
      };
    }
  ); //flattened relationships sorted by column

  const initialState: SortDescriptor = {
    column: 'description',
    direction: 'descending',
  };
  const [sortDescriptor, setSortDescriptor] = useState(initialState);

  const createSortHandler = useCallback(
    (column) => () => {
      setSortDescriptor((prevSort) => {
        const isActive = column === prevSort?.column;
        const direction =
          isActive && prevSort?.direction === 'ascending'
            ? 'descending'
            : 'ascending';
        return { column, direction };
      });
    },
    []
  );

  const getSortDirection = useCallback(
    (column) => {
      if (sortDescriptor) {
        return sortDescriptor.column === column
          ? sortDescriptor.direction
          : undefined;
      }
    },
    [sortDescriptor]
  );

  const sortedRelationshipData = useMemo(() => {
    if (sortDescriptor) {
      return sortedRelationships.sort(createDefaultSort(sortDescriptor));
    }
    return sortedRelationships;
  }, [sortedRelationships, sortDescriptor]);

  //const [archiveRelationship, { loading: deleteLoading }] = useMutation(
  // const [{ loading: deleteLoading }] = useMutation(ARCHIVE_RELATIONSHIP);

  // if (deleteLoading) {
  //   return (
  //     <Stack gap="small" align="center">
  //       <LoadingDots label="Deleting relationship" />
  //     </Stack>
  //   );
  // }

  return (
    <Table aria-label="Relationships table" density="compact">
      <TableHeader>
        <TableColumnHeader
          width={100}
          onClick={createSortHandler('type')}
          aria-sort={getSortDirection('type')}
        >
          Type
        </TableColumnHeader>
        <TableColumnHeader
          width={140}
          onClick={createSortHandler('description')}
          aria-sort={getSortDirection('description')}
        >
          Description
        </TableColumnHeader>
        <TableColumnHeader
          onClick={createSortHandler('relation')}
          aria-sort={getSortDirection('relation')}
        >
          Relation
        </TableColumnHeader>
        <TableColumnHeader width={60} align="right">
          <VisuallyHidden>Row actions</VisuallyHidden>
        </TableColumnHeader>
      </TableHeader>
      <TableBody>
        {sortedRelationshipData.map((relationshipData) => (
          <TableRow key={relationshipData.id}>
            <TableCell width={100} role="rowheader">
              {relationshipData.uiType}
            </TableCell>
            <TableCell width={140}>{relationshipData.uiDescription}</TableCell>
            <TableCell>
              <TextLink
                href={`${window.location.origin}/client?client=${
                  relationshipData.uiType === 'Has'
                    ? relationshipData.targetId
                    : relationshipData.sourceId
                }`}
              >
                {relationshipData.uiRelation}
              </TextLink>
            </TableCell>
            <TableCell width={60} align="right">
              {/* <DropdownMenu
                trigger={({ triggerProps }) => (
                  <IconButton
                    icon={MoreHorizontalIcon}
                    label="Actions"
                    size="small"
                    tone="passive"
                    weight="none"
                    {...triggerProps}
                  />
                )}
              >
                <MenuItem
                  label="Edit relationship"
                  disabled={accessLevel === READ}
                  onClick={() => {
                    const relation = {
                      label: relationshipData.uiRelation || '',
                      value: relationshipData.destinationId || '',
                    };
                    handleAddEditRelationshipDrawerState({
                      isOpen: !addEditRelationshipDrawerState.isOpen,
                      relationshipId: relationshipData.id,
                      isPrimaryContact: relationshipData.isPrimaryContact,
                      relationshipData: {
                        type: relationshipData.uiType?.toLowerCase() || '',
                        description: relationshipData.relationshipTypeId || '',
                        relation: relation,
                      },
                    });
                  }}
                />
                <MenuItem
                  label="Delete relationship"
                  disabled={
                    accessLevel === READ ||
                    accessLevel === WRITE ||
                    accessLevel === ADD_WRITE
                  }
                  onClick={async () => {
                    const { data } = await archiveRelationship({
                      variables: {
                        id: relationshipData.id,
                      },
                      refetchQueries: ['GetRelationships'],
                    });
                    return data;
                  }}
                />
              </DropdownMenu> */}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
