import React from 'react';
import { FragmentData, gql } from '@ts-gql/tag';

import { Box } from '@reckon-web/box';
import { Stack } from '@reckon-web/stack';
import { DataGroup, DataRow } from '@reckon-web/data-group';

import { useUser } from '../auth';
import { formatDate, monthsObject } from '../../utilities/helpers/dates';

let clientDetailsFragment = gql`
  fragment ClientDetails_client on Client {
    id
    sortName
    externalClientId
    entityType {
      id
      name
    }
    ... on Organisation {
      tradingAs
      registeredName
    }
    ... on Person {
      title
      firstName
      lastName
      middleName
      initials
    }
    mailName
    salutation
    balanceMonth
    dateAdded
    startDate
    endDate
    taxDetails {
      id
      abnNumber
      abnDivisionNumber
      tfnNumber
      acnArbnNumber
      irdNumber
      nzbnNumber
    }
    attributes {
      id
      dateOfBirth
      placeOfBirth
      age
      dateOfDeath
      annualReturnMonth
      ranking
      partner {
        id
        name
      }
      manager {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
` as import('../../../__generated__/ts-gql/ClientDetails_client').type;

export type ClientDetailsType = FragmentData<typeof clientDetailsFragment>;

export function ClientDetails({ client }: { client: ClientDetailsType }) {
  const { country = 'AUS' } = useUser().profile;

  return (
    <Stack gap="xxlarge">
      {client.__typename === 'Person' ? (
        <Box marginTop="medium">
          <DataGroup>
            <DataRow label="Title">{client?.title}</DataRow>
            <DataRow label="First name">{client?.firstName}</DataRow>
            <DataRow label="Middle name">{client?.middleName || '—'}</DataRow>
            <DataRow label="Last name">{client?.lastName}</DataRow>
            <DataRow label="Initials">{client.initials || '—'}</DataRow>
            <DataRow label="Sort name">{client.sortName}</DataRow>
          </DataGroup>
        </Box>
      ) : (
        <Box marginTop="medium">
          <DataGroup>
            <DataRow label="Sort name">{client.sortName}</DataRow>
            <DataRow label="Registered name">{client.registeredName}</DataRow>
            <DataRow label="Trading as">{client.tradingAs || '—'}</DataRow>
          </DataGroup>
        </Box>
      )}

      <Box marginTop="medium">
        <DataGroup>
          <DataRow label="Salutation">{client.salutation || '—'}</DataRow>
          <DataRow label="Mail name">{client.mailName || '—'}</DataRow>
          <DataRow label="Balance month">
            {client.balanceMonth && monthsObject[client.balanceMonth]}
          </DataRow>
        </DataGroup>
      </Box>

      {country === 'AUS' ? (
        <Box marginTop="medium">
          <DataGroup>
            <DataRow label="ABN number">
              {client.taxDetails?.abnNumber || '—'}
            </DataRow>
            <DataRow label="ABN division number">
              {client.taxDetails?.abnDivisionNumber || '—'}
            </DataRow>
            <DataRow label="TFN number">
              {client?.taxDetails?.tfnNumber || '—'}
            </DataRow>
            <DataRow label="ACN/ARBN number">
              {client.taxDetails?.acnArbnNumber || '—'}
            </DataRow>
          </DataGroup>
        </Box>
      ) : (
        <Box marginTop="medium">
          <DataGroup>
            <DataRow label="IRD number">
              {client.taxDetails?.irdNumber || '—'}
            </DataRow>
            <DataRow label="NZBN number">
              {client.taxDetails?.nzbnNumber || '—'}
            </DataRow>
          </DataGroup>
        </Box>
      )}

      <Box marginTop="medium">
        <DataGroup>
          <DataRow label="Added date">{formatDate(client.dateAdded)}</DataRow>
          <DataRow label="Start date">{formatDate(client.startDate)}</DataRow>
          <DataRow label="End date">{formatDate(client.endDate)}</DataRow>
        </DataGroup>
      </Box>
      <Box marginTop="medium">
        <DataGroup>
          <DataRow
            label={
              client.__typename === 'Person'
                ? 'Date of birth'
                : 'Date of incorporation'
            }
          >
            {formatDate(client.attributes?.dateOfBirth)}
          </DataRow>
          <DataRow
            label={
              client.__typename === 'Person'
                ? 'Place of birth'
                : 'Place of incorporation'
            }
          >
            {client.attributes?.placeOfBirth || '—'}
          </DataRow>
          <DataRow label="Age">{client.attributes?.age}</DataRow>
          <DataRow
            label={
              client.__typename === 'Person'
                ? 'Date of death'
                : 'Date of deregistration'
            }
          >
            {formatDate(client.attributes?.dateOfDeath)}
          </DataRow>
          <DataRow label="Annual return month">
            {client.attributes?.annualReturnMonth &&
              monthsObject[client.attributes?.annualReturnMonth]}
          </DataRow>
        </DataGroup>
      </Box>

      {/* <Box marginTop="medium"><DataGroup>
        <DataRow label="Ranking">{client.attributes?.ranking}</DataRow>
        <DataRow label="Fee synergy">{client.attributes?.feeSynergy}</DataRow>
      </DataGroup></Box> */}
    </Stack>
  );
}
