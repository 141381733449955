import { Form, FormValue } from '@reckon-web/forms';

import { organisationSchema, personSchema } from '.';

// PERSON FORM CHANGES

/** generate individual sortName based on inputed firstName, middleName and middleName */
export const generateIndividualSortName = (
  firstName: string,
  middleName: string,
  lastName: string
) => {
  // set if there is a comma
  const delimiter = firstName !== '' && lastName !== '' ? ', ' : '';

  // set if there is middleName
  const hasMiddle = middleName ? ' ' + middleName : '';

  return lastName.toUpperCase() + delimiter + firstName + hasMiddle;
};

export const createIndividualSortName = (
  updatedPerson: FormValue<typeof personSchema>,
  updatedForm: Form<typeof personSchema>
) => {
  const sortName = generateIndividualSortName(
    updatedPerson.firstName,
    updatedPerson.middleName,
    updatedPerson.lastName
  );

  // update the sortName field
  updatedForm.fields.generalDetails.fields.sortName.setState({
    ...updatedForm.state.generalDetails.sortName,
    value: sortName,
  });
};

/** generate individual mailing name based on inputed firstName, middleName and middleName */
export const generateIndividualMailName = (
  firstName: string,
  middleName: string,
  lastName: string,
  title?: string
) => {
  const firstChar = firstName.substring(0, 1).toUpperCase();
  const middleChar = middleName.substring(0, 1).toUpperCase();
  const capitalLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

  // create the mailName
  return `${title && title + ' '}${firstChar}${middleChar} ${capitalLastName}`;
};

export const createIndividualMailName = (
  updatedPerson: FormValue<typeof personSchema>,
  updatedForm: Form<typeof personSchema>
) => {
  // create the mailName
  const mailName = generateIndividualMailName(
    updatedPerson.firstName,
    updatedPerson.middleName,
    updatedPerson.lastName,
    updatedPerson.title
  );

  //update the mailName field
  updatedForm.fields.generalDetails.fields.mailName.setState({
    ...updatedForm.state.generalDetails.mailName,
    value: mailName,
  });
};

/** generate initials based on inputed firstName and middleName */
export const generateInitials = (firstName: string, middleName: string) => {
  return `${firstName.substring(0, 1)}${middleName.substring(0, 1)}`;
};

export const createIndividualInitials = (
  updatedPerson: FormValue<typeof personSchema>,
  updatedForm: Form<typeof personSchema>
) => {
  const initials = generateInitials(
    updatedPerson.firstName,
    updatedPerson.middleName
  );

  //   update the initials field
  updatedForm.fields.initials.setState({
    ...updatedForm.state.initials,
    value: initials,
  });
};

// ORGANISATION FORM CHANGES

/** generate initials based on inputed firstName and middleName */
export const generateOrgSortName = (registeredName: string) =>
  registeredName.toUpperCase();

export const createOrganisationSortName = (
  updatedOrganisation: FormValue<typeof organisationSchema>,
  updatedForm: Form<typeof organisationSchema>
) => {
  const sortName = generateOrgSortName(updatedOrganisation.registeredName);

  // update the sortName field
  updatedForm.fields.generalDetails.fields.sortName.setState({
    ...updatedForm.state.generalDetails.sortName,
    value: sortName,
  });
};

export const createOrganisationMailName = (
  updatedOrganisation: FormValue<typeof organisationSchema>,
  updatedForm: Form<typeof organisationSchema>
) => {
  const mailName = updatedOrganisation.registeredName;

  // update the mailName field
  updatedForm.fields.generalDetails.fields.mailName.setState({
    ...updatedForm.state.generalDetails.mailName,
    value: mailName,
  });
};
