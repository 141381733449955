import { FragmentData } from '@ts-gql/tag';

import { ValidatedFormValue } from '@reckon-web/forms';

import { ClientDetailsFragment } from '../..';

import {
  addressSchema,
  attributes,
  contactDetailSchema,
  createEditIndividualSchema,
  createEditOrganisationSchema,
  generalDetailsSchema,
  managementSchema,
  organisationSchema,
  personSchema,
  taxDetails,
} from './formSchemas';

// ---- FORM TO SERVER ----
const getManagementInput = (
  value: ValidatedFormValue<typeof managementSchema>
) => {
  return {
    ...(value.staff && { staff: { id: value.staff } }),
    ...(value.partner && { partner: { id: value.partner } }),
    ...(value.manager && { manager: { id: value.manager } }),
  };
};

const getAttributesInput = (
  attributesValue: ValidatedFormValue<typeof attributes>,
  managementValue: ValidatedFormValue<typeof managementSchema>
) => {
  return {
    dateOfBirth: attributesValue.dateOfBirth || null,
    placeOfBirth: attributesValue.placeOfBirth,
    ranking: attributesValue.ranking,
    dateOfDeath: attributesValue.dateOfDeath || null,
    annualReturnMonth: attributesValue.annualReturnMonth
      ? parseInt(attributesValue.annualReturnMonth)
      : null,
    // feeSynergy: value.feeSynergy,
    ...getManagementInput(managementValue),
  };
};

const getTaxDetailsInput = (value: ValidatedFormValue<typeof taxDetails>) => {
  return {
    abnDivisionNumber: value.abnDivisionNumber,
    abnNumber: value.abnNumber,
    acnArbnNumber: value.acnArbnNumber,
    irdNumber: value.irdNumber,
    nzbnNumber: value.nzbnNumber,
    tfnNumber: value.tfnNumber,
  };
};

const getPersonInput = (value: ValidatedFormValue<typeof personSchema>) => ({
  firstName: value.firstName,
  lastName: value.lastName,
  middleName: value.middleName,
  title: value.title,
  initials: value.initials,
  // employer: field.text(),
  // positionTitle: field.text(),
});

const getGeneralInput = (
  generalDetailsValue: ValidatedFormValue<typeof generalDetailsSchema>,
  managementValue: ValidatedFormValue<typeof managementSchema>,
  country: TenantCountry
) => {
  return {
    mailName: generalDetailsValue.mailName,
    salutation: generalDetailsValue.salutation,
    sortName: generalDetailsValue.sortName,
    startDate: generalDetailsValue.startDate || null,
    endDate: generalDetailsValue.endDate || null,
    ...(generalDetailsValue.group && {
      group: { id: generalDetailsValue.group },
    }),
    balanceMonth: generalDetailsValue.balanceMonth
      ? parseInt(generalDetailsValue.balanceMonth)
      : country === 'AUS'
      ? 6
      : 3,
    attributes: getAttributesInput(
      generalDetailsValue.attributes,
      managementValue
    ),
    taxDetails: getTaxDetailsInput(generalDetailsValue.taxDetails),
  };
};

const getAddressInput = (value: ValidatedFormValue<typeof addressSchema>) => ({
  line1: value.line1,
  line2: value.line2,
  line3: value.line3,
  city: value.city,
  state: value.state,
  postcode: value.postcode,
  country: value.country,
});

const getContactDetailInput = (
  contactValue: ValidatedFormValue<typeof contactDetailSchema>,
  postalValue: ValidatedFormValue<typeof addressSchema>,
  registeredValue: ValidatedFormValue<typeof addressSchema>,
  streetValue: ValidatedFormValue<typeof addressSchema>
) => ({
  primaryPhone: contactValue.primaryPhone,
  primaryEmail: contactValue.primaryEmail,
  fax: contactValue.fax,
  otherPhone: contactValue.otherPhone,
  otherEmail: contactValue.otherEmail,
  importantContactInfo: contactValue.importantContactInfo,
  website: contactValue.website,
  linkedIn: contactValue.linkedIn,
  postalAddress: getAddressInput(postalValue),
  registeredAddress: getAddressInput(registeredValue),
  streetAddress: getAddressInput(streetValue),
});

const getOrgInput = (value: ValidatedFormValue<typeof organisationSchema>) => ({
  tradingAs: value.tradingAs,
  registeredName: value.registeredName,
});

type EntityType = {
  id: string;
  name: string;
  type: string;
  __typename: string;
  selectable: boolean;
};

type TenantCountry = 'AUS' | 'NZ' | string;

export const getIndividualInput = (
  isEdit: boolean = false,
  value: ValidatedFormValue<typeof createEditIndividualSchema>,
  entityType: EntityType,
  country: TenantCountry
) => {
  return {
    ...(!isEdit && { entityType: { id: entityType.id } }),
    // debtor: ClientUniqueWhereInput,
    // familyGroup: ClientUniqueWhereInput,
    // group: GroupUniqueWhereInput,
    ...getGeneralInput(
      value.personDetails.generalDetails,
      value.management,
      country
    ),
    contactDetail: getContactDetailInput(
      value.contactDetail,
      value.postalAddress,
      value.registeredAddress,
      value.streetAddress
    ),
    person: getPersonInput(value.personDetails),
  };
};

export const getOrganisationInput = (
  isEdit: boolean = false,
  value: ValidatedFormValue<typeof createEditOrganisationSchema>,
  entityType: EntityType,
  country: TenantCountry
) => {
  return {
    ...(!isEdit && { entityType: { id: entityType.id } }),
    // debtor: ClientUniqueWhereInput,
    // familyGroup: ClientUniqueWhereInput,
    // group: GroupUniqueWhereInput,
    ...getGeneralInput(
      value.organisationDetails.generalDetails,
      value.management,
      country
    ),
    contactDetail: getContactDetailInput(
      value.contactDetail,
      value.postalAddress,
      value.registeredAddress,
      value.streetAddress
    ),
    organisation: getOrgInput(value.organisationDetails),
  };
};

// ---- SERVER TO FORM ----
export const getInitialValues = (
  client?: FragmentData<typeof ClientDetailsFragment>
) => {
  if (client?.__typename === 'Person')
    return {
      personDetails: {
        ...(client.title && { title: client?.title }),
        firstName: client?.firstName || null,
        middleName: client?.middleName || null,
        lastName: client?.lastName || null,
        initials: client?.initials || null,
        generalDetails: {
          sortName: client?.sortName || '',
          salutation: client?.salutation || '',
          mailName: client?.mailName || '',
          balanceMonth: client?.balanceMonth?.toString() || '',
          // group ?

          startDate: client?.startDate || null,
          endDate: client?.endDate || null,
          taxDetails: {
            // AU fields
            abnDivisionNumber: client.taxDetails?.abnDivisionNumber || '',
            abnNumber: client.taxDetails?.abnNumber || '',
            acnArbnNumber: client.taxDetails?.acnArbnNumber || '',
            tfnNumber: client.taxDetails?.tfnNumber || '',
            // NZ fields
            irdNumber: client.taxDetails?.irdNumber || '',
            nzbnNumber: client.taxDetails?.nzbnNumber || '',
          },
          attributes: {
            dateOfBirth: client.attributes?.dateOfBirth || null,
            placeOfBirth: client.attributes?.placeOfBirth || '',
            ranking: client.attributes?.ranking || '',
            dateOfDeath: client.attributes?.dateOfDeath || null,
            annualReturnMonth:
              client.attributes?.annualReturnMonth?.toString() || '',
          },
        },
      },
      contactDetail: {
        primaryPhone: client.contactDetail?.primaryPhone || '',
        primaryEmail: client.contactDetail?.primaryEmail || '',
        fax: client.contactDetail?.fax || '',
        otherPhone: client.contactDetail?.otherPhone || '',
        otherEmail: client.contactDetail?.otherEmail || '',
        importantContactInfo: client.contactDetail?.importantContactInfo || '',
        website: client.contactDetail?.website || '',
        // linkedIn: client.contactDetail || '',
      },
      streetAddress: {
        ...(client.contactDetail?.streetAddress?.line1 && {
          line1: client.contactDetail?.streetAddress?.line1,
        }),
        ...(client.contactDetail?.streetAddress?.line2 && {
          line2: client.contactDetail?.streetAddress?.line2,
        }),
        ...(client.contactDetail?.streetAddress?.line3 && {
          line3: client.contactDetail?.streetAddress?.line3,
        }),
        ...(client.contactDetail?.streetAddress?.city && {
          city: client.contactDetail?.streetAddress?.city,
        }),
        ...(client.contactDetail?.streetAddress?.state && {
          state: client.contactDetail?.streetAddress?.state,
        }),
        ...(client.contactDetail?.streetAddress?.postcode && {
          postcode: client.contactDetail?.streetAddress?.postcode,
        }),
        ...(client.contactDetail?.streetAddress?.country && {
          country: client.contactDetail?.streetAddress?.country,
        }),
      },
      postalAddress: {
        ...(client.contactDetail?.postalAddress?.line1 && {
          line1: client.contactDetail?.postalAddress?.line1,
        }),
        ...(client.contactDetail?.postalAddress?.line2 && {
          line2: client.contactDetail?.postalAddress?.line2,
        }),
        ...(client.contactDetail?.postalAddress?.line3 && {
          line3: client.contactDetail?.postalAddress?.line3,
        }),
        ...(client.contactDetail?.postalAddress?.city && {
          city: client.contactDetail?.postalAddress?.city,
        }),
        ...(client.contactDetail?.postalAddress?.state && {
          state: client.contactDetail?.postalAddress?.state,
        }),
        ...(client.contactDetail?.postalAddress?.postcode && {
          postcode: client.contactDetail?.postalAddress?.postcode,
        }),
        ...(client.contactDetail?.postalAddress?.country && {
          country: client.contactDetail?.postalAddress?.country,
        }),
      },
      registeredAddress: {
        ...(client.contactDetail?.registeredAddress?.line1 && {
          line1: client.contactDetail?.registeredAddress?.line1,
        }),
        ...(client.contactDetail?.registeredAddress?.line2 && {
          line2: client.contactDetail?.registeredAddress?.line2,
        }),
        ...(client.contactDetail?.registeredAddress?.line3 && {
          line3: client.contactDetail?.registeredAddress?.line3,
        }),
        ...(client.contactDetail?.registeredAddress?.city && {
          city: client.contactDetail?.registeredAddress?.city,
        }),
        ...(client.contactDetail?.registeredAddress?.state && {
          state: client.contactDetail?.registeredAddress?.state,
        }),
        ...(client.contactDetail?.registeredAddress?.postcode && {
          postcode: client.contactDetail?.registeredAddress?.postcode,
        }),
        ...(client.contactDetail?.registeredAddress?.country && {
          country: client.contactDetail?.registeredAddress?.country,
        }),
      },
      management: {
        partner: client.attributes?.partner?.id || null,
        manager: client.attributes?.manager?.id || null,
        staff: client.attributes?.manager?.id || null,
      },
    };
  if (client?.__typename === 'Organisation')
    return {
      organisationDetails: {
        tradingAs: client.tradingAs || '',
        registeredName: client.registeredName || '',
        generalDetails: {
          sortName: client?.sortName || '',
          mailName: client?.mailName || '',
          balanceMonth: client?.balanceMonth?.toString() || '',
          salutation: client?.salutation || '',
          // group:

          startDate: client?.startDate || null,
          endDate: client?.endDate || null,
          taxDetails: {
            // AU fields
            abnDivisionNumber: client.taxDetails?.abnDivisionNumber || '',
            abnNumber: client.taxDetails?.abnNumber || '',
            acnArbnNumber: client.taxDetails?.acnArbnNumber || '',
            tfnNumber: client.taxDetails?.tfnNumber || '',
            // NZ fields
            irdNumber: client.taxDetails?.irdNumber || '',
            nzbnNumber: client.taxDetails?.nzbnNumber || '',
          },
          attributes: {
            dateOfBirth: client.attributes?.dateOfBirth || null,
            placeOfBirth: client.attributes?.placeOfBirth || '',
            ranking: client.attributes?.ranking || '',
            dateOfDeath: client.attributes?.dateOfDeath || null,
            annualReturnMonth:
              client.attributes?.annualReturnMonth?.toString() || '',
          },
        },
      },
      contactDetail: {
        primaryPhone: client.contactDetail?.primaryPhone || '',
        primaryEmail: client.contactDetail?.primaryEmail || '',
        fax: client.contactDetail?.fax || '',
        otherPhone: client.contactDetail?.otherPhone || '',
        otherEmail: client.contactDetail?.otherEmail || '',
        importantContactInfo: client.contactDetail?.importantContactInfo || '',
        website: client.contactDetail?.website || '',
        // linkedIn: client.contactDetail || '',
      },
      streetAddress: {
        ...(client.contactDetail?.streetAddress?.line1 && {
          line1: client.contactDetail?.streetAddress?.line1,
        }),
        ...(client.contactDetail?.streetAddress?.line2 && {
          line2: client.contactDetail?.streetAddress?.line2,
        }),
        ...(client.contactDetail?.streetAddress?.line3 && {
          line3: client.contactDetail?.streetAddress?.line3,
        }),
        ...(client.contactDetail?.streetAddress?.city && {
          city: client.contactDetail?.streetAddress?.city,
        }),
        ...(client.contactDetail?.streetAddress?.state && {
          state: client.contactDetail?.streetAddress?.state,
        }),
        ...(client.contactDetail?.streetAddress?.postcode && {
          postcode: client.contactDetail?.streetAddress?.postcode,
        }),
        ...(client.contactDetail?.streetAddress?.country && {
          country: client.contactDetail?.streetAddress?.country,
        }),
      },
      postalAddress: {
        ...(client.contactDetail?.postalAddress?.line1 && {
          line1: client.contactDetail?.postalAddress?.line1,
        }),
        ...(client.contactDetail?.postalAddress?.line2 && {
          line2: client.contactDetail?.postalAddress?.line2,
        }),
        ...(client.contactDetail?.postalAddress?.line3 && {
          line3: client.contactDetail?.postalAddress?.line3,
        }),
        ...(client.contactDetail?.postalAddress?.city && {
          city: client.contactDetail?.postalAddress?.city,
        }),
        ...(client.contactDetail?.postalAddress?.state && {
          state: client.contactDetail?.postalAddress?.state,
        }),
        ...(client.contactDetail?.postalAddress?.postcode && {
          postcode: client.contactDetail?.postalAddress?.postcode,
        }),
        ...(client.contactDetail?.postalAddress?.country && {
          country: client.contactDetail?.postalAddress?.country,
        }),
      },
      registeredAddress: {
        ...(client.contactDetail?.registeredAddress?.line1 && {
          line1: client.contactDetail?.registeredAddress?.line1,
        }),
        ...(client.contactDetail?.registeredAddress?.line2 && {
          line2: client.contactDetail?.registeredAddress?.line2,
        }),
        ...(client.contactDetail?.registeredAddress?.line3 && {
          line3: client.contactDetail?.registeredAddress?.line3,
        }),
        ...(client.contactDetail?.registeredAddress?.city && {
          city: client.contactDetail?.registeredAddress?.city,
        }),
        ...(client.contactDetail?.registeredAddress?.state && {
          state: client.contactDetail?.registeredAddress?.state,
        }),
        ...(client.contactDetail?.registeredAddress?.postcode && {
          postcode: client.contactDetail?.registeredAddress?.postcode,
        }),
        ...(client.contactDetail?.registeredAddress?.country && {
          country: client.contactDetail?.registeredAddress?.country,
        }),
      },
      management: {
        partner: client.attributes?.partner?.id || null,
        manager: client.attributes?.manager?.id || null,
        staff: client.attributes?.manager?.id || null,
      },
    };
};
