import { ApolloError } from '@apollo/client';

import { useToasts } from '@reckon-web/toast';

// may have multiple queries in one component, thus multiple GQL errors
type Error = ApolloError | (ApolloError | undefined)[] | undefined;

const genericError =
  'Opps something went wrong, error has been logged. Please contact customer support';

export const useError = () => {
  const { addToast } = useToasts();

  const handleError = ({
    title,
    error,
    message,
    tone = 'critical',
  }: {
    title?: string;
    error?: Error;
    message?: string;
    tone?: 'critical' | 'positive' | 'warning' | 'informative' | undefined;
  }) => {
    // if multiple Apollo errors
    if (error instanceof Array) {
      error.map((err) => {
        if (err !== undefined) {
          return addToast({
            tone,
            title: title || err.name || 'Error',
            message: err?.message || genericError,
          });
        }
        return err;
      });
      // if just one Apollo error
    } else if (error?.message) {
      addToast({
        tone,
        title: title || error.name || 'Error',
        message: error?.message || genericError,
      });
      // if no Apollo error either expected response, then log data from query/mutation
    } else {
      addToast({
        tone: 'warning',
        title: `Something's not right`,
        message: message || genericError,
      });
    }
  };

  return handleError;
};
