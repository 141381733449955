import React, { ForwardedRef, forwardRef } from 'react';
import dedent from 'dedent';

import { Field, FieldProps } from '@reckon-web/field';
import { SelectInput, SelectInputProps } from '@reckon-web/select-input';

export type SelectFieldProps<Value extends string | number> = SelectInputProps<
  Value
> &
  FieldProps;

/** @deprecated `SelectField` has been deprecated. Use [Field](https://balance.reckon.com/package/field) and [SelectInput](https://balance.reckon.com/package/select-input) instead. */
const SelectFieldInner = <Value extends string | number>(
  {
    description,
    helpText,
    id,
    invalidMessage,
    label,
    labelVisible,
    reserveMessageSpace,
    ...inputProps
  }: SelectFieldProps<Value>,
  ref: ForwardedRef<HTMLSelectElement> | undefined
) => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      dedent`
      The "SelectField" component has been deprecated and will be removed in a future version. Use "Field" and "SelectInput" instead.
      %c
      - <SelectField
      -   label="Field label"
      -   onChange={handleInputChange}
      -   value={inputValue}
      - />
      %c
      + <Field label="Field label">
      +   <SelectInput
      +     onChange={handleInputChange}
      +     value={inputValue}
      +   />
      + </Field>
    `,
      'color: red',
      'color: green'
    );
  }

  return (
    <Field
      description={description}
      helpText={helpText}
      id={id}
      invalidMessage={invalidMessage}
      label={label}
      labelVisible={labelVisible}
      reserveMessageSpace={reserveMessageSpace}
    >
      <SelectInput {...inputProps} ref={ref} />
    </Field>
  );
};

export const SelectField = forwardRef(SelectFieldInner) as <
  Value extends string | number
>(
  props: SelectFieldProps<Value> & {
    ref?: ForwardedRef<HTMLSelectElement>;
  }
) => ReturnType<typeof SelectFieldInner>;
