import * as React from 'react';
import { FragmentData, gql } from '@ts-gql/tag';

import { Text } from '@reckon-web/text';
import { TextLink } from '@reckon-web/text-link';
import { Box } from '@reckon-web/box';
import { ArrowLeftIcon } from '@reckon-web/icon/icons/ArrowLeftIcon';
import { Flex } from '@reckon-web/flex';
import { EntityAvatar } from '@reckon-web/avatar';
import { Heading } from '@reckon-web/heading';
import { Tag } from '@reckon-web/tag';
import { IconButton } from '@reckon-web/button';
import { NextLink } from '@reckon-web/next-link';

import { getClientName } from '../../utilities/dsUtils';
import { convertWordsToNumbers } from '../../utilities/helpers/conversions';

import { CreateEditClientDrawerContainer } from './createEditClientDrawer/CreateEditClientDrawerContainer';

let fragment = gql`
  fragment ClientHeader_client on Client {
    ...ClientDetailsPage_client
  }
` as import('../../../__generated__/ts-gql/ClientHeader_client').type;

type ClientHeaderProps = {
  client: FragmentData<typeof fragment>;
};

const commonEntityTypes = {
  Trust: 0,
  Company: 1,
  Individual: 2,
  Partnership: 3,
  Club: 4,
} as const;

export default function ClientHeader({ client }: ClientHeaderProps) {
  const entityName = client.entityType.name as keyof typeof commonEntityTypes;

  const entityTagNumber =
    commonEntityTypes[entityName] || convertWordsToNumbers(entityName, 4);

  return (
    <Flex
      className="flex"
      alignItems="center"
      justifyContent="space-between"
      marginY="medium"
      wrap
    >
      <Flex className="client-head-left" alignItems="center" gap="medium">
        <IconButton
          label="Back"
          as={NextLink}
          id="ClientHeader-IconButton-back"
          href="/"
          weight="subtle"
          size="small"
          icon={ArrowLeftIcon}
        />

        <EntityAvatar
          type={client.__typename}
          name={getClientName(client) || ''}
        />

        <Box paddingX="medium">
          <Flex alignItems="center">
            <Heading level="1">{client.sortName}</Heading>
            <Box marginX="xlarge">
              <Tag label={entityName} variant={entityTagNumber} />
            </Box>
          </Flex>
          <Flex alignItems="center">
            {client?.contactDetail?.primaryEmail && (
              <Box marginRight="medium">
                <Text color="muted" size="small">
                  <TextLink
                    href={`mailto:${client.contactDetail?.primaryEmail}`}
                  >
                    {client?.contactDetail?.primaryEmail}
                  </TextLink>
                </Text>
              </Box>
            )}
            {client?.contactDetail?.primaryPhone && (
              <Box>
                <Text color="muted" size="small">
                  <TextLink href={`tel:${client.contactDetail?.primaryPhone}`}>
                    {client?.contactDetail?.primaryPhone}
                  </TextLink>
                </Text>
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>

      <Flex
        className="client-head-right"
        alignItems="center"
        marginY="small"
        paddingX="medium"
      >
        <CreateEditClientDrawerContainer isEdit={true} />
      </Flex>
    </Flex>
  );
}
