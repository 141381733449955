import React from 'react';
import { FragmentData } from '@ts-gql/tag';

import { Box } from '@reckon-web/box';
import { Flex } from '@reckon-web/flex';
import { Text } from '@reckon-web/text';
import { Instance } from '@reckon-web/avatar';
import { StarIcon } from '@reckon-web/icon';

import { ClientDetailsFragment } from '../..';

import { EditSecurityGroup } from './EditClientSecurityGroup';

export type Client = FragmentData<typeof ClientDetailsFragment>;

export function ClientSecurityGroup({ client }: { client: Client }) {
  const securityGroup = client.securityGroup;

  if (!securityGroup) {
    return null;
  }

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text weight="semibold">SECURITY GROUP</Text>
        <EditSecurityGroup client={client} securityGroup={securityGroup} />
      </Flex>
      <Flex marginY="medium" alignItems="center" gap="medium">
        <Instance icon={StarIcon} size="small" name={securityGroup.name} />
        <Text weight="semibold">{securityGroup.name}</Text>
      </Flex>
    </Box>
  );
}
