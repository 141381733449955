/** @jsx jsx */
import { useState } from 'react';

import { jsx } from '@reckon-web/core';
import { Heading } from '@reckon-web/heading';
import { Box } from '@reckon-web/box';
import { Stack } from '@reckon-web/stack';
import { Divider } from '@reckon-web/divider';
import { Form } from '@reckon-web/forms';
import { Checkbox } from '@reckon-web/checkbox';
import { Text } from '@reckon-web/text';

import { personSchema } from '../utils';
import {
  createIndividualInitials,
  createIndividualMailName,
  createIndividualSortName,
  generateInitials,
} from '../utils/generateformfields';
import { TextField } from '../../../design-system/text-field';

import { GeneralDetailsForm } from './GeneralDetailsForm';

// component
export const IndividualForm = ({
  isEdit = false,
  form,
}: {
  isEdit?: boolean;
  form: Form<typeof personSchema>;
}) => {
  const [autoInitials, setAutoInitials] = useState(isEdit ? false : true);
  const [autoMailName, setAutoMailName] = useState(isEdit ? false : true);
  const [autoSortName, setAutoSortName] = useState(isEdit ? false : true);

  const generateFormFields = (
    name: keyof typeof form.fields,
    value: string
  ) => {
    const updatedPerson = { ...form.value, [name]: value };

    autoSortName && createIndividualSortName(updatedPerson, form);
    autoMailName && createIndividualMailName(updatedPerson, form);
    autoInitials && createIndividualInitials(updatedPerson, form);

    // update the firstname/lastname field
    form.setState({
      [name]: { ...form.state[name], value },
    });
  };

  return (
    <Stack marginY="medium" gap="large">
      <Heading level="3">General Details</Heading>
      <Stack marginY="medium" gap="large">
        <TextField
          {...form.fields.title.props}
          autoComplete="off"
          id="Title"
          label="Title"
          placeholder="Enter your title"
          onChange={(e) => generateFormFields('title', e.target.value)}
        />
        <TextField
          {...form.fields.firstName.props}
          autoComplete="off"
          id="First-name"
          label="First name*"
          placeholder="Enter first name"
          onChange={(e) => generateFormFields('firstName', e.target.value)}
        />
        <TextField
          {...form.fields.middleName.props}
          autoComplete="off"
          id="Middle-name"
          label="Middle name"
          placeholder="Enter middle name"
          onChange={(e) => generateFormFields('middleName', e.target.value)}
        />
        <TextField
          {...form.fields.lastName.props}
          autoComplete="off"
          id="Last-name"
          label="Last name*"
          placeholder="Enter last name"
          onChange={(e) => generateFormFields('lastName', e.target.value)}
        />
        <Box marginY="medium">
          <Divider />
        </Box>

        <Stack gap="xsmall">
          <TextField
            {...form.fields.initials.props}
            autoComplete="off"
            id="Initials"
            label="Initials"
            placeholder="Enter initials"
          />
          <Checkbox
            checked={autoInitials}
            onChange={() => {
              if (!autoInitials) {
                const newInitials = generateInitials(
                  form.fields.firstName.value,
                  form.fields.middleName.value
                );
                form.fields.initials.setState({
                  value: newInitials,
                  touched: true,
                });
              }
              setAutoInitials(!autoInitials);
            }}
          >
            <Text color="dim" size="small">
              Generate initials from first and middle name
            </Text>
          </Checkbox>
        </Stack>

        <GeneralDetailsForm
          isEdit={isEdit}
          form={form.fields.generalDetails}
          autoMailName={{ autoMailName, setAutoMailName }}
          autoSortName={{ autoSortName, setAutoSortName }}
          names={{
            firstName: form.fields.firstName.value,
            middleName: form.fields.middleName.value,
            lastName: form.fields.lastName.value,
            title: form.fields.title.value,
          }}
          entityType="Person"
        />
      </Stack>
    </Stack>
  );
};
