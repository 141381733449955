// ts-gql-integrity:96086dc48b37dc7b3c6848f86c21cc94
/*
ts-gql-meta-begin
{
  "hash": "73eb9e7fec29fbeb4fad86d72c755046"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type StaffQueryVariables = {};


type StaffQuery = (
  { readonly __typename: 'Query' }
  & { readonly users: ReadonlyArray<(
    { readonly __typename: 'User' }
    & Pick<SchemaTypes.User, 'id' | 'name' | 'isPartner' | 'isManager'>
  )> }
);


export type type = TypedDocumentNode<{
  type: "query";
  result: StaffQuery;
  variables: StaffQueryVariables;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    Staff: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"Staff\"},\"variableDefinitions\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"users\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"limit\"},\"value\":{\"kind\":\"IntValue\",\"value\":\"1000\"}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"skip\"},\"value\":{\"kind\":\"IntValue\",\"value\":\"0\"}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"isPartner\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"isManager\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
