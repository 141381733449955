import { field, validate } from '@reckon-web/forms';

import {
  validateABN,
  validateCharacterCount,
  validateEmail,
  validateIRD,
  validateNZBN,
  validatePhone,
  validateTFN,
} from '../../../../utilities/validators';

const entityType = field.object({
  id: field.select()(),
});

export const addressSchema = field.object({
  line1: field.text(),
  line2: field.text(),
  line3: field.text(),
  city: field.text(),
  state: field.select()(),
  postcode: field.text(),
  country: field.text(),
  countryLookup: field.select()(),
});

export const managementSchema = field.object({
  partner: field.select()(),
  manager: field.select()(),
  staff: field.select()(),
});

export const contactDetailSchema = field.object({
  primaryPhone: field.text({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validatePhone(value))
        return validate.error('Must be a valid phone number');
      return value;
    }),
  }),
  primaryEmail: field.email({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validateEmail(value)) return validate.error('Must be a valid email');
      return value;
    }),
  }),
  fax: field.text(),
  otherPhone: field.text({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validatePhone(value))
        return validate.error('Must be a valid phone number');
      return value;
    }),
  }),
  otherEmail: field.email({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validateEmail(value)) return validate.error('Must be a valid email');
      return value;
    }),
  }),
  importantContactInfo: field.text(),
  website: field.text(),
  linkedIn: field.text(),
});

export const attributes = field.object({
  dateOfBirth: field.date(),
  placeOfBirth: field.text(),
  ranking: field.text(),
  dateOfDeath: field.date(),
  annualReturnMonth: field.select()(),
  // feeSynergy: field.text(),
});

export const taxDetails = field.object({
  // AU fields
  abnDivisionNumber: field.text(),
  abnNumber: field.text({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validateABN(value))
        return validate.error(
          'Must be a valid ABN number, remove any spaces or dashes '
        );
      return value;
    }),
  }),
  acnArbnNumber: field.text({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validateCharacterCount(value, 11))
        return validate.error(
          'The character limit on this field is 11 characters'
        );
      return value;
    }),
  }),
  tfnNumber: field.text({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validateTFN(value))
        return validate.error('Must be a valid TFN number');
      return value;
    }),
  }),
  // NZ fields
  irdNumber: field.text({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validateIRD(value))
        return validate.error('Must be a valid IRD number');
      return value;
    }),
  }),
  nzbnNumber: field.text({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validateNZBN(value))
        return validate.error('Must be a valid NZBN number');
      return value;
    }),
  }),
});

export const generalDetailsSchema = field.object({
  sortName: field.text({
    validate: validate((value) => {
      validate.required(value);
      return value;
    }),
  }),
  salutation: field.text(),
  mailName: field.text(),
  balanceMonth: field.select()(),

  group: field.select()(),

  startDate: field.date(),
  endDate: field.date(),
  taxDetails,
  attributes,
});

export const personSchema = field.object({
  title: field.text({
    validate: validate((value) => {
      if (value === '') return value;
      if (!validateCharacterCount(value, 15))
        return validate.error(
          'The character limit on this field is 15 characters'
        );
      return value;
    }),
  }),
  firstName: field.text({
    validate: validate((value) => {
      validate.required(value);
      return value;
    }),
  }),
  middleName: field.text(),
  lastName: field.text(
    field.text({
      validate: validate((value) => {
        validate.required(value);
        return value;
      }),
    })
  ),

  initials: field.text(),
  // employer: field.text(),
  // positionTitle: field.text(),
  generalDetails: generalDetailsSchema,
});

export const organisationSchema = field.object({
  tradingAs: field.text(),
  registeredName: field.text(
    field.text({
      validate: validate((value) => {
        validate.required(value);
        return value;
      }),
    })
  ),
  generalDetails: generalDetailsSchema,
});

export const createEditIndividualSchema = field.object({
  entityType,
  personDetails: personSchema,
  contactDetail: contactDetailSchema,
  streetAddress: addressSchema,
  postalAddress: addressSchema,
  registeredAddress: addressSchema,
  management: managementSchema,
});

export const createEditOrganisationSchema = field.object({
  entityType,
  organisationDetails: organisationSchema,
  contactDetail: contactDetailSchema,
  streetAddress: addressSchema,
  postalAddress: addressSchema,
  registeredAddress: addressSchema,
  management: managementSchema,
});
