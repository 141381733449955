import React from 'react';
import dedent from 'dedent';

import { Field, FieldProps } from '@reckon-web/field';
import { DateRangePicker, DateRangePickerProps } from '@reckon-web/date-picker';

type DateRangePickerFieldProps = DateRangePickerProps & FieldProps;

/** @deprecated `DateRangePickerField` has been deprecated. Use [Field](https://balance.reckon.com/package/field) and [DateRangePicker](https://balance.reckon.com/package/date-picker) instead. */
export const DateRangePickerField = ({
  description,
  helpText,
  id,
  invalidMessage,
  label,
  labelVisible,
  reserveMessageSpace,
  ...inputProps
}: DateRangePickerFieldProps) => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      dedent`
    The "DateRangePickerField" component has been deprecated and will be removed in a future version. Use "Field" and "DateRangePicker" instead.
    %c
    - <DateRangePickerField
    -   label="Field label"
    -   onChange={handleInputChange}
    -   value={inputValue}
    - />
    %c
    + <Field label="Field label">
    +   <DateRangePicker
    +     onChange={handleInputChange}
    +     value={inputValue}
    +   />
    + </Field>
  `,
      'color: red',
      'color: green'
    );
  }

  return (
    <Field
      description={description}
      helpText={helpText}
      id={id}
      invalidMessage={invalidMessage}
      label={label}
      labelVisible={labelVisible}
      reserveMessageSpace={reserveMessageSpace}
    >
      <DateRangePicker {...inputProps} />
    </Field>
  );
};
