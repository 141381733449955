/** @jsx jsx */
import { gql } from '@ts-gql/tag';
import { useQuery } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { Heading } from '@reckon-web/heading';
import { Stack } from '@reckon-web/stack';
import { Box } from '@reckon-web/box';
import { Form } from '@reckon-web/forms';

import { managementSchema } from '../utils';
import { SelectField } from '../../../design-system/select-field';

// gql
const USERS = gql`
  query users($limit: Int!, $skip: Int!, $where: UserWhereInput) {
    users(limit: $limit, skip: $skip, where: $where) {
      id
      role
      name
      isManager
      isPartner
      isStaff
      accessLevel
    }
  }
` as import('../../../../../__generated__/ts-gql/users').type;

// component
export function ManagementForm({
  form,
}: {
  form: Form<typeof managementSchema>;
}) {
  // const client = useContext(ClientContext).client;
  const { data } = useQuery(USERS, {
    variables: { limit: 100, skip: 0, where: {} },
  });
  const partnerList = data?.users
    .filter((user) => user.isPartner)
    .map((user) => ({ value: user.id, label: user.name }))
    .filter(
      (user): user is { value: string; label: string } => user.value !== null
    );
  const managerList = data?.users
    .filter((user) => user.isManager)
    .map((user) => ({ value: user.id, label: user.name }))
    .filter(
      (user): user is { value: string; label: string } => user.value !== null
    );
  const staffList = data?.users
    .filter((user) => user.isStaff)
    .map((user) => ({ value: user.id, label: user.name }))
    .filter(
      (user): user is { value: string; label: string } => user.value !== null
    );

  return (
    <Box paddingY="large">
      <Heading level="3">Management</Heading>

      <Stack gap="medium">
        <SelectField
          {...form.fields.partner.props}
          id="Partner"
          label="Partner"
          placeholder="Select partner"
          options={partnerList || []}
        />

        <SelectField
          {...form.fields.manager.props}
          id="Manager"
          label="Manager"
          placeholder="Select manager"
          options={managerList || []}
        />

        <SelectField
          {...form.fields.staff.props}
          id="Staff"
          label="Staff"
          placeholder="Select staff"
          options={staffList || []}
        />
      </Stack>
    </Box>
  );
}
