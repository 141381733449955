import React, { ReactNode, useContext, useState } from 'react';
import { Transition } from 'react-transition-group';

import { SideDrawerControllerContextType, TransitionState } from './types';

type SideDrawerControllerProps = {
  isOpen?: boolean;
  onClose: () => void;
  children: ReactNode;
};

const SideDrawerControllerContext = React.createContext<null | SideDrawerControllerContextType>(
  {
    animationTimeout: 150,
    setAnimationTimeout: () => 150,
    onClose: () => {},
    transitionState: 'exited',
  }
);

export const SideDrawerControllerContextProvider =
  SideDrawerControllerContext.Provider;

export const useSideDrawerControllerContext = () => {
  let context = useContext(SideDrawerControllerContext);
  if (!context) {
    throw new Error(
      'SideDrawer must be wrapped in a <SideDrawerController>. You should generally do this outside of the component that renders the <SideDrawer>. See https://balance.reckon.com/package/side-drawer#sidedrawercontroller for how to use it and why.'
    );
  }

  return context;
};

export const SideDrawerController = ({
  isOpen,
  onClose,
  children,
}: SideDrawerControllerProps) => {
  const [animationTimeout, setAnimationTimeout] = useState(150);
  return (
    <Transition
      appear
      mountOnEnter
      unmountOnExit
      in={isOpen}
      timeout={animationTimeout}
    >
      {(transitionState: TransitionState) => (
        <SideDrawerControllerContextProvider
          value={{
            onClose,
            transitionState,
            animationTimeout,
            setAnimationTimeout,
          }}
        >
          {children}
        </SideDrawerControllerContextProvider>
      )}
    </Transition>
  );
};
