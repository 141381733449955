/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { Flex } from '@reckon-web/flex';
import { EntityAvatar } from '@reckon-web/avatar';
import { Text } from '@reckon-web/text';
import { TextLink } from '@reckon-web/text-link';

import { Member } from './types';

const horizonLine = {
  '&:before': {
    content: 'close-quote',
    width: 24,
    borderBottom: '#F0F0F1 solid 2px',
    marginRight: 2,
    position: 'relative',
  },
} as const;

const textStyle = {
  maxWidth: '160px',
};

export const TreeItem = ({ member }: { member: Member }) => {
  const { name, __typename, id } = member;
  return (
    <Flex css={horizonLine} marginY="small" alignItems="center" gap="small">
      <EntityAvatar
        size="xsmall"
        type={__typename || 'Person'}
        name={name || ''}
      />
      {id ? (
        <Text size="small" css={textStyle} overflowStrategy="truncate">
          <TextLink href={`/client?client=${id}`} title={name || ''}>
            {name}
          </TextLink>
        </Text>
      ) : (
        <Text size="small" css={textStyle}>
          {name}
        </Text>
      )}
    </Flex>
  );
};
