/** @jsx jsx */
import { ReactElement } from 'react';

import { jsx } from '@reckon-web/core';
import { useTheme } from '@reckon-web/theme';
import { useMediaQuery } from '@reckon-web/media-query';

type WrapperProps = {
  backgroundColor?: string;
  children: ReactElement[] | ReactElement;
  paddingBottom?: string | number;
  paddingTop?: string | number;
};

/** extra space - main content wrapper, extra space background color defaulted to white */
export const Wrapper = ({
  backgroundColor,
  children,
  paddingBottom,
  paddingTop,
}: WrapperProps) => {
  const { mq } = useMediaQuery();
  const { palette } = useTheme();

  return (
    <div
      className="wrapper-outer"
      css={mq({
        backgroundColor: backgroundColor || palette.background.base,
        paddingTop,
        paddingBottom,
        width: '100%',
      })}
    >
      <div
        className="wrapper-inner"
        css={mq({
          marginLeft: ['32px', null, null, null, 'auto'],
          marginRight: ['32px', null, null, null, 'auto'],
          width: 'calc(100% - 64px)',
          maxWidth: '2400px',
        })}
      >
        {children}
      </div>
    </div>
  );
};
