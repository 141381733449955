import {
  validateABN,
  validateIRD,
  validateNZBN,
  validateTFN,
} from '../../../utilities/validators';

import { MappingValProps } from './types';
// eslint-disable-next-line no-control-regex
const foreign = /[^\u0000-\u007f]/;

const checkSortname = ({ value }: MappingValProps) => {
  if (!value)
    return 'There is no "Sortname" detected, this is a required field.';

  if (foreign.test(value)) {
    return `Sortname ${value} includes foriegn characters that are not able to be saved. Please remove any foreign characters from the row`;
  }
  return null;
};

const checkEntityType = ({ value, entityTypes }: MappingValProps) => {
  if (!value)
    return 'There is no "EntityType" detected, this is a required field. Please enter either: Individual, Partnership, Company, Superannuation Fund, Club, Trust, Maori Authority, Government, Incorporated Society, Estate or Trustee Company';

  const entityType = entityTypes?.find((entity) => entity.name === value);

  if (!entityType)
    return `${value} is not a valid "EntityType". Please enter either: Individual, Partnership, Company, Superannuation Fund, Club, Trust, Maori Authority, Government, Incorporated Society, Estate or Trustee Company`;

  return null;
};

const checkOrgOrPerson = ({
  colKey,
  value,
  rowData,
  entityTypes,
}: MappingValProps) => {
  const entityType = entityTypes?.find(
    (entity) => entity.name === rowData?.EntityType
  );

  if (entityType?.type === 'Organisation') {
    if (colKey === 'RegName' && !value)
      return `Registered name is a required field for entity type ${entityType.name}, please enter a value into the "RegName" column`;

    if (
      ['Title', 'FirstName', 'MidName', 'LastName', 'Initials'].includes(
        colKey
      ) &&
      value
    )
      return `Entity type ${entityType.name} should not contain a value in the "${colKey}" column`;
  } else if (entityType?.type === 'Person') {
    if ((colKey === 'FirstName' || colKey === 'LastName') && !value)
      return `First name and last name are required fields for entity type ${entityType.name}, please enter a value into the "FirstName" & "LastName" column`;

    if (['RegName', 'TradingAs'].includes(colKey) && value)
      return `Entity type ${entityType.name} should not contain a value in the "${colKey}" column`;
  }

  return null;
};

const checkValidStaff = ({ colKey, value, staffMembers }: MappingValProps) => {
  if (!value) return null;
  const staffMember = staffMembers?.find(
    (s) => s.name?.toUpperCase().trim() === value?.toUpperCase().trim()
  );

  if (!staffMember)
    return `${colKey} - ${value} was not found, please review and try again`;
};

const checkTaxEntries = ({ colKey, value }: MappingValProps) => {
  if (!value) return null;
  if (colKey === 'ABN') {
    const isValid = validateABN(value.trim());
    if (!isValid)
      return `"ABN" was detected as invalid, please fix and try again`;
  }
  //   TFN Validator
  if (colKey === 'TFN') {
    const isValid = validateTFN(value.trim());
    if (!isValid)
      return `"TFN" was detected as invalid, please fix and try again`;
  }
  // Ird validator
  if (colKey === 'IRD') {
    const isValid = validateIRD(value.trim());
    if (!isValid)
      return `"IRD" was detected as invalid, please fix and try again`;
  }

  //   NZBN Number validator
  if (colKey === 'NZBN') {
    const isValid = validateNZBN(value.trim());
    if (!isValid)
      return `"NZBN" was detected as invalid, please fix and try again`;
  }
};

export const mappingValidations = ({
  colKey,
  value,
  rowData,
  entityTypes,
  staffMembers,
}: MappingValProps) => {
  const mappingProps = {
    colKey,
    value,
    rowData,
    entityTypes,
    staffMembers,
  };

  if (colKey === 'Sortname') return checkSortname({ ...mappingProps });

  if (colKey === 'EntityType') return checkEntityType({ ...mappingProps });
  if (
    [
      'Title',
      'FirstName',
      'MidName',
      'LastName',
      'Initials',
      'RegName',
      'TradingAs',
    ].includes(colKey)
  )
    return checkOrgOrPerson({ ...mappingProps });

  if (['Partner', 'Manager', 'Staff'].includes(colKey))
    return checkValidStaff({ ...mappingProps });

  if (['ABN', 'TFN', 'IRD', 'NZBN'].includes(colKey))
    return checkTaxEntries({ ...mappingProps });

  return null;
};
