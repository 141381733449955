/** @jsx jsx */
import { FragmentData, gql } from '@ts-gql/tag';
import { useQuery } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';

import { TreeView } from '../../../design-system/treeview/TreeView';
import { getClientName } from '../../../../utilities/dsUtils';

const memberDetailsFragment = gql`
  fragment DebtorAndFamilyGroupMembers_group on Client {
    id
    sortName
    ... on Organisation {
      registeredName
    }
    ... on Person {
      firstName
      lastName
    }
  }
` as import('../../../../../__generated__/ts-gql/DebtorAndFamilyGroupMembers_group').type;

export type DebtorAndFamilyGroupMemberDetails = FragmentData<
  typeof memberDetailsFragment
>;

export const debtorFamilyfragment = gql`
  fragment DebtorAndFamilyGroup_group on MembersWithHead {
    head {
      ...DebtorAndFamilyGroupMembers_group
    }
    membersMeta {
      count
    }
  }
` as import('../../../../../__generated__/ts-gql/DebtorAndFamilyGroup_group').type;

export type DebtorAndFamilyGroupMember = FragmentData<
  typeof debtorFamilyfragment
>;

const DEBIT_OR_FAMILY_MEMBERS = gql`
  query getDebitOrFamilyMembers(
    $id: ID!
    $debtorType: Boolean!
    $familyType: Boolean!
  ) {
    client(id: $id) {
      id
      familyGroup @include(if: $familyType) {
        members(limit: 99, skip: 0) {
          ...DebtorAndFamilyGroupMembers_group
        }
      }
      debtor @include(if: $debtorType) {
        members(limit: 99, skip: 0) {
          ...DebtorAndFamilyGroupMembers_group
        }
      }
    }
  }
` as import('../../../../../__generated__/ts-gql/getDebitOrFamilyMembers').type;

type DebtorAndFamilyGroupTreeProps = {
  group: DebtorAndFamilyGroupMember;
  clientId: string;
  treeType: 'familyGroup' | 'debtor';
};

export function DebtorAndFamilyGroupTree({
  group,
  clientId,
  treeType,
}: DebtorAndFamilyGroupTreeProps) {
  const { data, loading } = useQuery(DEBIT_OR_FAMILY_MEMBERS, {
    variables: {
      id: clientId,
      familyType: treeType === 'familyGroup',
      debtorType: treeType === 'debtor',
    },
    fetchPolicy: 'no-cache',
  });

  const members = data?.client?.[treeType]?.members || [];

  const { head } = group;

  const groupHead = {
    ...head,
    id: head?.id || '',
    avatar: getClientName(head),
    name: head?.sortName || '',
  };

  const groupMembers = members.map((member) => {
    return {
      ...member,
      avatar: getClientName(member),
      name: member.sortName,
    };
  });

  return (
    <TreeView
      group={{
        groupHead,
        members: groupMembers.length <= 1 ? [] : groupMembers,
      }}
      loading={loading}
    />
  );
}
