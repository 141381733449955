/** @jsx jsx */
import { gql } from '@ts-gql/tag';
import { useApolloClient, useQuery } from '@ts-gql/apollo';
import { useRouter } from 'next/router';

import {
  Autocomplete,
  BaseOption,
  FormatOptionLabelMeta,
} from '@reckon-web/autocomplete';
import { jsx } from '@reckon-web/core';
import { Flex } from '@reckon-web/flex';
import { EntityAvatar } from '@reckon-web/avatar';
import { Text } from '@reckon-web/text';
import { ThemeProvider } from '@reckon-web/theme';

import { ENTITY_TYPE } from './client/createEditClientDrawer/CreateEditClientDrawerContainer';

export let CLIENT_SEARCH = gql`
  query ClientsSearch($searchInput: String!) {
    searchClients(searchInput: $searchInput) {
      id
      sortName
      entityType {
        id
      }
    }
  }
` as import('../../__generated__/ts-gql/ClientsSearch').type;

type EntityNameOption = BaseOption & {
  type: 'Organisation' | 'Person';
  entity: string;
};

type SearchClientsProps = {
  width?: string;
  isNavigation: boolean;
  callback?: (option: BaseOption | null) => void;
};

export function SearchClients({
  isNavigation = false,
  callback,
}: SearchClientsProps) {
  const apolloClient = useApolloClient();
  const router = useRouter();

  const { data: fetchedEntityTypes } = useQuery(ENTITY_TYPE);

  const onItemSelect = (option: BaseOption | null) => {
    if (!option) return;
    if (!isNavigation) {
      return callback?.(option);
    }

    return router.push(`/client?client=${option.value}`);
  };

  const entityNameOptionRenderer = (
    option: EntityNameOption,
    { context }: FormatOptionLabelMeta<EntityNameOption>
  ) => {
    return context === 'value' ? (
      option.label
    ) : (
      <ThemeProvider
        theme={{
          colors: {
            background: '#ffffff',
          },
        }}
      >
        <Flex alignItems="center" gap="medium">
          <EntityAvatar type={option.type} size="small" name={option.label} />
          <Flex flexGrow={1} direction="column">
            <Text color="inherit" size="small" weight="medium">
              {option.label}
            </Text>
            <Text
              color="inherit"
              size="xsmall"
              leading="tight"
              css={{ opacity: 0.8 }}
            >
              {option.entity}
            </Text>
          </Flex>
        </Flex>
      </ThemeProvider>
    );
  };

  return (
    <Autocomplete
      id="Header-Autocomplete-client-search"
      onChange={(option) => onItemSelect(option)}
      placeholder="Search clients..."
      loadOptions={async (inputValue) => {
        const { data } = await apolloClient.query({
          query: CLIENT_SEARCH,
          variables: { searchInput: inputValue },
        });

        const searchedClients = data?.searchClients;

        return (
          searchedClients?.map((client) => {
            const entity = fetchedEntityTypes?.entityTypes.find(
              (_entity) => _entity.id === client?.entityType?.id
            );

            return {
              value: client?.id || '',
              label: client?.sortName || '',
              type: client?.__typename,
              entity: entity?.name || '',
            };
          }) || []
        );
      }}
      renderOption={entityNameOptionRenderer}
    />
  );
}
