/** @jsx jsx */
import { Fragment, useContext, useEffect, useState } from 'react';
import { gql } from '@ts-gql/tag';
import { useMutation, useQuery } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { field, useForm, useSubmit } from '@reckon-web/forms';
import { useToasts } from '@reckon-web/toast';
import { SideDrawer } from '@reckon-web/side-drawer';
import { Stack } from '@reckon-web/stack';
import { Button } from '@reckon-web/button';
import { useFormSnapshot } from '@reckon-web/forms';

import { ClientContext } from '../..';
import { useError } from '../../../../utilities/hooks/useError';
import { SelectField } from '../../../design-system/select-field';
import { DiscardConfirmationDialog } from '../../../common/dialogs/DiscardConfirmationDialog';

export let STAFF_QUERY = gql`
  query Staff {
    users(limit: 1000, skip: 0) {
      id
      name
      isPartner
      isManager
    }
  }
` as import('../../../../../__generated__/ts-gql/Staff').type;

// form schema
const managementGroupForm = field.object({
  partner: field.select()({}),
  manager: field.select()({}),
  staff: field.select()({}),
});

export type StaffMember = {
  id: string;
  name: string;
  isPartner: boolean;
  isManager: boolean;
};

export const EditManagementGroupDrawer = ({
  toggleIsOpen,
}: {
  toggleIsOpen: () => void;
}) => {
  const { addToast } = useToasts();
  const client = useContext(ClientContext).client;
  const [partnerList, setPartnerList] = useState([{ value: '', label: '' }]);
  const [managerList, setManagerList] = useState([{ value: '', label: '' }]);
  const [staffList, setStaffList] = useState([{ value: '', label: '' }]);

  const { data } = useQuery(STAFF_QUERY);
  const [updateStaff, { loading: isSavingSettings }] = useMutation(
    UPDATE_STAFF
  );

  const handleError = useError();

  useEffect(() => {
    if (data?.users) {
      // @ts-ignore
      const staff = data?.users.map((_staff: StaffMember) => {
        return {
          value: _staff.id,
          label: _staff.name,
          isPartner: _staff.isPartner,
          isManager: _staff.isManager,
        };
      });
      setStaffList(staff);
      setPartnerList(staff.filter((_partner) => _partner.isPartner));
      setManagerList(staff.filter((_manager) => _manager.isManager));
    }
  }, [data]);

  const form = useForm(managementGroupForm, {
    partner: client?.attributes?.partner?.id || '',
    manager: client?.attributes?.manager?.id || '',
    staff: client?.attributes?.user?.id || '',
  });
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
  const { isDirty } = useFormSnapshot(form);
  // const isFormEqualToInitialValue = useIsFormEqualToInitialValue(form);

  const handleSaveAndCloseSubmit = useSubmit(form, (value) => {
    const formValues: any = { ...value };
    Object.keys(formValues).forEach((key: string) => {
      if (formValues[key] === '') return delete formValues[key];
      formValues[key] = { id: formValues[key] };
    });

    const clientID = client?.id || '';
    const input = { attributes: { ...formValues } };

    updateStaff({
      variables: {
        id: clientID,
        input,
      },
    })
      .then(() => {
        addToast({
          title: 'Management successfully updated ',
          tone: 'positive',
        });
        toggleIsOpen();
      })
      .catch((error) => {
        handleError({ title: 'Failed to update management', error });
      });
  });

  const handleCancel = () => {
    isDirty ? setIsDiscardDialogOpen(true) : toggleIsOpen();
  };

  return (
    <Fragment>
      <SideDrawer>
        <SideDrawer.Form onSubmit={handleSaveAndCloseSubmit}>
          <SideDrawer.Header>
            <SideDrawer.Title>Change management</SideDrawer.Title>
          </SideDrawer.Header>
          <SideDrawer.Body>
            <Stack gap="large" padding="xlarge">
              <SelectField
                {...form.fields.partner.props}
                label="Partner"
                placeholder="Select partner"
                options={partnerList}
              />
              <SelectField
                {...form.fields.manager.props}
                label="Manager"
                placeholder="Select manager"
                options={managerList}
              />
              <SelectField
                {...form.fields.staff.props}
                label="Staff"
                placeholder="Select staff"
                options={staffList}
              />
            </Stack>
          </SideDrawer.Body>
          <SideDrawer.Footer>
            <Button
              label="Save & close"
              type="submit"
              loading={isSavingSettings}
            />
            <Button
              label="Cancel"
              weight="none"
              onClick={handleCancel}
              disabled={isSavingSettings}
            />
          </SideDrawer.Footer>
        </SideDrawer.Form>
      </SideDrawer>
      <DiscardConfirmationDialog
        isDiscardDialogOpen={isDiscardDialogOpen}
        setIsDiscardDialogOpen={setIsDiscardDialogOpen}
        callback={() => toggleIsOpen()}
      />
    </Fragment>
  );
};

const UPDATE_STAFF = gql`
  mutation UpdateStaff(
    $id: ID!
    $input: UpdateClientInput!
    $showSecGroup: Boolean = false
  ) {
    updateClient(id: $id, input: $input) {
      id
      ...ClientDetailsPage_client
    }
  }
` as import('../../../../../__generated__/ts-gql/UpdateStaff').type;
